import { Constants, getUser } from '@dx-ui/dx-common';
import { LocalStorageHelpers } from '@dx-ui/dx-common/src/utils/LocalStorageHelpers';
import { fetchUtils } from 'react-admin';

export interface DocumentCustomizationID {
  version: string;
  customizationID: string;
}
export interface PeppolProfileDocument {
  profileName: string;
  version: string;
}

export class PeppolService {
  private _supportedCapabilitiesMap: Map<string, DocumentCustomizationID[]>;

  /**
   * Builds the service instance
   */
  constructor() {
    /**
     * supportedCapabilitiesMap:  Map associating versioned profileName and platform customizationID
     */
    this._supportedCapabilitiesMap = new Map<string, DocumentCustomizationID[]>(
      [
        [
          PeppolService.UBL_BE_INVOICE,
          [
            {
              version: '3.0',
              customizationID: PeppolService.PEPPOL_CUSTOMIZATION_ID_BE,
            },
          ],
        ],
        [
          PeppolService.PEPPOL_BIS_BILLING_UBL_INVOICE,
          [
            {
              version: '3.0',
              customizationID: PeppolService.PEPPOL_CUSTOMIZATION_ID,
            },
          ],
        ],
      ]
    );
  }

  public static PEPPOL_CUSTOMIZATION_ID_PREFIX = 'urn:cen.eu:en16931';
  public static PEPPOL_CUSTOMIZATION_ID = `${PeppolService.PEPPOL_CUSTOMIZATION_ID_PREFIX}:2017#compliant#urn:fdc:peppol.eu:2017:poacc:billing:3.0`;
  public static PEPPOL_CUSTOMIZATION_ID_BE = `${PeppolService.PEPPOL_CUSTOMIZATION_ID_PREFIX}:2017#conformant#urn:UBL.BE:1.0.0.20180214`;
  public static PEPPOL_PROFILE_ID =
    'urn:fdc:peppol.eu:2017:poacc:billing:01:1.0';
  public static UBL_BE_INVOICE = 'UBL_BE_INVOICE';
  public static PEPPOL_BIS_BILLING_UBL_INVOICE =
    'PEPPOL_BIS_BILLING_UBL_INVOICE';

  get supportedCapabilitiesMap() {
    return this._supportedCapabilitiesMap;
  }

  private dateIsNotToday(storedTimestamp: number): boolean {
    const storedDate = new Date(storedTimestamp);
    storedDate.setHours(0, 0, 0, 0);

    const today = new Date(Date.now());
    today.setHours(0, 0, 0, 0);
    if (storedDate.getTime() !== today.getTime()) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * gets endpoint capabilities
   * @param endpoint list of dxuids
   */
  async getEndpointCapabilities(
    endpoint: string,
    docInstance: string
  ): Promise<any> {
    if (!endpoint || !docInstance) {
      // eslint-disable-next-line no-console
      console.log('getEndpointCapabilities : undefined params');
      return Promise.resolve([]);
    }
    const url = `/api/peppol/endpoint/${endpoint}/${docInstance}`;
    const user = getUser();
    const key = `${user}.${Constants.PEPPOL_ENDPOINTS_CAPS}`;

    let profiles: any[] = LocalStorageHelpers.retrieveItem<any>(key) || [];
    let endpointProfile = profiles?.find((e) => e.endpoint === endpoint);
    if (
      endpointProfile !== undefined &&
      this.dateIsNotToday(endpointProfile?.timestamp)
    ) {
      // Obsolete stored profile
      endpointProfile = undefined;
      profiles = profiles?.filter((e) => e.endpoint !== endpoint);
      LocalStorageHelpers.removeItem(key);
      LocalStorageHelpers.storeItem(key, profiles);
    }
    if (!endpointProfile || endpointProfile?.profiles.length === 0) {
      return fetchUtils
        .fetchJson(url, {
          credentials: 'include',
        })
        .then(({ json }) => {
          profiles.push({
            endpoint: endpoint,
            timestamp: new Date(Date.now()).getTime(),
            profiles: json,
          });
          LocalStorageHelpers.storeItem(key, profiles);

          return Promise.resolve(json);
        })
        .catch(({ status, body: json }) => {
          if (status === 400) {
            // Bad request in case of endpoint not found
            // eslint-disable-next-line no-console
            console.log('%o', json.error);
            return Promise.resolve([]);
          } else {
            return Promise.reject();
          }
        });
    } else {
      return Promise.resolve(endpointProfile.profiles);
    }
  }

  /**
   * gets the more specific customizationID according to country and plateform supported ones,
   * @param targetedCountry
   * @param capabilities
   * @returns the customizationID as a string if supported or the default one
   */
  getCustomizationID(
    targetedCountry: string,
    capabilities: PeppolProfileDocument[]
  ): string {
    let profileName =
      targetedCountry === 'BE'
        ? PeppolService.UBL_BE_INVOICE
        : PeppolService.PEPPOL_BIS_BILLING_UBL_INVOICE;
    const version = '3.0';

    let customizationID: string = PeppolService.PEPPOL_CUSTOMIZATION_ID;
    let caps: PeppolProfileDocument | undefined = capabilities.find(
      (p) => p.profileName === profileName
    );
    if (!caps) {
      profileName = PeppolService.PEPPOL_BIS_BILLING_UBL_INVOICE;
    } else {
      profileName = caps.profileName;
    }
    if (this.supportedCapabilitiesMap.has(profileName)) {
      customizationID =
        this.supportedCapabilitiesMap
          .get(profileName)
          ?.find((cap) => cap.version === version)?.customizationID ||
        PeppolService.PEPPOL_CUSTOMIZATION_ID;
    }

    return customizationID;
  }
}
