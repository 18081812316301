import { Constants, isPspAdministrator } from '@dx-ui/dx-common/src';
import { Company } from '@dx-ui/dx-common/src/configuration/types';
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Tooltip,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
import countryLanguage from 'country-language';
import HttpStatus from 'http-status-codes';
import { set, split } from 'lodash';
import { FC, useState } from 'react';
import {
  AutocompleteInput,
  DateTimeInput,
  Edit,
  EditProps,
  FieldProps,
  FunctionField,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  Translate,
  maxLength,
  required,
  useLocale,
  useNotify,
  usePermissions,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { CountryService } from '../../../../services';
import { V2CompanyModel } from '../../../../shared/types';

const useStyles = makeStyles((theme) => ({
  fillSpace: {
    width: '100%',
    minWidth: 300,
  },
}));

/**
 * The edition of a person.
 *
 * Only visible for PSP administrators.
 */
const AccountDetailsEdit: FC<EditProps> = (props) => {
  const notify = useNotify();

  const redirect = useRedirect();

  const onSuccess = () => {
    notify('resources.address.edit.notifications.updated');
    redirect(props.location?.pathname + '/show');
  };

  const onFailure = (error) => {
    // eslint-disable-next-line no-console
    console.error(error);
    if (error?.status === HttpStatus.CONFLICT) {
      // company already exist
      notify('dxMessages.error_messages.company_already_exists', 'error');
    } else {
      //unknown error
      notify('dxMessages.error_messages.OnError_contact_the_support', 'error', {
        error: error.message,
      });
    }
  };

  const CompanyTitle: FC<FieldProps<Company>> = ({ record }) => {
    const translate = useTranslate();

    if (!record) return null;
    return (
      <span>
        {translate('resources.companies.edit.title', {
          companyName: record.accountname,
        })}
      </span>
    );
  };

  /**
   * Formats Trade Register Number
   * @param formData form data
   * @returns form data containing Trade Register number + corporate Stock if any
   */
  const transformBeforeSaving = (formData) => {
    formData.traderegnr =
      formData.corporateStockAmount && formData.tradeRegisterNumber
        ? `${formData.tradeRegisterNumber}*${formData.corporateStockAmount}`
        : formData.tradeRegisterNumber || undefined;

    delete formData.tradeRegisterNumber;
    delete formData.corporateStockAmount;
    return formData;
  };

  const sanitizeRestProps = ({ mutationMode, validating, ...rest }) => rest;
  const CustomEditToolbar = (props: any) => (
    <Toolbar
      {...sanitizeRestProps(props)}
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <SaveButton
        {...sanitizeRestProps(props)}
        transform={transformBeforeSaving}
        disabled={props.pristine}
      />
      <CancelButton />
    </Toolbar>
  );

  const CancelButton = () => {
    const translate = useTranslate();
    return (
      <Button
        onClick={() => {
          redirect(props.location?.pathname + '/show');
        }}
        variant='contained'
      >
        {translate('ra.action.cancel')}
      </Button>
    );
  };

  return (
    <div>
      <Edit
        mutationMode='pessimistic'
        onSuccess={onSuccess}
        onFailure={onFailure}
        component='div'
        title={<CompanyTitle />}
        {...props}
      >
        <SimpleForm toolbar={<CustomEditToolbar />}>
          <FormLayout {...props} />
        </SimpleForm>
      </Edit>
    </div>
  );
};

const FormLayout: FC<FieldProps<V2CompanyModel>> = (props) => {
  const { record } = props;
  if (!record) return null;

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <GeneralInfo {...props} />
      </Grid>
    </Grid>
  );
};

const GeneralInfo = (props: any) => {
  const { permissions } = usePermissions();
  const locale = useLocale();
  const notify = useNotify();
  const countryService = new CountryService(locale);
  const countries = countryService.getCountries();
  const countryItems = countries.map((country) => {
    return {
      id: country.code,
      name: country.name,
    };
  });
  const form = useForm();
  const classes = useStyles();
  const icon = <CheckCircle color='primary' />;
  const [vatCode, setVatCode] = useState<string>('');
  const [details, setDetails] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [displayLoading, setDisplayLoading] = useState<boolean>(false);
  const translate = useTranslate();
  const refresh = useRefresh();

  const country = form.getState().values?.countryCode;
  const fetchDetails = () => {
    const params = new URLSearchParams({
      country: country,
      vatCode: vatCode,
    });
    setDisplayLoading(true);
    setLoading(true);

    fetch(`/${Constants.RESOURCE_ACCOUNT}/company-info?${params}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          if (response.status === HttpStatus.NOT_FOUND) {
            notify(
              'dxMessages.error_messages.company_info_not_found',
              'warning',
              { _: 'Company information not found' }
            );
          } else if (response.status === HttpStatus.BAD_REQUEST) {
            notify('dxMessages.error_messages.vat_not_found', 'warning', {
              _: 'VAT not found in public repository',
            });
          } else {
            notify(
              'dxMessages.error_messages.OnError_contact_the_support',
              'error',
              { error: response.statusText }
            );
          }
          // come back to initial value
          refresh();
        } else {
          response.json().then((json) => {
            const details = json;
            if (details.account !== undefined) {
              if (details.account.name !== undefined) {
                form.change('accountname', details.account.name);
              }
              if (details.account.registryNumber !== undefined) {
                let trn: string = details.account.registryNumber;
                let csa: string = '';
                if (details.account.registryNumber.indexOf('*') !== -1) {
                  trn = split(details.account.registryNumber, '*')?.[0];
                  csa = split(details.account.registryNumber, '*')?.[1];
                }
                form.change('tradeRegisterNumber', trn);
                if (csa?.length > 0) {
                  form.change('corporateStockAmount', csa);
                }
              }
            }
            setDetails(details);
          });
        }
        setLoading(false);
        setDisplayLoading(false);
      })
      .catch((err) => {
        setDisplayLoading(false);
        setLoading(false);
        notify(
          'dxMessages.error_messages.OnError_contact_the_support',
          'error',
          { error: err.message }
        );
      });
  };

  const CustomWidthTooltip = withStyles((theme) => ({
    tooltip: {
      maxWidth: 200,
      fontSize: 15,
    },
  }))(Tooltip);

  const allLanguages = countryLanguage.getLanguages().map((language) => {
    return {
      id: language.iso639_1,
      name: language.name,
    };
  });

  return (
    <Paper>
      <Grid
        style={{
          marginLeft: 20,
          paddingTop: 20,
        }}
      >
        <Grid item xs={8}>
          <div
            style={{
              display: 'flex',
              gap: 20,
              alignItems: 'flex-end',
            }}
          >
            <SelectInput
              label={'dxMessages.companyManagement.account.country'}
              source='countryCode'
              className={classes.fillSpace}
              choices={countryItems}
              defaultValue={'RO'}
              validate={required()}
            />
            <TextInput
              label={'dxMessages.companyManagement.account.vatCode'}
              source='identification'
              className={classes.fillSpace}
              validate={[required(), maxLength(50)]}
              onChange={(ev) => {
                setDetails(undefined);
                setVatCode(ev.target.value.substring(2));
              }}
            />
            <CustomWidthTooltip
              title={
                country !== 'RO'
                  ? translate(
                      'dxMessages.companyManagement.account.detailsTooltipRo'
                    )
                  : translate(
                      'dxMessages.companyManagement.account.detailsTooltip'
                    )
              }
            >
              <div>
                <Button
                  variant='outlined'
                  onClick={fetchDetails}
                  disabled={
                    vatCode.length === 0 ||
                    vatCode === details?.account?.vatCode ||
                    displayLoading === true ||
                    country !== 'RO'
                  }
                  startIcon={icon}
                  style={{
                    width: 200,
                    minWidth: 150,
                    height: 60,
                    display: 'flex',
                    position: 'relative',
                    bottom: '2em',
                  }}
                >
                  {translate('dxMessages.companyManagement.account.getDetails')}
                </Button>
              </div>
            </CustomWidthTooltip>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ display: 'flex', alignItems: 'baseline', gap: 10 }}>
            <SelectInput
              label={'dxMessages.companyManagement.account.language'}
              source='languageCode'
              className={classes.fillSpace}
              choices={allLanguages}
              defaultValue={'ro'}
              validate={required()}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ display: 'flex', alignItems: 'baseline', gap: 10 }}>
            <TextInput
              label={'dxMessages.companyManagement.account.name'}
              source='accountname'
              className={classes.fillSpace}
              validate={[required(), maxLength(255)]}
            />
            <CircularProgress
              size='1.5em'
              style={{
                display:
                  loading === true && displayLoading === true
                    ? 'block'
                    : 'none',
              }}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ display: 'flex', alignItems: 'baseline', gap: 10 }}>
            <FunctionField
              label={'dxMessages.companyManagement.account.registryNumber'}
              className={classes.fillSpace}
              render={(record) => {
                //
                if (record?.traderegnr?.indexOf('*') !== -1) {
                  set(
                    record as any,
                    'tradeRegisterNumber',
                    split(record?.traderegnr, '*')?.[0]
                  );
                } else {
                  set(record as any, 'tradeRegisterNumber', record?.traderegnr);
                }
                return (
                  <TextInput
                    source='tradeRegisterNumber'
                    record={record}
                    className={classes.fillSpace}
                    validate={[maxLength(255), checkAuthorizedChars(translate)]}
                  />
                );
              }}
            />
            <CircularProgress
              size='1.5em'
              style={{
                display:
                  loading === true && displayLoading === true
                    ? 'block'
                    : 'none',
              }}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'baseline', gap: 10 }}>
            <FunctionField
              label={'dxMessages.companyManagement.account.corporateStock'}
              className={classes.fillSpace}
              render={(record) => {
                //
                if (record?.traderegnr?.indexOf('*') !== -1) {
                  set(
                    record as any,
                    'corporateStockAmount',
                    split(record?.traderegnr, '*')?.[1]
                  );
                }
                return (
                  <TextInput
                    source='corporateStockAmount'
                    record={record}
                    className={classes.fillSpace}
                    validate={maxLength(255)}
                  />
                );
              }}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <SelectInput
            label={'dxMessages.companyManagement.account.idstatus'}
            source='idstatus'
            className={classes.fillSpace}
            choices={[
              { id: 1, name: 'Active' },
              { id: 2, name: 'Not Active' },
              { id: 3, name: 'Suspended' },
              { id: 4, name: 'Terminat' },
            ]}
            defaultValue={1}
            validate={[required(), maxLength(50)]}
          />
        </Grid>
        <Grid item xs={3} style={{ marginRight: 110 }}>
          {isPspAdministrator(permissions) && (
            <ReferenceInput
              label='dxMessages.companyManagement.account.idformattype'
              source='idformattype'
              reference='management/formattype'
              validate={required()}
              filterToQuery={(searchText) => ({
                description: [searchText],
              })}
              filter={{ idDocumentType: 1 }}
              sort={{ field: 'id', order: 'ASC' }}
              className={classes.fillSpace}
            >
              <AutocompleteInput
                optionText='description'
                className={classes.fillSpace}
              />
            </ReferenceInput>
          )}
        </Grid>
        <Grid item xs={3}>
          {isPspAdministrator(permissions) && (
            <ReferenceInput
              label='dxMessages.companyManagement.account.idformattypedesadv'
              source='idformattypedesadv'
              reference='management/formattype'
              validate={required()}
              filterToQuery={(searchText) => ({
                description: [searchText],
              })}
              filter={{ idDocumentType: 2 }}
              sort={{ field: 'id', order: 'ASC' }}
              className={classes.fillSpace}
            >
              <AutocompleteInput
                optionText='description'
                className={classes.fillSpace}
              />
            </ReferenceInput>
          )}
        </Grid>
        <Grid item xs={3}>
          <DateTimeInput
            label={'dxMessages.companyManagement.audit.tsChange'}
            source='tsChange'
            className={classes.fillSpace}
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            label={'dxMessages.companyManagement.audit.userIdChange'}
            source='userIdChange'
            className={classes.fillSpace}
            disabled
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

const checkAuthorizedChars = (translate: Translate) => (value: any) => {
  let error: string = '';
  if (value && value?.indexOf('*') !== -1) {
    error = translate('dxMessages.error_messages.invalid_character', {
      unwanted: '*',
    });
  }
  return error;
};

export default AccountDetailsEdit;
