import {
  Constants,
  InvoiceTypeCode,
  Metadata,
  ProcessStatus,
  RegulatorExtraDetailsType,
  sendGAEvent,
  Ubl,
} from '@dx-ui/dx-common';
import { MenuItem } from '@material-ui/core';
import { get } from 'lodash';
import { linkToRecord, usePermissionsOptimized } from 'ra-core';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useDataProvider,
  useGetIdentity,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { GA_EVENTS } from '../../GAUtils';
import {
  AlfrescoDocumentService,
  CopyOperation,
  DataHelpers,
  DocumentServiceFactory,
  InvoiceService,
  InvoiceServiceCreator,
} from '../../services';
import ToolbarButton from '../../shared/ToolbarButton';
import { P2pData } from '../../shared/types';

const checkForCommercialInvoice = (record: P2pData) => {
  const type: InvoiceTypeCode =
    get(record, Ubl.invoiceTypeCode) ||
    get(record.properties, Metadata.documentSubTypeCode);
  return type === InvoiceTypeCode.COMMERCIAL;
};

interface InvoiceActionProps {
  record: P2pData;
  isMenuItem?: boolean;
}

const InvoiceActions = React.forwardRef<any, InvoiceActionProps>(
  (props, ref) => {
    const { record, isMenuItem } = props;

    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const { permissions } = usePermissionsOptimized();

    const processStatus: ProcessStatus = DataHelpers.getProcessStatus(record);
    const isWebInvoice = AlfrescoDocumentService.isWebDocument(record);
    const isCommercialInvoice = checkForCommercialInvoice(record);
    const { identity } = useGetIdentity();
    // @ts-ignore
    const account: Account = identity;

    const [copyPermissions, setCopyPermissions] = useState<{
      canCancel: boolean;
      canCorrective: boolean;
      canClone: boolean;
    }>({
      canCancel: false,
      canCorrective: false,
      canClone: false,
    });

    const [loading, setLoading] = useState<boolean>(false);

    const documentService = useMemo(
      () => new AlfrescoDocumentService(dataProvider),
      [dataProvider]
    );

    const invoiceService = useMemo(
      () =>
        DocumentServiceFactory.create(
          InvoiceServiceCreator,
          dataProvider
        ) as InvoiceService,
      [dataProvider]
    );

    useEffect(() => {
      const checkForInvoiceCopyPermissions = async () => {
        let canClone = false,
          canCancel = false,
          canCorrective = false;

        setLoading(true);

        try {
          const canCreate = InvoiceService.canCreate(permissions);

          if (canCreate) {
            const recipientId = DataHelpers.getRecipientId(record);
            const isEfactura: boolean =
              DataHelpers.regulatorExtraDetails(record) ===
                RegulatorExtraDetailsType.EFACTURA_OUTBOUND ||
              DataHelpers.regulatorExtraDetails(record) ===
                RegulatorExtraDetailsType.EFACTURA_INBOUND;
            const templateRights =
              await invoiceService.getInvoiceTemplateRights(
                recipientId,
                DataHelpers.regulatorExtraDetails(record)
              );

            const clonePermissions =
              await documentService.getClonePermissions();

            const cancelCorrectiveKey = isWebInvoice
              ? CopyOperation.CAN_CANCEL_CORRECTIVE_WEB_INVOICE
              : CopyOperation.CAN_CANCEL_CORRECTIVE_CLASSIC_INVOICE;
            const cloneKey = isWebInvoice
              ? CopyOperation.CAN_CLONE_WEB_INVOICE
              : CopyOperation.CAN_CLONE_CLASSIC_INVOICE;

            canClone =
              isCommercialInvoice &&
              isWebInvoice &&
              templateRights.cloneOrCreate &&
              !!clonePermissions[cloneKey]?.includes(processStatus);
            canCancel =
              !isEfactura &&
              isCommercialInvoice &&
              !isWebInvoice &&
              templateRights.cancel &&
              !!clonePermissions[cancelCorrectiveKey]?.includes(processStatus);
            canCorrective =
              !isEfactura &&
              isCommercialInvoice &&
              !isWebInvoice &&
              templateRights.corrective &&
              !!clonePermissions[cancelCorrectiveKey]?.includes(processStatus);
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }

        setCopyPermissions({
          canClone,
          canCancel,
          canCorrective,
        });

        setLoading(false);
      };

      checkForInvoiceCopyPermissions();
    }, [
      dataProvider,
      processStatus,
      isWebInvoice,
      isCommercialInvoice,
      record,
      permissions,
      invoiceService,
      documentService,
    ]);

    enum InvoiceActionType {
      CLONE,
      CANCEL,
      CORRECTIVE,
    }

    const onClickDoAction = (actionType: InvoiceActionType) => {
      return async (e) => {
        e.preventDefault();

        if (!record.id) {
          throw new Error('record id cannot be null');
        }

        setLoading(true);

        try {
          let result: P2pData;
          switch (actionType) {
            case InvoiceActionType.CLONE:
              sendGAEvent(
                GA_EVENTS.categories.FORM.name,
                GA_EVENTS.categories.FORM.actions.CLONE,
                account?.company?.cmsRootDir,
                record
                  ? `Invoice DXUID: ${record.properties[Metadata.dxuid]}`
                  : undefined
              );

              result = await invoiceService.clone(record.id);
              break;
            case InvoiceActionType.CANCEL:
              sendGAEvent(
                GA_EVENTS.categories.FORM.name,
                GA_EVENTS.categories.FORM.actions.CANCEL,
                account?.company?.cmsRootDir,
                record
                  ? `Invoice DXUID: ${record.properties[Metadata.dxuid]}`
                  : undefined
              );

              result = await invoiceService.createCancelInvoice(record.id);
              break;
            case InvoiceActionType.CORRECTIVE:
              sendGAEvent(
                GA_EVENTS.categories.FORM.name,
                GA_EVENTS.categories.FORM.actions.CORRECT,
                account?.company?.cmsRootDir,
                record
                  ? `Invoice DXUID: ${record.properties[Metadata.dxuid]}`
                  : undefined
              );

              result = await invoiceService.createCorrectiveInvoice(record.id);
              break;
            default:
              throw new Error(`Invalid value for actionType: ${actionType}`);
          }
          setLoading(false);
          redirect(
            `/${linkToRecord(Constants.RESOURCE_WEBINVOICE, result.id)}`
          );
        } catch (e) {
          setLoading(false);
          // eslint-disable-next-line no-console
          console.error(e);
        }
      };
    };

    if (isMenuItem) {
      const sanitizeLinkProps = ({ isMenuItem, record, ...rest }: any) => rest;
      return (
        <>
          {copyPermissions.canClone && (
            <MenuItem
              {...sanitizeLinkProps(props)}
              disabled={loading}
              onClick={onClickDoAction(InvoiceActionType.CLONE)}
            >
              {translate('dxMessages.buttons.cloneInvoice')}
            </MenuItem>
          )}
          {copyPermissions.canCancel && (
            <MenuItem
              {...sanitizeLinkProps(props)}
              disabled={loading}
              onClick={onClickDoAction(InvoiceActionType.CANCEL)}
            >
              {translate('dxMessages.buttons.cancelInvoice')}
            </MenuItem>
          )}
          {copyPermissions.canCorrective && (
            <MenuItem
              {...sanitizeLinkProps(props)}
              disabled={loading}
              onClick={onClickDoAction(InvoiceActionType.CORRECTIVE)}
            >
              {translate('dxMessages.buttons.correctiveInvoice')}
            </MenuItem>
          )}
        </>
      );
    } else {
      const sanitizeBtnProps = ({ isMenuItem, className, ...rest }: any) =>
        rest;

      return (
        <>
          {copyPermissions.canClone && (
            <ToolbarButton
              disabled={loading}
              loading={loading}
              label={'dxMessages.buttons.cloneInvoice'}
              {...sanitizeBtnProps(props)}
              onClick={onClickDoAction(InvoiceActionType.CLONE)}
            />
          )}
          {copyPermissions.canCancel && (
            <ToolbarButton
              disabled={loading}
              loading={loading}
              label={'dxMessages.buttons.cancelInvoice'}
              {...sanitizeBtnProps(props)}
              onClick={onClickDoAction(InvoiceActionType.CANCEL)}
            />
          )}
          {copyPermissions.canCorrective && (
            <ToolbarButton
              disabled={loading}
              loading={loading}
              label={'dxMessages.buttons.correctiveInvoice'}
              {...sanitizeBtnProps(props)}
              onClick={onClickDoAction(InvoiceActionType.CORRECTIVE)}
            />
          )}
        </>
      );
    }
  }
);

InvoiceActions.defaultProps = {
  isMenuItem: true,
};

export default InvoiceActions;
