const Constants = {
  ERROR_END_EVENT: 'errorEndEvent',
  NONE_END_EVENT: 'noneEndEvent',
  INTERMEDIATE_SIGNAL_CATCH: 'intermediateSignalCatch',

  INVOICE_TYPE_FAM: 'FAM',
  INVOICE_TYPE_FAS: 'FAS',
  INVOICE_TYPE_FCG: 'FCG',
  INVOICE_TYPE_FIM: 'FIM',
  INVOICE_TYPE_FMD: 'FMD',
  INVOICE_TYPE_FME: 'FME',
  INVOICE_TYPE_FMF: 'FMF',
  INVOICE_TYPE_FSR: 'FSR',
  INVOICE_TYPE_FGO: 'FGO',

  PROCESS_DOCUMENT_FORMAT_TYPE_ORIGINAL: 'ORIGINAL',
  PROCESS_DOCUMENT_FORMAT_TYPE_IMAGE: 'IMAGE',
  PROCESS_DOCUMENT_FORMAT_TYPE_ISSUER_IMAGE: 'ISSUER_IMAGE',
  PROCESS_DOCUMENT_FORMAT_TYPE_RECIPIENT_IMAGE: 'RECIPIENT_IMAGE',
  PROCESS_DOCUMENT_FORMAT_TYPE_TARGET: 'TARGET',
  PROCESS_DOCUMENT_FORMAT_TYPE_DX: 'DX',
  PROCESS_DOCUMENT_FORMAT_TYPE_DRAFT: 'DRAFT',
  PROCESS_DOCUMENT_FORMAT_TYPE_ISSUER_ORIGINAL: 'ISSUER_ORIGINAL',

  ARCHIVE_PERIOD_1: '1',
  ARCHIVE_PERIOD_10: '10',
  ARCHIVE_PERIOD_PERMANENT: 'Permanent',

  DOCUMENT_TYPE_CODE_APERAK: 'APERAK',
  DOCUMENT_TYPE_CODE_CATLOG: 'CATLOG',
  DOCUMENT_TYPE_CODE_CNTCND: 'CNTCND',
  DOCUMENT_TYPE_CODE_DESADV: 'DESADV',
  DOCUMENT_TYPE_CODE_DELFOR: 'DELFOR',
  DOCUMENT_TYPE_CODE_INVOIC: 'INVOIC',
  DOCUMENT_TYPE_CODE_ORDERS: 'ORDERS',
  DOCUMENT_TYPE_CODE_RECADV: 'RECADV',
  DOCUMENT_TYPE_CODE_ATTACH: 'ATTACH',
  DOCUMENT_TYPE_CODE_WAYBIL: 'WAYBIL',

  // The key in the localStore of the user account.
  DX_USER_ACCOUNT: 'dxUserAccount',

  DX_LAST_GET_LIST: 'lastGetList',

  DXAPPLICATION: 'dxApplication',
  PERMISSIONS: 'permissions',
  BSP_ID: 'bsp_id',
  DEFAULT_BSP_ID: 'DocProcess',
  COMPANY_UUID: 'company_uuid',
  USER_VERSION: 'user_version',
  PEPPOL_ENDPOINTS_CAPS: 'peppol_endpoints_caps',
  DXARCHIVE_APP: 'dxarchive',
  DXPURCHASE_APP: 'dxportal',
  DXCATALOG_APP: 'dxcatalog',
  DXUPLOAD_APP: 'dxupload',
  DXCONTRACT_APP: 'dxcontract',
  RECONCILIATION_COCKPIT_APP: 'dxmatching',
  EXPENSE_CLASSIFICATION_COCKPIT_APP: 'dxallocation',
  ETRANSPORT_COCKPIT_APP: 'dxetransport',
  DXADMIN_APP: 'dxadmin',
  DXNO_APP: 'no_apps',
  DXARCHIVE_APP_DISPLAY: 'DxArchive',
  DXPURCHASE_APP_DISPLAY: 'DocProcess',
  DXCATALOG_APP_DISPLAY: 'DxCatalog',
  DXUPLOAD_APP_DISPLAY: 'DxUpload',
  DXCONTRACT_APP_DISPLAY: 'DxContract',
  RECONCILIATION_COCKPIT_APP_DISPLAY: 'DxAPM',
  EXPENSE_CLASSIFICATION_COCKPIT_APP_DISPLAY: 'Expense class.',
  DXPORTALCLASSIC_APP_DISPLAY: 'DocXchange legacy',
  ETRANSPORT_COCKPIT_APP_DISPLAY: 'E-Transport',
  DX_APP_DISPLAY: 'Dx Application',
  C4_COCKPIT_APP: 'dxefactura',
  C4_COCKPIT_APP_DISPLAY: 'Efactura Cockpit',
  EMAG_COCKPIT_APP: 'emagefactura',
  EMAG_COCKPIT_APP_DISPLAY: 'Emag Cockpit',
  CORA_COCKPIT_APP: 'coraefactura',
  CORA_COCKPIT_APP_DISPLAY: 'Cora Cockpit',

  RESOURCE_CONTRACT: 'cntcnd',
  RESOURCE_ORDER: 'order',
  RESOURCE_DESADV: 'desadv',
  RESOURCE_DESPATCH_ADVICE: 'despatchAdvice',
  RESOURCE_RECEIPT_ADVICE: 'recadv',
  RESOURCE_INVOICE: 'invoice',
  RESOURCE_FORECAST: 'delfor',
  RESOURCE_CATALOG: 'catalog',
  RESOURCE_FEEDBACK_MESSAGE: 'feedbackMessage',
  RESOURCE_ATTACHMENT: 'attachment',
  RESOURCE_DOCUMENTS: 'documents',
  RESOURCE_BULK_DOWNLOAD_CREATE: 'download/bulk/create',
  RESOURCE_BULK_DOWNLOAD_STATUS: 'download/bulk/status',
  RESOURCE_BULK_DOWNLOAD_CONTENT: 'download/bulk/content',
  RESOURCE_BULK_DOWNLOAD_DELETE: 'download/bulk/delete',
  RESOURCE_UBL: 'ubl',
  RESOURCE_WAYBILL: 'waybill',
  RESOURCE_WEBDOCUMENT: 'webdocument',
  RESOURCE_WEBINVOICE: 'webInvoice',
  RESOURCE_WEBDESPATCH_ADVICE: 'webDespatchAdvice',
  RESOURCE_WEBFORMTEMPLATE: 'webformtemplate',
  RESOURCE_WEBWAYBILL: 'webWaybill',
  RESOURCE_WEBRECEIPT_ADVICE: 'webReceiptAdvice',
  RESOURCE_WEBORDER: 'webOrder',
  RESOURCE_COMPANY: 'company',
  RESOURCE_USERPROFILES: 'userprofiles',
  RESOURCE_USERPROFILES_V2: 'userprofiles/true',
  RESOURCE_PASSWORD_MGMT: 'pwdmgmt',
  RESOURCE_TASKS: 'bpm/tasks',
  RESOURCE_CONVERT: 'convert',
  RESOURCE_TASK_DOCUMENTS: 'multiple/metadata',
  RESOURCE_CLONE: 'clone',
  RESOURCE_LINKED: 'linked',
  RESOURCE_AUDIT_TRAIL: 'audittrail',
  RESOURCE_PRODUCTUSAGE: 'management/productusage',
  RESOURCE_SUPPORTED_PRODUCTS: 'management/supportedproducts',
  RESOURCE_ADDRESSES: 'management/address',
  RESOURCE_ADDRESS_BUFFER: 'management/bufferaddress',
  RESOURCE_ACCOUNT: 'management/company',
  RESOURCE_PRODUCT_FOR_RELATION: 'management/productrelation',
  RESOURCE_PRODUCT_FOR_RELATION_PRODUCTS: 'management/productrelation/products',
  RESOURCE_CONTACT: 'management/contact',
  RESOURCE_DROPDOWNDEF: 'management/dropdowndefinition',
  RESOURCE_DXPRODUCT: 'management/dxproduct',
  RESOURCE_SUPPLIERS: 'management/supplier',
  RESOURCE_CUSTOMER: 'management/customer',
  RESOURCE_FORMAT_TYPE: 'management/formattype',
  RESOURCE_DX_ROLE: 'management/dxrole',
  RESOURCE_CREDIT: 'management/tbcredit',
  RESOURCE_EFACTURA_MONITORING_CONTENT_ERROR: 'efactura/monitoring/error',
  RESOURCE_EFACTURA_MONITORING_REJECTED_DOCUMENTS:
    'efactura/monitoring/rejected',
  RESOURCE_V2INVOICE: 'management/v2invoice',
  RESOURCE_V2ORDER: 'management/v2order',
  RESOURCE_V2RECEIPTADVICE: 'management/v2receiptadvice',
  RESOURCE_V2DESPATCHADVICE: 'management/v2despatchadvice',
  RESOURCE_PEPPOL: 'management/peppol',
  RESOURCE_USERS: 'usermgmt',
  RESOURCE_CATALOG_DRAFT: 'dxcatalog/draft',
  RESOURCE_WEBFORM_DRAFT: 'dxcatalog/webformdraft',
  RESOURCE_CATALOG_ADMIN: 'dxcatalog/admin',
  RESOURCE_DXCATALOG: 'dxcatalog/catalog',
  RESOURCE_DXSGRCATALOG: 'dxcatalog/catalogsgr',
  RESOURCE_DXCORASGRCATALOG: 'dxcatalog/catalogcorasgr',
  RESOURCE_DXDRAFT: 'dxcatalog/webform',
  RESOURCE_DXUPLOAD: 'upload',
  RESOURCE_ZENDESK: '/api/zendesk',
  RESOURCE_MESSAGES: 'configuration-message',
  API_GET_ANNOUNCEMENTS: 'GET_ANNOUNCEMENTS',
  API_APERAK_DETAILS: 'API_APERAK_DETAILS',
  API_GET_UBL_JSON: 'API_GET_UBL_JSON',
  API_GET_JSON_CONTENT: 'API_GET_JSON_CONTENT',
  API_PUT_SAVE_DOCUMENT: 'API_PUT_SAVE_DOCUMENT',
  API_POST_CREATE_DOCUMENT: 'API_POST_CREATE_DOCUMENT',
  API_SEND_DOCUMENT: 'API_SEND_DOCUMENT',
  API_GET_TEMPLATE: 'API_GET_TEMPLATE',
  API_GET_TEMPLATES_METADATA: 'API_GET_TEMPLATES_METADATA',
  API_GET_TEMPLATES_SUB_TYPES: 'API_GET_TEMPLATES_SUB_TYPES',
  API_GET_COMPANY_DETAILS: 'API_GET_COMPANY_DETAILS',
  API_GET_COMPANY_RELATIONS: 'API_GET_COMPANY_RELATIONS',
  API_GET_RECIPIENTS_FOR_TEMPLATE: 'API_GET_RECIPIENTS_FOR_TEMPLATE',
  API_CREATE_BULK_DOWNLOAD: 'API_CREATE_BULK_DOWNLOAD',
  API_DELETE_BULK_DOWNLOAD: 'API_DELETE_BULK_DOWNLOAD',
  API_GET_BULK_DOWNLOAD: 'API_GET_BULK_DOWNLOAD',
  API_GET_TASK_DOCUMENTS: 'API_GET_TASK_DOCUMENTS',
  API_CLONE_DOCUMENT: 'API_CLONE_DOCUMENT',
  API_GET_CATALOG: 'API_GET_CATALOG',
  API_FETCH_ENDPOINTS_BY_ISSUER_IDENTIFICATION_OR_COMPANY_NAME:
    'API_FETCH_ENDPOINTS_BY_ISSUER_IDENTIFICATION_OR_COMPANY_NAME',
  API_GET_PEPPOL_ENDPOINT_CAPABILITIES: 'API_GET_PEPPOL_ENDPOINT_CAPABILITIES',
  SYNC_COMPANY: 'SYNC_COMPANY',

  NODE_TYPE_APERAK: 'edm:feedbackMessage',
  NODE_TYPE_CATLOG: 'edm:catlog',
  NODE_TYPE_CNTCND: 'edm:cntcnd',
  NODE_TYPE_DESADV: 'edm:despatchAdvice',
  NODE_TYPE_DELFOR: 'edm:delfor',
  NODE_TYPE_INVOIC: 'edm:invoice',
  NODE_TYPE_ORDERS: 'edm:order',
  NODE_TYPE_RECADV: 'edm:recadv',
  NODE_TYPE_ATTACH: 'edm:attachment',
  NODE_TYPE_WAYBIL: 'edm:waybill',
  NODE_TYPE_CUSTOM: 'am:document',

  READ_STATUS_NEW: 'NEW',
  READ_STATUS_READ: 'READ',
  READ_STATUS_DOWNLOADED: 'DOWNLOADED',
  READ_STATUS_CONVERTED: 'CONVERTED',

  // TEMPLATE SERVICE
  DEFAULT_RECIPIENT: 'DEFAULT',

  // WEB FORM
  DISCOUNT_REASON: 'Discount',
  GREEN_TAX_REASON: 'Taxa verde',
  SGR_REASON: 'SGR',
  SUGAR_TAX_REASON: 'SGT',

  // TAX SCHEMES
  TAX_SCHEME_NAME_S: 'S',
  // BE specific
  TAX_SCHEME_NAME_S_01: '01',
  TAX_SCHEME_NAME_S_02: '02',
  TAX_SCHEME_NAME_S_03: '03',
  TAX_SCHEME_NAME_45: '45',
  TAX_SCHEME_NAME_NA: 'NA',
  TAX_SCHEME_NAME_FD: 'FD',
  TAX_SCHEME_NAME_SC: 'SC',
  TAX_SCHEME_NAME_00_44: '00/44',
  TAX_SCHEME_NAME_03_SE: '03/SE',
  TAX_SCHEME_NAME_MA: 'MA',
  TAX_SCHEME_NAME_46_GO: '46/GO',
  TAX_SCHEME_NAME_47_TO: '47/TO',
  TAX_SCHEME_NAME_47_AS: '47/AS',
  TAX_SCHEME_NAME_47_DI: '47/DI',
  TAX_SCHEME_NAME_47_SE: '47/SE',
  TAX_SCHEME_NAME_44: '44',
  TAX_SCHEME_NAME_46_TR: '46/TR',
  TAX_SCHEME_NAME_47_EX: '47/EX',
  TAX_SCHEME_NAME_47_EI: '47/EI',
  TAX_SCHEME_NAME_47_EE: '47/EE',
  TAX_SCHEME_NAME_NS: 'NS',
  TAX_SCHEME_NAME_OSS_G: 'OSS-G',
  TAX_SCHEME_NAME_OSS_S: 'OSS-S',
  TAX_SCHEME_NAME_OSS_I: 'OSS-I',
  TAX_SCHEME_NAME_Z_00: '00',

  // EU
  TAX_SCHEME_ID_7: '7',
  TAX_SCHEME_ID_S: 'S',
  TAX_SCHEME_ID_AE: 'AE',
  TAX_SCHEME_ID_E: 'E',
  TAX_SCHEME_ID_G: 'G',
  TAX_SCHEME_ID_K: 'K',
  TAX_SCHEME_ID_O: 'O',
  TAX_SCHEME_ID_Z: 'Z',
  TAX_CATEGORY_CODE_VAT: 'VAT',
  TAX_EXEMPTION_CODE_VATEX_EU_AE: 'VATEX-EU-AE',
  TAX_EXEMPTION_CODE_VATEX_EU_C: 'VATEX-EU-C',
  TAX_EXEMPTION_CODE_VATEX_EU_D: 'VATEX-EU-D',
  TAX_EXEMPTION_CODE_VATEX_EU_F: 'VATEX-EU-F',
  TAX_EXEMPTION_CODE_VATEX_EU_I: 'VATEX-EU-I',
  TAX_EXEMPTION_CODE_VATEX_EU_J: 'VATEX-EU-J',
  TAX_EXEMPTION_CODE_VATEX_EU_IC: 'VATEX-EU-IC',
  TAX_EXEMPTION_CODE_VATEX_EU_G: 'VATEX-EU-G',
  TAX_EXEMPTION_CODE_VATEX_EU_O: 'VATEX-EU-O',
  TAX_EXEMPTION_CODE_VATEX_EU_79_C: 'VATEX-EU-79-C',
  TAX_EXEMPTION_CODE_VATEX_EU_132: 'VATEX-EU-132',
  TAX_EXEMPTION_CODE_VATEX_EU_143: 'VATEX-EU-143',
  TAX_EXEMPTION_CODE_VATEX_EU_148: 'VATEX-EU-148',
  TAX_EXEMPTION_CODE_VATEX_EU_151: 'VATEX-EU-151',
  TAX_EXEMPTION_CODE_VATEX_EU_309: 'VATEX-EU-309',

  // BE specific
  TAX_EXEMPTION_CODE_BETE_45: 'BETE-45',
  TAX_EXEMPTION_CODE_BETE_EX: 'BETE-EX',
  TAX_EXEMPTION_CODE_BETE_FD: 'BETE-FD',
  TAX_EXEMPTION_CODE_BETE_SC: 'BETE-SC',
  TAX_EXEMPTION_CODE_BETE_00_44: 'BETE-00/44',
  TAX_EXEMPTION_CODE_BETE_03_SE: 'BETE-03/SE',
  TAX_EXEMPTION_CODE_BETE_MA: 'BETE-MA',
  TAX_EXEMPTION_CODE_BETE_46_GO: 'BETE-46/GO',
  TAX_EXEMPTION_CODE_BETE_47_TO: 'BETE-47/TO',
  TAX_EXEMPTION_CODE_BETE_47_AS: 'BETE-47/AS',
  TAX_EXEMPTION_CODE_BETE_47_DI: 'BETE-47/DI',
  TAX_EXEMPTION_CODE_BETE_47_SE: 'BETE-47/SE',
  TAX_EXEMPTION_CODE_BETE_44: 'BETE-44',
  TAX_EXEMPTION_CODE_BETE_46_TR: 'BETE-46/TR',
  TAX_EXEMPTION_CODE_BETE_47_EX: 'BETE-47/EX',
  TAX_EXEMPTION_CODE_BETE_47_EI: 'BETE-47/EI',
  TAX_EXEMPTION_CODE_BETE_47_EE: 'BETE-47/EE',
  TAX_EXEMPTION_CODE_BETE_NS: 'BETE-NS',

  // FR
  // France domestic VAT franchise in base.
  TAX_EXEMPTION_CODE_VATEX_FR_FRANCHISE: 'VATEX-FR-FRANCHISE',
  // France domestic Credit Notes without VAT, due to supplier forfeit of VAT for discount.
  TAX_EXEMPTION_CODE_VATEX_FR_CNWVAT: 'VATEX-FR-CNWVAT',
};

/**
 * Exemple: documentTypeCode="edm:documentTypeCode"
 */
const Metadata = {
  documentTypeCode: 'edm:documentTypeCode',
  documentSubTypeCode: 'edm:documentSubTypeCode',
  document: 'document',
  nodeType: 'nodeType',
  dxuid: 'edm:dxuid',
  readStatus: 'edm:readStatus',
  totalWithVat: 'edm:totalWithVat',
  totalVat: 'edm:totalVat',
  totalWithoutVat: 'edm:totalWithoutVat',
  currency: 'edm:currency',
  created: 'created',
  createdAt: 'createdAt', // alias "created"
  modified: 'modified',
  modifiedAt: 'modifiedAt', // alias "modified"
  issuerName: 'edm:issuerName',
  issuerId: 'edm:issuerId',
  issueDate: 'edm:issueDate',
  recipientName: 'edm:recipientName',
  recipientId: 'edm:recipientId',
  receiverId: 'edm:transportReceiverId',
  processStatus: 'edm:processStatus',
  documentId: 'edm:documentId',
  locationName: 'edm:name',
  locationAddress: 'edm:address',
  gln: 'edm:gln',
  processDocumentFormatType: 'edm:processDocumentFormatType',
  adherentUniqueIdentifier: 'edm:adherentUniqueIdentifier',
  documentReferenceDxuid: 'edm:documentReferenceDxuid',
  attachmentParentDxuid: 'edm:attachmentParentDxuid',
  despatchAdviceId: 'edm:despatchAdviceId',
  despatchAdviceDate: 'edm:despatchAdviceDate',
  receiptAdviceId: 'edm:receiptAdviceId',
  receiptAdviceDate: 'edm:receiptAdviceDate',
  contractId: 'edm:contractId',
  contractDate: 'edm:contractDate',
  orderId: 'edm:orderId',
  orderDate: 'edm:orderDate',
  invoiceType: 'edm:invoiceType',
  invoiceDocumentId: 'invoiceDocumentId',
  invoiceIssueDate: 'invoiceIssueDate',
  despatchAdviceDocumentId: 'despatchAdviceDocumentId',
  despatchAdviceIssueDate: 'despatchAdviceIssueDate',
  dueDate: 'dueDate',
  iban: 'iban',
  deliveryDate: 'edm:deliveryDate',
  archiveDate: 'edm:archiveDate',
  archivePeriod: 'edm:archivePeriod',
  carrierPartyName: 'edm:carrierPartyName',
  consignorPartyName: 'edm:consignorPartyName',
  loadingLocationAddress: 'edm:loadingLocationAddress',
  loadingLocationGln: 'edm:loadingLocationGln',
  loadingLocationName: 'edm:loadingLocationName',
  unloadingLocationAddress: 'edm:unloadingLocationAddress',
  unloadingLocationGln: 'edm:unloadingLocationGln',
  unloadingLocationName: 'edm:unloadingLocationName',
  extendedInvoiceDxuid: 'edm:invoiceDxuid',
  extendedInvoiceId: 'edm:invoiceId',
  extendedInvoiceIssueDate: 'edm:invoiceIssueDate',
  regulatorExtraDetails: 'edm:regulatorExtraDetails',
};

const Ubl = {
  // lines
  lineDeliveryStartDate: 'delivery[0].requestedDeliveryPeriod.startDate.value',
  lineDeliveryEndDate: 'delivery[0].requestedDeliveryPeriod.endDate.value',
  lineActualDeliveryDate: 'delivery[0].actualDeliveryDate.value',
  lineId: 'orderLineReference.lineID.value',
  description: 'item.description[0].value',
  unitsNumber: 'item.packSizeNumeric.value',
  codeClient: 'item.buyersItemIdentification.id.value',
  codeSupplier: 'item.sellersItemIdentification.id.value',
  codeStandard: 'item.standardItemIdentification.id.value',
  additionalItemCode: 'item.additionalItemIdentification.id.value',
  itemClassificationCode:
    'item.commodityClassification.itemClassificationCode.value',
  receivedDescription: 'item[0].description[0].value',
  receivedCodeClient: 'item[0].buyersItemIdentification.id.value',
  manufacturersItemIdentification:
    'item.manufacturersItemIdentification[0].value',
  receivedCodeSupplier: 'item[0].sellersItemIdentification.id.value',
  receivedCodeStandard: 'item[0].standardItemIdentification.id.value',
  lineNote: 'note[0].value',
  quantityOrder: 'quantity.value',
  unitCodeOrder: 'quantity.unitCode',
  quantityInvoice: 'invoicedQuantity.value',
  unitCodeInvoice: 'invoicedQuantity.unitCode',
  quantityDespatchAdvice: 'deliveredQuantity.value',
  unitCodeDespatchAdvice: 'deliveredQuantity.unitCode',
  quantityReceiptAdvice: 'receivedQuantity.value',
  unitCodeReceiptAdvice: 'receivedQuantity.unitCode',
  orderReferenceId: 'orderLineReference.orderReference.id.value',
  price: 'price.priceAmount.value',
  baseQuantity: 'price.baseQuantity.value',
  priceAmountCurrencyID: 'price.priceAmount.currencyID',
  vatPercentage: 'taxTotal[0].taxSubtotal[0].percent.value',
  totalWithoutVat: 'lineExtensionAmount.value',
  totalVat: 'taxTotal[0].taxSubtotal[0].taxAmount.value',
  totalVatCurrencyID: 'taxTotal[0].taxSubtotal[0].taxAmount.currencyID',
  taxableAmount: 'taxTotal[0].taxSubtotal[0].taxableAmount.value',
  taxableAmountCurrencyID:
    'taxTotal[0].taxSubtotal[0].taxableAmount.currencyID',
  taxPerUnitAmountCurrencyID:
    'taxTotal[0].taxSubtotal[0].perUnitAmount.currencyID',
  taxSchemeName: 'taxTotal[0].taxSubtotal[0].taxCategory.taxScheme.name.value',
  taxSchemeID: 'taxTotal[0].taxSubtotal[0].taxCategory.taxScheme.id.value',
  taxCategoryCode:
    'taxTotal[0].taxSubtotal[0].taxCategory.taxScheme.taxTypeCode.value',
  taxExemptionReason:
    'taxTotal[0].taxSubtotal[0].taxCategory.taxExemptionReason.value',
  taxExemptionReasonCode:
    'taxTotal[0].taxSubtotal[0].taxCategory.taxExemptionReasonCode.value',
  baseUnitMeasure:
    'taxTotal[0].taxSubtotal[0].taxCategory.taxScheme.baseUnitMeasure.value',
  packingType:
    'pricingReference.originalItemLocationQuantity.package.returnableMaterialIndicator.value',
  allowanceCharge: 'allowanceCharge',
  chargeIndicator: 'chargeIndicator.value',
  allowanceChargeReasonCode: 'allowanceChargeReasonCode.value',
  multiplierFactorNumeric: 'multiplierFactorNumeric.value',

  //discount
  discountMultiplierFactorNumeric:
    'allowanceCharge[0].multiplierFactorNumeric.value', // Will need some computation in order to transform to allowanceCharge entry.
  discountId: 'allowanceCharge[0].id.value',
  discountReasonCode: 'allowanceCharge[0].allowanceChargeReasonCode.value', // false discount
  discountReason: 'allowanceCharge[0].allowanceChargeReason.value',
  discountSequenceNumeric: 'allowanceCharge[0].sequenceNumeric.value',
  discountAmount: 'allowanceCharge[0].amount.value',
  discountAmountCurrencyID: 'allowanceCharge[0].amount.currencyID',
  discountBaseAmount: 'allowanceCharge[0].baseAmount.value',
  discountBaseAmountCurrencyID: 'allowanceCharge[0].baseAmount.currencyID',
  discountPerUnitAmount: 'allowanceCharge[0].perUnitAmount.value',
  discountPerUnitAmountCurrencyID:
    'allowanceCharge[0].perUnitAmount.currencyID',

  //green tax
  greenTaxId: 'allowanceCharge[1].id.value', // Will need some computation in order to transform to allowanceCharge entry.
  greenTaxReasonCode: 'allowanceCharge[1].allowanceChargeReasonCode.value', // true for GT
  greenTaxReason: 'allowanceCharge[1].allowanceChargeReason.value',
  greenTaxSequenceNumeric: 'allowanceCharge[1].sequenceNumeric.value',
  greenTaxAmount: 'allowanceCharge[1].amount.value',
  greenTaxAmountCurrencyID: 'allowanceCharge[1].amount.currencyID',
  greenTaxBaseAmount: 'allowanceCharge[1].baseAmount.value',
  greenTaxBaseAmountCurrencyID: 'allowanceCharge[1].baseAmount.currencyID',
  greenTaxPerUnitAmount: 'allowanceCharge[1].perUnitAmount.value',
  greenTaxPerUnitAmountCurrencyID:
    'allowanceCharge[1].perUnitAmount.currencyID',

  // SGR tax
  sgrTaxId: 'allowanceCharge[2].id.value', // Will need some computation in order to transform to allowanceCharge entry.
  sgrTaxReasonCode: 'allowanceCharge[2].allowanceChargeReasonCode.value', // true for GT
  sgrTaxReason: 'allowanceCharge[2].allowanceChargeReason.value',
  sgrTaxSequenceNumeric: 'allowanceCharge[2].sequenceNumeric.value',
  sgrTaxAmount: 'allowanceCharge[2].amount.value',
  sgrTaxAmountCurrencyID: 'allowanceCharge[2].amount.currencyID',
  sgrTaxBaseAmount: 'allowanceCharge[2].baseAmount.value',
  sgrTaxBaseAmountCurrencyID: 'allowanceCharge[2].baseAmount.currencyID',
  sgrTaxPerUnitAmount: 'allowanceCharge[2].perUnitAmount.value',
  sgrTaxPerUnitAmountCurrencyID: 'allowanceCharge[2].perUnitAmount.currencyID',

  // Sugar Tax
  sugarTaxId: 'allowanceCharge[3].id.value', // Will need some computation in order to transform to allowanceCharge entry.
  sugarTaxReasonCode: 'allowanceCharge[3].allowanceChargeReasonCode.value', // true for GT
  sugarTaxReason: 'allowanceCharge[3].allowanceChargeReason.value',
  sugarTaxSequenceNumeric: 'allowanceCharge[3].sequenceNumeric.value',
  sugarTaxAmount: 'allowanceCharge[3].amount.value',
  sugarTaxAmountCurrencyID: 'allowanceCharge[3].amount.currencyID',
  sugarTaxBaseAmount: 'allowanceCharge[3].baseAmount.value',
  sugarTaxBaseAmountCurrencyID: 'allowanceCharge[3].baseAmount.currencyID',
  sugarTaxPerUnitAmount: 'allowanceCharge[3].perUnitAmount.value',
  sugarTaxPerUnitAmountCurrencyID:
    'allowanceCharge[3].perUnitAmount.currencyID',

  // Ubl properties
  taxTotalVat: 'taxTotal[0].taxAmount.value',
  taxTotalVatCurrencyID: 'taxTotal[0].taxAmount.currencyID',
  taxSubtotal: 'taxTotal[0].taxSubtotal',
  taxSubtotalTaxAmount: 'taxAmount.value',
  taxSubtotalTaxAmountCurrencyID: 'taxAmount.currencyID',
  taxSubtotalTaxableAmount: 'taxableAmount.value',
  taxSubtotalTaxableAmountCurrencyID: 'taxableAmount.currencyID',
  taxSubtotalPercent: 'percent.value',
  taxSubtotalTaxSchemeName: 'taxCategory.taxScheme.name.value',
  taxSubtotalTaxSchemeID: 'taxCategory.taxScheme.id.value',
  taxSubtotalTaxTypeCode: 'taxCategory.taxScheme.taxTypeCode.value',
  paymentMeansCode: 'ublProperties.paymentMeans[0].paymentMeansCode.value',
  accountingCustomerParty: 'accountingCustomerParty',
  accountingCustomerPartyName:
    'ublProperties.accountingCustomerParty.party.partyName[0].name.value',
  accountingCustomerCityName:
    'ublProperties.accountingCustomerParty.party.postalAddress.cityName.value',
  accountingCustomerStreetName:
    'ublProperties.accountingCustomerParty.party.postalAddress.streetName.value',
  accountingCustomerAdditionalStreetName:
    'ublProperties.accountingCustomerParty.party.postalAddress.additionalStreetName.value',
  accountingCustomerGLN:
    'ublProperties.accountingCustomerParty.party.endpointID.value',
  accountingCustomerGLNSchemeID:
    'ublProperties.accountingCustomerParty.party.endpointID.schemeID',
  accountingCustomerBuildingNumber:
    'ublProperties.accountingCustomerParty.party.postalAddress.buildingNumber.value',
  accountingCustomerPostalZone:
    'ublProperties.accountingCustomerParty.party.postalAddress.postalZone.value',
  accountingCustomerCountryCode:
    'ublProperties.accountingCustomerParty.party.postalAddress.country.identificationCode.value',
  accountingCustomerCompanyID:
    'ublProperties.accountingCustomerParty.party.partyLegalEntity[0].companyID.value',
  accountingCustomerCorporateStockAmountValue:
    'ublProperties.accountingCustomerParty.party.partyLegalEntity[0].corporateStockAmount.value',
  accountingCustomerCorporateStockAmountCurrencyID:
    'ublProperties.accountingCustomerParty.party.partyLegalEntity[0].corporateStockAmount.currencyID',
  buyerCustomerParty: 'buyerCustomerParty',
  buyerCustomerStreetName:
    'ublProperties.buyerCustomerParty.party.postalAddress.streetName.value',
  buyerCustomerCityName:
    'ublProperties.buyerCustomerParty.party.postalAddress.cityName.value',
  buyerCustomerGLN: 'ublProperties.buyerCustomerParty.party.endpointID.value',
  buyerCustomerGLNSchemeID:
    'ublProperties.buyerCustomerParty.party.endpointID.schemeID',
  buyerCustomerName:
    'ublProperties.buyerCustomerParty.party.partyName[0].name.value',
  buyerCustomerBuildingNumber:
    'ublProperties.buyerCustomerParty.party.postalAddress.buildingNumber.value',
  buyerCustomerPostalZone:
    'ublProperties.buyerCustomerParty.party.postalAddress.postalZone.value',
  buyerCustomerCountryCode:
    'ublProperties.buyerCustomerParty.party.postalAddress.country.identificationCode.value',
  accountingCost: 'ublProperties.accountingCost.value',
  accountingSupplierParty: 'accountingSupplierParty',
  accountingSupplierCustomerAssignedID:
    'ublProperties.accountingSupplierParty.customerAssignedAccountID.value',
  accountingSupplierCityName:
    'ublProperties.accountingSupplierParty.party.postalAddress.cityName.value',
  accountingSupplierStreetName:
    'ublProperties.accountingSupplierParty.party.postalAddress.streetName.value',
  accountingSupplierAdditionalStreetName:
    'ublProperties.accountingSupplierParty.party.postalAddress.additionalStreetName.value',
  accountingSupplierGLN:
    'ublProperties.accountingSupplierParty.party.endpointID.value',
  accountingSupplierBuildingNumber:
    'ublProperties.accountingSupplierParty.party.postalAddress.buildingNumber.value',
  accountingSupplierPostalZone:
    'ublProperties.accountingSupplierParty.party.postalAddress.postalZone.value',
  accountingSupplierCountryCode:
    'ublProperties.accountingSupplierParty.party.postalAddress.country.identificationCode.value',
  accountingSupplierCompanyID:
    'ublProperties.accountingSupplierParty.party.partyLegalEntity[0].companyID.value',
  accountingSupplierCorporateStockAmountValue:
    'ublProperties.accountingSupplierParty.party.partyLegalEntity[0].corporateStockAmount.value',
  accountingSupplierCorporateStockAmountCurrencyID:
    'ublProperties.accountingSupplierParty.party.partyLegalEntity[0].corporateStockAmount.currencyID',
  accountingSupplierCompanyLegalForm:
    'ublProperties.accountingSupplierParty.party.partyLegalEntity[0].companyLegalForm.value',
  AccountingSupplierTaxSchemeCompanyID:
    'ublProperties.accountingSupplierParty.party.partyTaxScheme[0].companyID.value',
  sellerSupplierParty: 'sellerSupplierParty',
  sellerSupplierCityName:
    'ublProperties.sellerSupplierParty.party.postalAddress.cityName.value',
  sellerSupplierStreetName:
    'ublProperties.sellerSupplierParty.party.postalAddress.streetName.value',
  sellerSupplierBuildingNumber:
    'ublProperties.sellerSupplierParty.party.postalAddress.buildingNumber.value',
  sellerSupplierPostalZone:
    'ublProperties.sellerSupplierParty.party.postalAddress.postalZone.value',
  sellerSupplierCountryCode:
    'ublProperties.sellerSupplierParty.party.postalAddress.country.identificationCode.value',
  sellerSupplierGLN: 'ublProperties.sellerSupplierParty.party.endpointID.value',
  sellerSupplierReceiverCode:
    'ublProperties.sellerSupplierParty.customerAssignedAccountID.value',
  despatchSupplierPartyStreetName:
    'ublProperties.despatchSupplierParty.party.postalAddress.streetName.value',
  despatchSupplierPartyCityName:
    'ublProperties.despatchSupplierParty.party.postalAddress.cityName.value',
  despatchSupplierPartyGLN:
    'ublProperties.despatchSupplierParty.party.endpointID.value',
  dueDate: 'ublProperties.paymentMeans[0].paymentDueDate.value',
  headerDueDate: 'ublProperties.dueDate.value',
  payerIban: 'ublProperties.paymentMeans[0].payerFinancialAccount.id.value',
  payerBank:
    'ublProperties.paymentMeans[0].payerFinancialAccount.financialInstitutionBranch.financialInstitution.name.value',
  payerCurrencyCode:
    'ublProperties.paymentMeans[0].payerFinancialAccount.currencyCode.value',
  payeeIban: 'ublProperties.paymentMeans[0].payeeFinancialAccount.id.value',
  payeeBank:
    'ublProperties.paymentMeans[0].payeeFinancialAccount.financialInstitutionBranch.financialInstitution.name.value',
  payeeCurrencyCode:
    'ublProperties.paymentMeans[0].payeeFinancialAccount.currencyCode.value',
  deliveryId: 'ublProperties.delivery[0].id.value',
  deliveryDate: 'ublProperties.delivery[0].actualDeliveryDate.value',
  deliveryRequestStartDate:
    'ublProperties.delivery[0].requestedDeliveryPeriod.startDate.value',
  deliveryRequestEndDate:
    'ublProperties.delivery[0].requestedDeliveryPeriod.endDate.value',
  deliveryPartyGLN: 'ublProperties.delivery[0].deliveryParty.endpointID.value',
  deliveryPartyName: 'ublProperties.delivery[0].deliveryParty.partyName.value',
  deliveryPartyStreetName:
    'ublProperties.delivery[0].deliveryParty.postalAddress.streetName.value',
  deliveryPartyAdditionalStreetName:
    'ublProperties.delivery[0].deliveryParty.postalAddress.additionalStreetName.value',
  deliveryPartyBuildingNumber:
    'ublProperties.delivery[0].deliveryParty.address.buildingNumber.value',
  deliveryPartyCityName:
    'ublProperties.delivery[0].deliveryParty.postalAddress.cityName.value',
  deliveryPartyPostalZone:
    'ublProperties.delivery[0].deliveryParty.address.postalZone.value',
  deliveryPartyCountryCode:
    'ublProperties.delivery[0].deliveryParty.address.country.identificationCode.value',
  deliveryCustomerGLN:
    'ublProperties.deliveryCustomerParty.party.endpointID.value',
  shipmentDeliveryDate:
    'ublProperties.shipment.delivery.actualDeliveryDate.value',
  shipmentDeliveryTime:
    'ublProperties.shipment.delivery.actualDeliveryTime.value',
  deliveryLocationGLN: 'ublProperties.delivery[0].deliveryLocation.id.value',
  deliveryLocationName:
    'ublProperties.delivery[0].deliveryLocation.description[0].value',
  deliveryLocationAddressSchemeID:
    'ublProperties.delivery[0].deliveryLocation.address.id.schemeID',
  deliveryLocationStreetName:
    'ublProperties.delivery[0].deliveryLocation.address.streetName.value',
  deliveryLocationAdditionalStreetName:
    'ublProperties.delivery[0].deliveryLocation.address.additionalStreetName.value',
  deliveryLocationBuildingNumber:
    'ublProperties.delivery[0].deliveryLocation.address.buildingNumber.value',
  deliveryLocationCityName:
    'ublProperties.delivery[0].deliveryLocation.address.cityName.value',
  deliveryLocationPostalZone:
    'ublProperties.delivery[0].deliveryLocation.address.postalZone.value',
  deliveryLocationCountryCode:
    'ublProperties.delivery[0].deliveryLocation.address.country.identificationCode.value',
  invoiceTypeCode: 'ublProperties.invoiceTypeCode.value',
  note: 'ublProperties.note[0].value',
  efacturaFlag: 'ublProperties.note[1].value',
  taxNote: 'ublProperties.note[1].value',
  accountingCustomerContactElectronicMail: 'ublProperties.note[2].value',
  invoiceDocumentReferenceId:
    'ublProperties.billingReference[0].invoiceDocumentReference.id.value',
  invoiceDocumentReferenceIssueDate:
    'ublProperties.billingReference[0].invoiceDocumentReference.issueDate.value',
  lineCountNumeric: 'ublProperties.lineCountNumeric.value',
  paymentTermsQualifier: 'ublProperties.paymentTerms[0].id.value',
  paymentTermsStartEvent: 'ublProperties.paymentTerms[0].paymentMeansID.value',
  paymentTermsReferenceEventCode:
    'ublProperties.paymentTerms[0].referenceEventCode.value',
  paymentTermsSettlementPeriodCode:
    'ublProperties.paymentTerms[0].settlementPeriod.descriptionCode.value',
  paymentTermsSettlementPeriodDurationMeasure:
    'ublProperties.paymentTerms[0].settlementPeriod.durationMeasure.value',
  documentCurrencyCode: 'documentCurrencyCode.value',
  paymentExchangeRate: 'ublProperties.paymentExchangeRate',
  paymentExchangeRateSourceCurrencyCode:
    'ublProperties.paymentExchangeRate.sourceCurrencyCode.value',
  paymentExchangeRateTargetCurrencyCode:
    'ublProperties.paymentExchangeRate.targetCurrencyCode.value',
  paymentExchangeRateCalculationRate:
    'ublProperties.paymentExchangeRate.calculationRate.value',
  customizationID: 'ublProperties.customizationID.value',
  profileID: 'ublProperties.profileID.value',
  projectReferenceID: 'ublProperties.projectReference[0].id.value',
};

enum WebFormMode {
  //INVOICE
  DRAFT_INVOICE = 'MODE_DRAFT_INVOICE',
  SCRATCH_INVOICE = 'MODE_SCRATCH_INVOICE',

  //RECEIPT_ADVICE
  DRAFT_RECEIPT_ADVICE = 'MODE_DRAFT_RECEIPT_ADVICE',
  SCRATCH_RECEIPT_ADVICE = 'MODE_SCRATCH_RECEIPT_ADVICE',

  //DESPATCH_ADVICE
  DRAFT_DESPATCH_ADVICE = 'MODE_DRAFT_DESPATCH_ADVICE',
  SCRATCH_DESPATCH_ADVICE = 'MODE_SCRATCH_DESPATCH_ADVICE',

  // WAYBILL
  SCRATCH_WAYBILL = 'MODE_SCRATCH_WAYBILL',
  DRAFT_WAYBILL = 'MODE_DRAFT_WAYBILL',

  // ORDER
  SCRATCH_ORDER = 'MODE_SCRATCH_ORDER',
  DRAFT_ORDER = 'MODE_DRAFT_ORDER',
}

const TemplatesMetadata = {
  country: 'country',
  paymentMeansCode: 'paymentMeansCode',
  productGroup: 'productGroup',
  paymentTypePeriod: 'paymentTypePeriod',
  invoiceTypeCode: 'invoiceTypeCode',
  tax: 'tax',
  currency: 'currency',
  paymentTermsQualifier: 'paymentTermsQualifier',
  packageType: 'packageType',
  paymentReferenceEvent: 'paymentReferenceEvent',
  paymentStartEvent: 'paymentStartEvent',
};

const EmptyValue = '--';

enum ProcessStatus {
  ACCEPTED_BY_CUSTOMER = 'ACCEPTED_BY_CUSTOMER',
  ACCEPTED_BY_RECIPIENT = 'ACCEPTED_BY_RECIPIENT',
  ACCOUNT_CHECKED = 'ACCOUNT_CHECKED',
  ALLOCATION_IN_PROGRESS = 'ALLOCATION_IN_PROGRESS',
  DELIVERED = 'DELIVERED',
  DELIVERED_FROM_CONTENT_ERROR = 'DELIVERED_FROM_CONTENT_ERROR',
  CONTENT_ERROR = 'CONTENT_ERROR',
  DELIVERED_FROM_FORMAT_ERROR = 'DELIVERED_FROM_FORMAT_ERROR',
  FORMAT_ERROR = 'FORMAT_ERROR',
  ENRICHED = 'ENRICHED',
  INVALID_MATCHING = 'INVALID_MATCHING',
  LOCALLY_DELIVERED = 'LOCALLY_DELIVERED',
  MATCHING_ERROR = 'MATCHING_ERROR',
  MATCHING_IN_PROGRESS = 'MATCHING_IN_PROGRESS',
  PAID_BY_RECIPIENT = 'PAID_BY_RECIPIENT',
  PARTIAL_MATCHED = 'PARTIAL_MATCHED',
  PARTIALLY_PAID_BY_RECIPIENT = 'PARTIALLY_PAID_BY_RECIPIENT',
  PENDING_RECADV = 'PENDING_RECADV',
  PENDING_SCHEDULED_DELIVERY = 'PENDING_SCHEDULED_DELIVERY',
  PENDING_TO_CUSTOMER = 'PENDING_TO_CUSTOMER',
  PENDING_ENRICHMENTS = 'PENDING_ENRICHMENTS',
  PENDING_CORRECTION = 'PENDING_CORRECTION',
  WAITING_FOR_RECIPIENT = 'WAITING_FOR_RECIPIENT',
  RECEIVED_BY_CUSTOMER = 'RECEIVED_BY_CUSTOMER',
  RECEIVED_BY_DX = 'RECEIVED_BY_DX',
  RECEIVED_BY_RECIPIENT = 'RECEIVED_BY_RECIPIENT',
  RECEIVED_BY_SUPPLIER = 'RECEIVED_BY_SUPPLIER',
  REJECTED_BY_CUSTOMER = 'REJECTED_BY_CUSTOMER',
  REJECTED_BY_RECIPIENT = 'REJECTED_BY_RECIPIENT',
  REJECTED_BY_SUPPLIER = 'REJECTED_BY_SUPPLIER',
  RECEIVED_BY_BSP = 'RECEIVED_BY_BSP',
  RECEIVED_AND_VALIDATED_BY_BSP = 'RECEIVED_AND_VALIDATED_BY_BSP',
  REJECTED_BY_BSP = 'REJECTED_BY_BSP',
  SUCCESSFUL_MATCHED = 'SUCCESSFUL_MATCHED',
  UNDER_QUERY = 'UNDER_QUERY',
  CONDITIONALLY_ACCEPTED = 'CONDITIONALLY_ACCEPTED',
  IN_PROCESS = 'IN_PROCESS',
  SUSPENDED = 'SUSPENDED',
  SENT = 'SENT',
  NO_CREDIT = 'NO_CREDIT',
  NEW = 'NEW',
  NOTIFIED = 'NOTIFIED',
  NORMALIZED = 'NORMALIZED',
  VALIDATED = 'VALIDATED',
  DRAFT = 'DRAFT',
}

/**
 * Type of document inside Alfresco
 */
enum DocumentTypeCode {
  APERAK = 'APERAK',
  CATLOG = 'CATLOG',
  CNTCND = 'CNTCND',
  DESADV = 'DESADV',
  DELFOR = 'DELFOR',
  INVOIC = 'INVOIC',
  ORDERS = 'ORDERS',
  RECADV = 'RECADV',
  ATTACH = 'ATTACH',
  WAYBIL = 'WAYBIL',
}

enum InvoiceTypeCode {
  COMMERCIAL = '380',
  CANCELLATION = '381',
  CORRECTION = '384',
  SELF_BILLING = '389',
  ACCOUNTING = '751',
}

/**
 * Types of receipt advice
 * This info should also be available in the Alfresco metadata (edm:documentSubTypeCode)
 */
enum ReceiptAdviceSubType {
  GoodsReceiptAdvice = '632',
  GoodsReturnAdvice = '35E',
  AcknowledgementOfReceipt = '352',
}
/**
 * Types of order
 * This info should also be available in the Alfresco metadata (edm:documentSubTypeCode)
 */
enum OrderSubType {
  FinalOrder = '220',
  CrossDockingOrder = '50E',
  GoodsReturnOrder = '70E',
  InitialOrder = '105',
  UpdatedOrder = '231',
  NotModifiedUpdatedOrder = '320',
}

enum DespatchAdviceSubType {
  LogisticOnly = '1',
}

enum GenericSubType {
  FinalOrder = '220',
  CrossDockingOrder = '50E',
  GoodsReturnOrder = '70E',
  InitialOrder = '105',
  UpdatedOrder = '231',
  NotModifiedUpdatedOrder = '320',
  GoodsReceiptAdvice = '632',
  GoodsReturnAdvice = '35E',
  AcknowledgementOfReceipt = '352',
}

const SubTypeLabels = {
  [ReceiptAdviceSubType.AcknowledgementOfReceipt]:
    'dxMessages.receiptAdvices.ackReceiptSubType',
  [ReceiptAdviceSubType.GoodsReturnAdvice]:
    'dxMessages.receiptAdvices.goodsReturnSubType',
  [ReceiptAdviceSubType.GoodsReceiptAdvice]:
    'dxMessages.receiptAdvices.receiptAdviceSubType',
  [OrderSubType.InitialOrder]: 'dxMessages.orders.initialOrder',
  [OrderSubType.UpdatedOrder]: 'dxMessages.orders.updatedOrder',
  [OrderSubType.NotModifiedUpdatedOrder]:
    'dxMessages.orders.updatedOrderNotModified',
  [OrderSubType.FinalOrder]: 'dxMessages.orders.finalOrder',
  [OrderSubType.CrossDockingOrder]: 'dxMessages.orders.crossDocking',
  [OrderSubType.GoodsReturnOrder]: 'dxMessages.orders.goodsReturn',
  [InvoiceTypeCode.COMMERCIAL]: 'dxMessages.invoices.commercialInvoice',
  [InvoiceTypeCode.CANCELLATION]: 'dxMessages.invoices.cancelInvoice',
  [InvoiceTypeCode.CORRECTION]: 'dxMessages.invoices.correctiveInvoice',
  [InvoiceTypeCode.SELF_BILLING]: 'dxMessages.invoices.selfbillingInvoice',
  [InvoiceTypeCode.ACCOUNTING]: 'dxMessages.invoices.accountingInvoice',
  [DespatchAdviceSubType.LogisticOnly]:
    'dxMessages.despatchAdvices.logisticOnly',
};

enum NodeType {
  APERAK = 'edm:feedbackMessage',
  CATLOG = 'edm:catlog',
  CNTCND = 'edm:cntcnd',
  DESADV = 'edm:despatchAdvice',
  DELFOR = 'edm:delfor',
  INVOIC = 'edm:invoice',
  ORDERS = 'edm:order',
  RECADV = 'edm:recadv',
  ATTACH = 'edm:attachment',
  WAYBIL = 'edm:waybill',
}

enum RegulatorExtraDetailsType {
  EFACTURA_OUTBOUND = '1', // E-Factura outbound
  EFACTURA_INBOUND = '10',
  PEPPOL = '2', // Peppol outbound
  PEPPOL_INBOUND = '20',
}

const REST_ENDPOINTS = {
  // The root path of all the REST endpoints.
  ROOT: '/api',

  // Below the react-admin resource names of this App.
  // The full REST path info is: ROOT + '/' + <resource name>
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  // The authentication enpoints.
  SIGNIN: 'auth/signin',
  SIGNOUT: 'auth/signout',
  SWITCH_APP_TOKEN: 'auth/switchAppToken',
  SWITCH_COMPANY: 'auth/switchCompany',
  IMPERSONATE_ACCOUNT: 'auth/impersonateAccount',
  MY_COMPANIES: 'auth/myCompanies',

  // The account management resources.
  USER_MGMT_ACCOUNTS: 'configuration-accounts',
  USER_MGMT_COMPANIES: 'configuration-companies',
  USER_MGMT_PEOPLE: 'configuration-people',
  USER_MGMT_IDENTITY_PROVIDERS: 'configuration-identityProviders',
  USER_MGMT_DXFEATURES: 'configuration-dxfeatures',
  USER_MGMT_LOGINE_EVENTS: 'loginevents',
  USER_MGMT_ACCOUNT_LIFECYCLE_EVENTS: 'accountlifecycleevents',
  USER_MGMT_PERSON_PREFERENCES: 'configuration-preferences',

  // Messages
  MESSAGE: 'configuration-message',

  // The user task resource.
  BPM_TASKS: 'bpm/tasks',

  // The i2pr matchings of invoices.
  I2PR_MATCHING_INVOICE: 'nwaymatching-invoice',
  // The i2pr reverse matchings of recadvs.
  I2PR_MATCHING_RECADV: 'nwaymatching-recadv',
  // The pending items after reconciliations.
  I2PR_MATCHING_PENDING: 'nwaymatching-pending',

  // The repository of the document representations.
  // To get the PDF representation: DOC_REPRESENTATION_REPOSITOTY/<dxuid>/pdf
  DOC_REPRESENTATION_REPOSITOTY: 'document',
};
// All the keys of the objects stored into the browser local store.
const LOCAL_STORE_KEYS = {
  CURRENT_ACCOUNT: 'DX_CURRENT_ACCOUNT',
  PERSON_PREFERENCES: 'DX_PERSON_PREFERENCES',
};

export {
  Constants,
  DespatchAdviceSubType,
  DocumentTypeCode,
  EmptyValue,
  GenericSubType,
  InvoiceTypeCode,
  LOCAL_STORE_KEYS,
  Metadata,
  NodeType,
  OrderSubType,
  ProcessStatus,
  REST_ENDPOINTS,
  ReceiptAdviceSubType,
  RegulatorExtraDetailsType,
  SubTypeLabels,
  TemplatesMetadata,
  Ubl,
  WebFormMode,
};
