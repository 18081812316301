import {
  cellStyle,
  EmptyValueField,
  GenericSimpleField,
  GLNInput,
  Metadata,
  widthPresets,
} from '@dx-ui/dx-common';
import Typography from '@material-ui/core/Typography';
import { TextInput } from 'react-admin';

export const DeliveryLocationField = ({
  className,
  record = { properties: [] },
}) => {
  const address = record.properties[Metadata.locationAddress];
  const addressName = record.properties[Metadata.locationName];
  if (address !== undefined && addressName !== undefined) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: 250 }}>
        <Typography className={className}>
          {record.properties[Metadata.locationName]}
        </Typography>
        <Typography variant='caption'>
          {record.properties[Metadata.locationAddress]}
        </Typography>
      </div>
    );
  } else if (address !== undefined && addressName === undefined) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: 250 }}>
        <Typography variant='caption'>
          {record.properties[Metadata.locationAddress]}
        </Typography>
      </div>
    );
  } else if (address === undefined && addressName !== undefined) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: 250 }}>
        <Typography>{record.properties[Metadata.locationName]}</Typography>
      </div>
    );
  } else {
    return <EmptyValueField />;
  }
};

export const DeliveryLocationNameField = (props) => (
  <GenericSimpleField {...props} />
);

export const DeliveryLocationAddressField = (props) => (
  <GenericSimpleField {...props} />
);

export const GLNField = (props) => <GenericSimpleField {...props} />;

export const createColumnsDeliveryLocation = () => [
  {
    id: Metadata.locationAddress,
    label: 'dxMessages.headers.locationAddress',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.large,
    cellStyle: cellStyle,
    renderCell: (props) => <DeliveryLocationField {...props} />,
  },
  {
    id: Metadata.locationName,
    label: 'dxMessages.headers.deliveryLocationName',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    // Delivery location Name is part of the combined field DeliveryLocationField,
    // thus, it is only defined for being used by ExportUtils which aims to split combined fields.
    hiddenByDefault: true,
    displayInGroupingMode: false, // not selectable for display
    displayedOnlyInLists: true, // not in metadata
    width: widthPresets.large,
    cellStyle: cellStyle,
    renderCell: (props) => <DeliveryLocationNameField {...props} />,
  },
  {
    id: Metadata.gln,
    label: 'dxMessages.headers.gln',
    sortable: true,
    hiddenByDefault: true,
    width: widthPresets.medium,
    cellStyle: cellStyle,
    renderCell: (props) => <GLNField {...props} />,
  },
];

export const createDeliveryLocationFilters = () => [
  {
    id: Metadata.gln,
    filter: (props) => (
      <GLNInput
        label='dxMessages.headers.gln'
        source={Metadata.gln}
        {...props}
        resettable
      />
    ),
  },
  {
    id: Metadata.locationAddress,
    filter: (props) => (
      <TextInput
        source={Metadata.locationAddress}
        label='dxMessages.headers.locationAddress'
        resettable
        style={{ width: 200 }}
        {...props}
      />
    ),
  },
  {
    id: Metadata.locationName,
    filter: (props) => (
      <TextInput
        source={Metadata.locationName}
        label='dxMessages.headers.deliveryLocationName'
        resettable
        style={{ width: 150 }}
        {...props}
      />
    ),
  },
];
