import {
  Constants,
  DocumentTypeCode,
  formatDate,
  formatDateToAlfresco,
  getLocaleInfo,
  getMessages,
  Metadata,
  RegulatorExtraDetailsType,
  Ubl,
} from '@dx-ui/dx-common';
import { DomainMessages } from '@dx-ui/dx-common/src/intl/DomainMessages';
import {
  MonetaryTotalDetails,
  OrderDetails,
  OrderLineDetails,
  OrderModel,
  TaxSubtotalDetails,
  TaxTotalDetails,
} from '@dx-ui/lib-oasis-ubl-2.1/src/OrderModel';
import { cloneDeep, get, groupBy, set, unset } from 'lodash';
import * as math from 'mathjs';
import {
  buildAddressAsAString,
  getDeliveryLocationAddress,
} from '../modules/common/PreviewAddress';
import { FieldTypes } from '../shared';
import {
  Address,
  AlfrescoContent,
  AllowanceChargeItem,
  DesAdvLine,
  InvoiceLine,
  LegalMonetaryTotal,
  Line,
  OrderLine,
  P2pData,
  TaxItem,
  TaxTotalItem,
  ValueString,
} from '../shared/types';
import { round } from '../shared/webForms/utils';
import { DataHelpers } from './DataHelpers';
import { InvoiceService } from './InvoiceService';
import { OrderService } from './OrderService';
import { PeppolService } from './PeppolService';

// TODO: move the code from this class to service or document dedicated helper classes as most if this is dedicated to invoices

export interface CalculatedTotalValues {
  quantity: number | undefined;
  discount: number | undefined;
  discountPerUnitAmount: number | undefined;
  discountAmount: number | undefined;
  tax: number | undefined;
  taxPerUnitAmount: number | undefined;
  sgrTax: number | undefined;
  sgrTaxPerUnitAmount: number | undefined;
  unitPrice: number | undefined;
  vatPercentage: number | undefined;
  totalVat: number | undefined;
  totalWithoutVat: number | undefined;
  totalWithVat: number | undefined;
}

export interface CalculatedLineValues extends CalculatedTotalValues {
  taxCategory: any;
}

export class TaxAndPriceUtils {
  /**
   * Set Ubl field: legalMonetaryTotal
   */
  public static setLegalMonetaryTotal(
    data: P2pData,
    linesTotalWithoutVat: number | undefined,
    totalVat: number | undefined,
    currencyID: string
  ) {
    let documentAllowanceAmount = 0;
    let documentChargeAmount = 0;
    const documentLevelDiscounts = get(data, FieldTypes.DOC_LEVEL_DISCOUNTS);
    const documentLevelCharges = get(data, FieldTypes.DOC_LEVEL_CHARGES);
    if (
      documentLevelDiscounts !== undefined ||
      documentLevelCharges !== undefined
    ) {
      documentLevelDiscounts?.forEach((d) => {
        if (d && d.chargeIndicator?.value === undefined) {
          set(d, 'chargeIndicator.value', false);
        }
        documentAllowanceAmount =
          d?.amount?.value > 0
            ? documentAllowanceAmount + d.amount?.value
            : documentAllowanceAmount;
      });
      documentLevelCharges?.forEach((c) => {
        if (c && c.chargeIndicator?.value === undefined) {
          set(c, 'chargeIndicator.value', true);
        }
        documentChargeAmount =
          c?.amount?.value > 0
            ? documentChargeAmount + c.amount?.value
            : documentChargeAmount;
      });
    }

    const legalMonetaryTotal: LegalMonetaryTotal = {
      payableAmount: {
        value: undefined, // initialization
        currencyID,
      },
      prepaidAmount: {
        value: 0, // initialization
        currencyID,
      },
      payableRoundingAmount: {
        value: 0, // initialization
        currencyID,
      },
    };

    delete data.ublProperties.legalMonetaryTotal?.taxExclusiveAmount;
    /**
     * [[BR-CO-13]-Invoice total amount without VAT (BT-109) =
     *  Σ Invoice line net amount (BT-131)
     *  - Sum of allowances on document level (BT-107)
     *  + Sum of charges on document level (BT-108).]
     *
     * <Invoice/cac:LegalMonetaryTotal/cbc:TaxExclusiveAmount (BT-109) =
     *   Σ Invoice/cac:InvoiceLine/cbc:LineExtensionAmount (BT-131) (edm:TotalWithoutVat)
     *   - Invoice/cac:LegalMonetaryTotal/cbc:AllowanceTotalAmount (BT-107)
     *   + Invoice/cac:LegalMonetaryTotal/cbc:ChargeTotalAmount (BT-108)>
     */
    // BT-109
    if (linesTotalWithoutVat !== undefined) {
      legalMonetaryTotal.taxExclusiveAmount = {
        value: round(
          linesTotalWithoutVat - documentAllowanceAmount + documentChargeAmount
        ),
        currencyID,
      };
    }

    // BT-107 cac:LegalMonetaryTotal/cbc:AllowanceTotalAmount
    if (documentAllowanceAmount > 0) {
      legalMonetaryTotal.allowanceTotalAmount = {
        value: round(documentAllowanceAmount),
        currencyID,
      };
    }

    // BT-108 cac:LegalMonetaryTotal/cbc:ChargeTotalAmount
    if (documentChargeAmount > 0) {
      legalMonetaryTotal.chargeTotalAmount = {
        value: round(documentChargeAmount),
        currencyID,
      };
    }

    /**
     * [BR-CO-15]-Invoice total amount with VAT (BT-112) =
     *  Invoice total amount without VAT (BT-109)
     *  +
     *  Invoice total VAT amount (BT-110) (Invoice/cac:TaxTotal/cbc:TaxAmount).]
     */
    // BT-112
    if (
      legalMonetaryTotal?.taxExclusiveAmount?.value !== undefined &&
      totalVat !== undefined
    ) {
      legalMonetaryTotal.taxInclusiveAmount = {
        value: round(legalMonetaryTotal.taxExclusiveAmount.value + totalVat),
        currencyID,
      };
    }

    /**
     * [[BR-CO-16]-Amount due for payment (BT-115) (Invoice/cac:LegalMonetaryTotal/cbc:PayableAmount) =
     *  Invoice total amount with VAT (BT-112)
     *  -
     *    Paid amount (BT-113) (Invoice/cac:LegalMonetaryTotal/cbc:PrepaidAmount)
     *  +
     *    Rounding amount (BT-114) (Invoice/cac:LegalMonetaryTotal/cbc:PayableRoundingAmount).]
     **/

    // BT-115 (limited to BT-112)
    if (legalMonetaryTotal?.taxInclusiveAmount?.value !== undefined) {
      legalMonetaryTotal.payableAmount = {
        value: legalMonetaryTotal.taxInclusiveAmount.value,
        currencyID,
      };
    }

    data.ublProperties.legalMonetaryTotal = legalMonetaryTotal;
  }

  /**
   * Set Ubl field: AnticipatedMonetaryTotal
   */
  public static setAnticipatedMonetaryTotal(
    ublProperties: OrderModel,
    totalWithVat: number | undefined,
    totalWithoutVat: number | undefined,
    currencyID: string
  ) {
    if (totalWithVat === undefined) {
      return;
    }

    const anticipatedMonetaryTotal: MonetaryTotalDetails = {
      PayableAmount: [
        {
          _: totalWithVat,
          currencyID,
        },
      ],
    };

    if (totalWithVat !== undefined) {
      anticipatedMonetaryTotal.TaxInclusiveAmount = [
        {
          _: totalWithVat,
          currencyID,
        },
      ];
    }

    delete (ublProperties.Order[0] as OrderDetails)
      .AnticipatedMonetaryTotal?.[0]?.TaxExclusiveAmount;
    if (totalWithoutVat !== undefined) {
      anticipatedMonetaryTotal.TaxExclusiveAmount = [
        {
          _: totalWithoutVat,
          currencyID,
        },
      ];
    }

    ublProperties.Order[0].AnticipatedMonetaryTotal = [
      anticipatedMonetaryTotal,
    ];
  }

  public static buildTaxTotalUBL21(
    taxSubtotals: TaxItem[] | undefined = [],
    currencyID: string
  ): TaxTotalDetails | undefined {
    if (currencyID === undefined) {
      throw new Error('currencyID should be defined');
    }

    const taxSubtotalsUBL21: TaxSubtotalDetails[] = [];
    taxSubtotals.forEach((taxSubtotal) => {
      const taxSubtotalUBL21: any = {};
      // Vat percentage
      set(taxSubtotalUBL21, 'Percent[0]._', taxSubtotal.percent?.value);

      // totalVat
      set(taxSubtotalUBL21, 'TaxAmount[0]._', taxSubtotal.taxAmount?.value);
      set(
        taxSubtotalUBL21,
        'TaxAmount[0].currencyID',
        taxSubtotal.taxAmount?.currencyID
      );

      // totalWithoutVat
      set(
        taxSubtotalUBL21,
        'TaxableAmount[0]._',
        taxSubtotal.taxableAmount?.value
      );
      set(
        taxSubtotalUBL21,
        'TaxableAmount[0].currencyID',
        taxSubtotal.taxableAmount?.currencyID
      );

      // Tax Scheme
      set(
        taxSubtotalUBL21,
        'TaxCategory[0].TaxScheme[0].ID[0]._',
        taxSubtotal.taxCategory.taxScheme.id.value
      );
      set(
        taxSubtotalUBL21,
        'TaxCategory[0].TaxScheme[0].Name[0]._',
        taxSubtotal.taxCategory.taxScheme.name.value
      );
      set(
        taxSubtotalUBL21,
        'TaxCategory[0].TaxScheme[0].TaxTypeCode[0]._',
        taxSubtotal.taxCategory.taxScheme.taxTypeCode.value
      );

      taxSubtotalsUBL21.push(taxSubtotalUBL21);
    });

    const taxTotal: TaxTotalDetails = {
      TaxSubtotal: taxSubtotalsUBL21,
    } as TaxTotalDetails;

    let totalVat: number | undefined = 0;
    for (let index = 0; index < taxSubtotalsUBL21?.length; index++) {
      const element = taxSubtotalsUBL21[index];
      const vat = element?.TaxAmount?.[0]?._;
      if (vat === undefined) {
        totalVat = undefined;
        break;
      }
      totalVat += vat;
    }

    if (totalVat !== undefined) {
      taxTotal.TaxAmount = [
        {
          _: totalVat || 0,
          currencyID,
        },
      ];
    }

    return taxTotal;
  }

  public static buildTaxTotal(
    taxSubtotals: TaxItem[],
    currencyID: string
  ): TaxTotalItem | undefined {
    if (currencyID === undefined) {
      throw new Error('currencyID should be defined');
    }

    const taxTotal: TaxTotalItem = {
      taxSubtotal: taxSubtotals,
    } as TaxTotalItem;

    let totalVat: number | undefined = 0;
    for (let index = 0; index < taxSubtotals.length; index++) {
      const element = taxSubtotals[index];
      const vat = element?.taxAmount?.value;
      if (vat === undefined) {
        totalVat = undefined;
        break;
      }
      totalVat += vat;
    }

    if (totalVat !== undefined) {
      taxTotal.taxAmount = {
        value: totalVat,
        currencyID,
      };
    }

    return taxTotal;
  }

  public static buildTaxSubtotal(
    percent: number | undefined,
    totalVat: number | undefined,
    totalWithoutVat: number | undefined,
    currencyID: string,
    taxCategory: any
  ): TaxItem {
    const taxSubtotal: TaxItem = {} as TaxItem;

    if (taxCategory !== undefined) {
      taxSubtotal.taxCategory = {
        taxScheme: {
          id: {
            value: taxCategory.taxScheme?.id?.value
              ? taxCategory?.taxScheme?.id?.value
              : Constants.TAX_SCHEME_ID_7,
          },
          name: { value: taxCategory.taxScheme?.name?.value },
          taxTypeCode: {
            value: taxCategory.taxScheme?.taxTypeCode?.value
              ? taxCategory.taxScheme?.taxTypeCode?.value
              : Constants.TAX_CATEGORY_CODE_VAT,
          },
        },
      };
      if (taxCategory.taxExemptionReasonCode?.value) {
        set(
          taxSubtotal.taxCategory,
          'taxExemptionReasonCode.value',
          taxCategory.taxExemptionReasonCode?.value
        );
      }
      if (taxCategory.taxExemptionReason?.value) {
        set(
          taxSubtotal.taxCategory,
          'taxExemptionReason.value',
          taxCategory.taxExemptionReason?.value
        );
      }
    } else {
      taxSubtotal.taxCategory = {
        taxScheme: TaxAndPriceUtils.getDefaultTaxScheme(),
      };
    }

    // vat percent
    if (percent !== undefined) {
      taxSubtotal.percent = { value: percent };
    }

    //total VAT
    if (totalVat !== undefined) {
      taxSubtotal.taxAmount = {
        value: round(totalVat),
        currencyID,
      };
    }

    //total without VAT
    if (totalWithoutVat !== undefined) {
      taxSubtotal.taxableAmount = {
        value: round(totalWithoutVat),
        currencyID,
      };
    }

    return taxSubtotal;
  }

  public static getDefaultTaxScheme(): {
    id: ValueString;
    name: ValueString;
    taxTypeCode: ValueString;
  } {
    return {
      id: { value: Constants.TAX_SCHEME_ID_7 },
      name: { value: Constants.TAX_SCHEME_NAME_S },
      taxTypeCode: { value: Constants.TAX_CATEGORY_CODE_VAT },
    };
  }

  /**
   * Computes price based on discount and green tax
   */
  public static computePrice(
    unitPrice: number | undefined,
    quantity: number | undefined,
    vatPercentage?: number,
    discount?: number,
    tax?: number,
    sgrTax?: number | undefined
  ): CalculatedTotalValues {
    let discountPerUnitAmount: number | undefined;
    let discountAmount: number | undefined;
    let taxPerUnitAmount: number | undefined;
    let sgrTaxPerUnitAmount: number | undefined;
    let totalVat: number | undefined;
    let totalWithoutVat: number | undefined;
    let totalWithVat: number | undefined;

    // use math library to avoid javascript floating point operations precision issues
    // ex: 0.1 + 0.2 = 0.30000000000004

    if (unitPrice !== undefined && quantity !== undefined) {
      let discountedPrice = unitPrice;
      if (discount !== undefined) {
        discountedPrice = unitPrice * (1 - discount / 100);
      }

      if (tax !== undefined) {
        discountedPrice = discountedPrice + tax;
      }

      const price = TaxAndPriceUtils.limitDecimals(quantity * discountedPrice);

      totalVat =
        vatPercentage !== undefined && price !== undefined
          ? TaxAndPriceUtils.limitDecimals((vatPercentage * price) / 100)
          : undefined;

      totalWithoutVat =
        sgrTax !== undefined
          ? TaxAndPriceUtils.limitDecimals(quantity * sgrTax)
          : price;
      totalWithVat =
        totalVat !== undefined && totalWithoutVat !== undefined
          ? TaxAndPriceUtils.limitDecimals(totalVat + totalWithoutVat)
          : undefined;

      discountPerUnitAmount =
        price !== undefined && discount !== undefined
          ? TaxAndPriceUtils.limitDecimals((price * discount) / 100)
          : undefined;

      discountAmount =
        quantity !== undefined && discountPerUnitAmount !== undefined
          ? TaxAndPriceUtils.limitDecimals(
              (quantity * discountPerUnitAmount) / 100
            )
          : undefined;

      taxPerUnitAmount =
        quantity !== undefined && tax !== undefined
          ? TaxAndPriceUtils.limitDecimals(quantity * tax)
          : undefined;

      sgrTaxPerUnitAmount =
        quantity !== undefined && sgrTax !== undefined
          ? TaxAndPriceUtils.limitDecimals(quantity * sgrTax)
          : undefined;
    }

    return {
      quantity,
      discount,
      discountPerUnitAmount,
      discountAmount,
      tax,
      taxPerUnitAmount,
      sgrTax,
      sgrTaxPerUnitAmount,
      unitPrice,
      vatPercentage,
      totalVat,
      totalWithoutVat,
      totalWithVat,
    };
  }

  public static limitDecimals(value: number | undefined) {
    if (value === undefined) {
      return value;
    }

    // round to a random 7 decimals before going further
    // fix ticket https://docprocess.atlassian.net/browse/DXFL-3015
    return math.round(value, 7); // '0.3' instead of 0.30000000000000004
  }

  /**
   * Ensures that the draft invoice will correctly read the
   * document level allowance charge
   * @param data draft invoice data
   */
  public static ensureDocumentLevelAllowanceCharge(data) {
    if (data.ublProperties.allowanceCharge) {
      const docDiscountNodes = data.ublProperties.allowanceCharge.filter(
        (a) => a.chargeIndicator.value === false
      );
      const docChargeNodes = data.ublProperties.allowanceCharge.filter(
        (a) => a.chargeIndicator.value === true
      );
      docDiscountNodes?.forEach((c) => {
        // avoid conflict definition for templates
        if (c.allowanceChargeReasonCode?.value !== undefined) {
          set(
            c,
            'discount_reason_code.value',
            c.allowanceChargeReasonCode.value
          );
        }
      });

      docChargeNodes?.forEach((c) => {
        // avoid conflict definition for templates
        if (c.allowanceChargeReasonCode?.value !== undefined) {
          set(c, 'charge_reason_code.value', c.allowanceChargeReasonCode.value);
        }
      });
      delete data.ublProperties.allowanceCharge;
      set(data, FieldTypes.DOC_LEVEL_DISCOUNTS, docDiscountNodes);
      set(data, FieldTypes.DOC_LEVEL_CHARGES, docChargeNodes);
    }
  }
}

export class LineProcessor {
  public static SGR_EAN = '9814567890126';

  public static isLineForOrder(line: InvoiceLine | OrderLineDetails) {
    return (line as OrderLineDetails)?.LineItem?.length > 0;
  }

  public static isLineSGR(line: InvoiceLine | OrderLineDetails): boolean {
    if (
      (line as InvoiceLine)?.item?.standardItemIdentification?.id.value ===
      LineProcessor.SGR_EAN
    ) {
      return true;
    } else if (
      (line as any)?.item?.[0]?.standardItemIdentification?.id.value ===
      LineProcessor.SGR_EAN
    ) {
      return true;
    } else if (
      (line as any)?.allowanceCharge?.find(
        (_) =>
          _?.allowanceChargeReasonCode?.value === Constants.SGR_REASON &&
          _?.perUnitAmount.value !== undefined
      ) !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  }

  public static getTotalVat(line: InvoiceLine | OrderLineDetails) {
    if (LineProcessor.isLineForOrder(line)) {
      return (line as OrderLineDetails).LineItem?.[0].TaxTotal?.[0]
        ?.TaxSubtotal?.[0]?.TaxAmount?.[0]?._;
    } else {
      return (line as InvoiceLine).taxTotal?.[0]?.taxSubtotal?.[0]?.taxAmount
        ?.value;
    }
  }

  public static setTotalVat(
    line: Line | OrderLineDetails,
    totalVat: number | undefined
  ) {
    if (LineProcessor.isLineForOrder(line)) {
      set(
        line as OrderLineDetails,
        'LineItem[0].TaxTotal[0].TaxSubtotal[0].TaxAmount[0]._',
        totalVat
      );
    } else {
      if ((line as Line).taxTotal?.[0]?.taxSubtotal?.[0]?.taxAmount) {
        set(
          line as Line,
          'taxTotal[0].taxSubtotal[0].taxAmount.value',
          totalVat
        );
      } else {
        throw new Error('Node cannot be empty');
      }
    }
  }

  public static getTotalWithoutVat(line: InvoiceLine | OrderLineDetails) {
    if (LineProcessor.isLineForOrder(line)) {
      return (line as OrderLineDetails).LineItem?.[0]?.TaxTotal?.[0]
        ?.TaxSubtotal?.[0]?.TaxableAmount?.[0]._;
    } else {
      return (line as InvoiceLine).taxTotal?.[0]?.taxSubtotal?.[0]
        ?.taxableAmount?.value;
    }
  }

  public static setTotalWithoutVat(
    line: Line | OrderLineDetails,
    totalWithoutVat: number | undefined
  ) {
    if (LineProcessor.isLineForOrder(line) && totalWithoutVat) {
      set(
        line as OrderLineDetails,
        'LineItem[0].TaxTotal[0].TaxSubtotal[0].TaxableAmount[0]._',
        totalWithoutVat
      );
    } else {
      if ((line as Line)?.lineExtensionAmount) {
        set(line as Line, 'lineExtensionAmount.value', totalWithoutVat);
      } else {
        throw new Error('Node should not be empty');
      }

      if ((line as Line).taxTotal?.[0]?.taxSubtotal?.[0]?.taxableAmount) {
        set(
          line as Line,
          'taxTotal[0].taxSubtotal[0].taxableAmount.value',
          totalWithoutVat
        );
      } else {
        throw new Error('Node should not be empty');
      }
    }
  }

  public static getTotalWithVat(line: InvoiceLine | OrderLineDetails) {
    if (LineProcessor.isLineForOrder(line)) {
      const totalWithoutVat = (line as OrderLineDetails).LineItem?.[0]
        ?.TaxTotal?.[0]?.TaxSubtotal?.[0]?.TaxableAmount?.[0]?._;
      const totalVat = (line as OrderLineDetails).LineItem?.[0]?.TaxTotal?.[0]
        ?.TaxAmount?.[0]?._;
      const totalWithVat =
        totalVat !== undefined && totalWithoutVat !== undefined
          ? TaxAndPriceUtils.limitDecimals(totalVat + totalWithoutVat)
          : undefined;

      return totalWithVat;
    } else {
      const totalWithoutVat = (line as InvoiceLine).lineExtensionAmount?.value;
      const totalVat = (line as InvoiceLine).taxTotal?.[0]?.taxAmount?.value;
      const totalWithVat =
        totalVat !== undefined && totalWithoutVat !== undefined
          ? TaxAndPriceUtils.limitDecimals(totalVat + totalWithoutVat)
          : undefined;

      return totalWithVat;
    }
  }

  public static getTaxCategory(line: InvoiceLine | OrderLineDetails): any {
    if (LineProcessor.isLineForOrder(line)) {
      return (line as OrderLineDetails)?.LineItem?.[0]?.TaxTotal?.[0]
        ?.TaxSubtotal?.[0].TaxCategory;
    } else {
      return (line as InvoiceLine)?.taxTotal?.[0]?.taxSubtotal?.[0]
        ?.taxCategory;
    }
  }

  public static getVATPercentage(
    line: InvoiceLine | OrderLineDetails
  ): number | undefined {
    if (LineProcessor.isLineForOrder(line)) {
      return (line as OrderLineDetails).LineItem?.[0]?.TaxTotal?.[0]
        ?.TaxSubtotal?.[0]?.Percent?.[0]?._;
    } else {
      return (line as InvoiceLine).taxTotal?.[0]?.taxSubtotal?.[0]?.percent
        ?.value;
    }
  }

  public static getUnitPrice(
    line: InvoiceLine | OrderLineDetails
  ): number | undefined {
    if (LineProcessor.isLineForOrder(line)) {
      return (line as OrderLineDetails)?.LineItem?.[0]?.Price?.[0]
        .PriceAmount[0]._;
    } else {
      return (line as InvoiceLine)?.price?.priceAmount?.value;
    }
  }

  /**
   * Ensures allowanceCharge integrity
   */
  public static ensureAllowanceChargeAndDiscount(
    line: Line | OrderLineDetails,
    currencyID: string
  ) {
    if (LineProcessor.isLineForOrder(line)) {
      return;
    }
    const items: AllowanceChargeItem[] = [];

    let discount = DataHelpers.getDiscountValue(
      DataHelpers.getDiscountNode(line)
    );
    let greenTax = DataHelpers.getGreenTaxValue(
      DataHelpers.getGreenTaxNode(line)
    );

    let sugarTax = DataHelpers.getSugarTaxValue(
      DataHelpers.getSugarTaxNode(line)
    );

    let sgrTaxPerUnitAmount = DataHelpers.getSGRTaxValue(
      DataHelpers.getSGRNode(line)
    );
    let sgrTaxAmount = DataHelpers.getSGRTaxAmountValue(
      DataHelpers.getSGRNode(line)
    );

    const discountEntry: AllowanceChargeItem = {
      allowanceChargeReason: { value: Constants.DISCOUNT_REASON },
      allowanceChargeReasonCode: { value: Constants.DISCOUNT_REASON },
      chargeIndicator: { value: false },
      multiplierFactorNumeric: { value: discount },
      amount: {
        value: undefined,
        currencyID,
      },
    };

    const greenTaxEntry: AllowanceChargeItem = {
      allowanceChargeReason: { value: Constants.GREEN_TAX_REASON },
      allowanceChargeReasonCode: { value: Constants.GREEN_TAX_REASON },
      chargeIndicator: { value: true },
      amount: {
        value: undefined,
        currencyID,
      },
      perUnitAmount: { value: greenTax, currencyID },
    };

    const sugarTaxEntry: AllowanceChargeItem = {
      allowanceChargeReason: { value: 'Sugar Tax' },
      allowanceChargeReasonCode: { value: Constants.SUGAR_TAX_REASON },
      chargeIndicator: { value: true },
      amount: {
        value: undefined,
        currencyID,
      },
      perUnitAmount: { value: sugarTax, currencyID },
    };

    const sgrTaxEntry: AllowanceChargeItem = {
      allowanceChargeReason: { value: Constants.SGR_REASON },
      allowanceChargeReasonCode: { value: Constants.SGR_REASON },
      chargeIndicator: { value: true },
      amount: {
        value: sgrTaxAmount,
        currencyID,
      },
      perUnitAmount: { value: sgrTaxPerUnitAmount, currencyID },
    };

    items.push(discountEntry);
    items.push(greenTaxEntry);
    items.push(sgrTaxEntry);
    items.push(sugarTaxEntry);

    line[Ubl.allowanceCharge] = items;

    if ((greenTax || sugarTax) && !line[FieldTypes.Taxes]?.length) {
      // initializes the local Taxes Object
      const taxes: any[] = [];
      if (greenTax) {
        taxes.push({ _: FieldTypes.GT_Amount, value: greenTax });
      }
      if (sugarTax) {
        taxes.push({ _: FieldTypes.SGT_Amount, value: sugarTax });
      }
      line[FieldTypes.Taxes] = taxes;
    }
  }

  /**
   * Add discount and green Tax
   */
  public static calculateDiscountAndGreenTax(
    line: InvoiceLine | OrderLineDetails
  ) {
    if (LineProcessor.isLineForOrder(line)) {
      return;
    }
    if (!(line as InvoiceLine)?.allowanceCharge?.length) {
      delete (line as InvoiceLine).allowanceCharge;
    }

    const currencyID = LineProcessor.getCurrencyID(line);

    // ensure both discount and green tax will have entries in the line
    LineProcessor.ensureAllowanceChargeAndDiscount(line, currencyID);

    const price = (line as InvoiceLine)?.price?.priceAmount?.value ?? 0;
    const quantity = (line as InvoiceLine)?.invoicedQuantity?.value ?? 0;

    if ((line as InvoiceLine)?.allowanceCharge?.length === 4) {
      //discount
      const discountEntry = (line as InvoiceLine)?.allowanceCharge![0]; // allowanceCharge never empty here
      const discount = discountEntry?.multiplierFactorNumeric?.value;
      const discountPerUnitAmount = discount
        ? (price * discount) / 100
        : undefined;

      discountEntry.amount = {
        value: discountPerUnitAmount
          ? round(quantity * discountPerUnitAmount)
          : undefined,
        currencyID,
      };
      discountEntry.perUnitAmount = {
        value: round(discountPerUnitAmount),
        currencyID,
      };

      discountEntry.baseAmount = {
        value: round(price * quantity),
        currencyID,
      };

      //green tax
      const greenTaxEntry = (line as InvoiceLine)?.allowanceCharge![1]; // allowanceCharge never empty here
      const greenTax = greenTaxEntry?.perUnitAmount?.value;
      greenTaxEntry.amount = {
        value: greenTax ? round(quantity * greenTax) : undefined,
        currencyID,
      };

      // SGR tax
      const sgrTaxEntry = (line as InvoiceLine)?.allowanceCharge![2]; // allowanceCharge never empty here
      const sgrTax = sgrTaxEntry?.perUnitAmount?.value;
      sgrTaxEntry.amount = {
        value: sgrTax ? round(quantity * sgrTax) : undefined,
        currencyID,
      };

      //sugar tax
      const sugarTaxEntry = (line as InvoiceLine)?.allowanceCharge![3]; // allowanceCharge never empty here
      const sugarTax = sugarTaxEntry?.perUnitAmount?.value;
      sugarTaxEntry.amount = {
        value: sugarTax ? round(quantity * sugarTax) : undefined,
        currencyID,
      };
    }
  }

  public static getCurrencyID(line: Line | OrderLineDetails) {
    const currencyID =
      (line as InvoiceLine)?.lineExtensionAmount?.currencyID ||
      (line as OrderLineDetails)?.LineItem?.[0].Price?.[0].PriceAmount?.[0]
        .currencyID;

    if (!currencyID) {
      throw new Error(
        'line.lineExtensionAmount.currencyID should not be empty'
      );
    }

    return currencyID;
  }

  public static calculateTaxTotal(
    line: Line | OrderLineDetails,
    calculatedLineValues: CalculatedLineValues
  ) {
    const currencyID = LineProcessor.getCurrencyID(line);

    const taxSubtotal = TaxAndPriceUtils.buildTaxSubtotal(
      calculatedLineValues.vatPercentage,
      calculatedLineValues.totalVat,
      calculatedLineValues.totalWithoutVat,
      currencyID,
      calculatedLineValues.taxCategory
    );

    if (taxSubtotal) {
      if (LineProcessor.isLineForOrder(line)) {
        // ORDER
        const taxTotal = TaxAndPriceUtils.buildTaxTotalUBL21(
          [taxSubtotal],
          currencyID
        );
        delete (line as OrderLineDetails).LineItem[0].TaxTotal;
        if (taxTotal) {
          (line as OrderLineDetails).LineItem[0].TaxTotal = [taxTotal];
        }
      } else {
        // INVOICE
        const taxTotal = TaxAndPriceUtils.buildTaxTotal(
          [taxSubtotal],
          currencyID
        );
        delete (line as Line).taxTotal;
        if (taxTotal) {
          (line as Line).taxTotal = [taxTotal];
        }
      }
    }
  }

  public static calculateLineValues(line: Line): CalculatedLineValues {
    const quantity: number | undefined =
      (line as InvoiceLine)?.invoicedQuantity?.value || // invoice
      (line as DesAdvLine)?.deliveredQuantity?.value || // despatch advice
      (line as OrderLine)?.quantity?.value || // order
      (line as OrderLineDetails)?.LineItem?.[0]?.Quantity?.[0]?._; // order UBL2.1
    const discount: number | undefined = DataHelpers.getDiscountValue(
      DataHelpers.getDiscountNode(line)
    );
    const greenTax: number | undefined = DataHelpers.getGreenTaxValue(
      DataHelpers.getGreenTaxNode(line)
    );
    const sgrTax: number | undefined = DataHelpers.getSGRTaxValue(
      DataHelpers.getSGRNode(line)
    );

    const sugarTax: number | undefined = DataHelpers.getSugarTaxValue(
      DataHelpers.getSugarTaxNode(line)
    );

    let unitPrice: number | undefined = LineProcessor.getUnitPrice(line);

    let vatPercentage: number | undefined =
      LineProcessor.getVATPercentage(line);

    if (LineProcessor.isLineSGR(line)) {
      vatPercentage = 0;
      unitPrice = 0;
    }

    // CAUTION : NumberInput reset value is ''
    let tax: number =
      greenTax !== undefined && (greenTax as any) !== '' ? greenTax : 0;
    tax =
      sugarTax !== undefined && (sugarTax as any) !== '' ? tax + sugarTax : tax;

    // Applies discount if any and then adds GT
    const calculatedValues = TaxAndPriceUtils.computePrice(
      unitPrice,
      quantity,
      vatPercentage,
      discount,
      tax,
      sgrTax
    );

    return {
      quantity,
      discount,
      discountPerUnitAmount: calculatedValues.discountPerUnitAmount,
      discountAmount: calculatedValues.discountAmount,
      tax,
      taxPerUnitAmount: calculatedValues.taxPerUnitAmount,
      sgrTax,
      sgrTaxPerUnitAmount: calculatedValues.sgrTaxPerUnitAmount,
      unitPrice,
      vatPercentage,
      totalVat: calculatedValues.totalVat,
      totalWithoutVat: calculatedValues.totalWithoutVat,
      totalWithVat: round(calculatedValues.totalWithVat),
      taxCategory: LineProcessor.getTaxCategory(line),
    };
  }

  public static calculateTaxesAndPrices(
    line: Line | OrderLineDetails,
    calculatedValues: CalculatedLineValues,
    currencyID: string
  ) {
    //set total without VAT and currencyID on line level
    if (LineProcessor.isLineForOrder(line)) {
      // ORDER
      set(line, 'LineItem[0].TaxTotal[0].TaxSubtotal[0].TaxableAmount[0]._', {
        _: round(calculatedValues.totalWithoutVat),
        currencyID,
      });

      // ensure currencyID is present with the price amount
      set(line, 'LineItem[0].Price[0].PriceAmount[0].currencyID', currencyID);
    } else {
      // INVOICES
      (line as Line).lineExtensionAmount = {
        value: round(calculatedValues.totalWithoutVat),
        currencyID,
      };

      // ensure currencyID is present with the price amount
      if ((line as Line)?.price?.priceAmount) {
        set(line as Line, 'price.priceAmount.currencyID', currencyID);
      }
    }

    // discount and green tax
    LineProcessor.calculateDiscountAndGreenTax(line);

    LineProcessor.calculateTaxTotal(line, calculatedValues);
  }
}

class MetadataTotalsUtils {
  public static getTotals(data: P2pData) {
    const totalVat = get(data.properties, Metadata.totalVat);
    const totalWithoutVat = get(data.properties, Metadata.totalWithoutVat);
    const totalWithVat = get(data.properties, Metadata.totalWithVat);

    return { totalVat, totalWithoutVat, totalWithVat };
  }

  public static setTotals(
    data: P2pData,
    totalVat: number | undefined,
    totalWithoutVat: number | undefined,
    totalWithVat: number | undefined
  ) {
    if (totalVat !== undefined) {
      set(data.properties, Metadata.totalVat, totalVat);
    } else {
      set(data.properties, Metadata.totalVat, null);
    }

    if (totalWithoutVat !== undefined) {
      set(data.properties, Metadata.totalWithoutVat, totalWithoutVat);
    } else {
      set(data.properties, Metadata.totalWithoutVat, null);
    }

    if (totalWithVat !== undefined) {
      set(data.properties, Metadata.totalWithVat, totalWithVat);
    } else {
      set(data.properties, Metadata.totalWithVat, null);
    }
  }
}

/**
 * Helper methods related to the form editor data
 */
export class FormDataHelpers {
  private static formatDatesForAlfresco(data: P2pData) {
    set(
      data.properties,
      Metadata.issueDate,
      formatDateToAlfresco(get(data.properties, Metadata.issueDate))
    );
    set(
      data.properties,
      Metadata.orderDate,
      formatDateToAlfresco(get(data.properties, Metadata.orderDate))
    );
    set(
      data.properties,
      Metadata.receiptAdviceDate,
      formatDateToAlfresco(get(data.properties, Metadata.receiptAdviceDate))
    );
    set(
      data.properties,
      Metadata.despatchAdviceDate,
      formatDateToAlfresco(get(data.properties, Metadata.despatchAdviceDate))
    );
    set(
      data.properties,
      Metadata.contractDate,
      formatDateToAlfresco(get(data.properties, Metadata.contractDate))
    );

    // General
    if (get(data, Ubl.dueDate)) {
      set(data, Ubl.dueDate, formatDate(get(data, Ubl.dueDate)));
    }

    // delivery
    if (get(data, Ubl.deliveryDate)) {
      set(data, Ubl.deliveryDate, formatDate(get(data, Ubl.deliveryDate)));
      set(
        data.properties,
        Metadata.deliveryDate,
        formatDateToAlfresco(get(data, Ubl.deliveryDate))
      );
    } else {
      if (get(data.properties, Metadata.deliveryDate)) {
        delete data.properties[Metadata.deliveryDate];
      }
    }

    // shipment
    if (get(data, Ubl.shipmentDeliveryTime)) {
      set(
        data,
        Ubl.shipmentDeliveryTime,
        formatDate(get(data, Ubl.shipmentDeliveryTime))
      );
    }

    // billingReference
    if (get(data, Ubl.invoiceDocumentReferenceIssueDate)) {
      set(
        data,
        Ubl.invoiceDocumentReferenceIssueDate,
        formatDate(get(data, Ubl.invoiceDocumentReferenceIssueDate))
      );
    }
  }

  private static buildTaxSubtotalDocumentDiscountOrCharge(
    documentDiscountOrCharge: any,
    defaultReasonCode: string,
    currencyID: string,
    discount: boolean
  ) {
    const discountOrChargeSubtotals: TaxItem[] = [];

    const groupedValuesByScheme = groupBy(
      documentDiscountOrCharge,
      (values: any) => {
        if (
          values?.amount?.value !== undefined &&
          values?.amount?.value !== null &&
          values?.taxCategory?.percent?.value !== undefined &&
          values?.taxCategory?.percent?.value !== null
        ) {
          const allowanceReasonCode: string =
            values.discount_reason_code?.value ||
            values.charge_reason_code?.value ||
            defaultReasonCode;
          return `${values.taxCategory?.id?.value}_${allowanceReasonCode}`;
        }
      }
    );

    const sortedSchemeKeys = Object.keys(groupedValuesByScheme);
    sortedSchemeKeys.forEach((discountOrCharge) => {
      if (
        groupedValuesByScheme[discountOrCharge][0]?.taxCategory?.percent
          ?.value !== undefined &&
        groupedValuesByScheme[discountOrCharge][0]?.taxCategory?.percent
          ?.value !== null &&
        groupedValuesByScheme[discountOrCharge][0]?.taxCategory?.percent
          ?.value !== '' &&
        groupedValuesByScheme[discountOrCharge][0]?.amount?.value !==
          undefined &&
        groupedValuesByScheme[discountOrCharge][0]?.amount?.value !== null &&
        groupedValuesByScheme[discountOrCharge][0]?.amount?.value !== ''
      ) {
        const groupedSchemeByPercent = groupBy(
          groupedValuesByScheme[discountOrCharge],
          (values: any) => values.taxCategory.percent.value
        );

        const sortedPercentKeys = Object.keys(groupedSchemeByPercent)
          .map((key) => +key)
          .filter((key) => !isNaN(key))
          .sort((a, b) => a - b);
        sortedPercentKeys.forEach((p) => {
          const { taxableAmount, taxAmount, percent, taxCategory } =
            groupedSchemeByPercent[p].reduce(
              (acc: any, values) => {
                // No reason code for tax category standard
                const taxCategory =
                  values.taxCategory.id.value === Constants.TAX_SCHEME_ID_S
                    ? {
                        id: values.taxCategory.id,
                        percent: values.taxCategory.percent,
                        taxScheme: {
                          name: { value: values.taxCategory.id.value },
                          id: { value: values.taxCategory.id.value },
                        },
                      }
                    : {
                        id: values.taxCategory.id,
                        percent: values.taxCategory.percent,
                        taxExemptionReason: {
                          value: discount
                            ? values.discount_reason_code?.value
                            : values.charge_reason_code?.value,
                        },
                        taxScheme: {
                          name: { value: values.taxCategory.id.value },
                          id: { value: values.taxCategory.id.value },
                        },
                      };
                return {
                  taxableAmount: TaxAndPriceUtils.limitDecimals(
                    acc.taxableAmount + round(values.amount.value)
                  ),
                  taxAmount: TaxAndPriceUtils.limitDecimals(
                    round(
                      (acc.taxableAmount + round(values.amount.value)) *
                        (values.taxCategory.percent.value / 100)
                    )
                  ),
                  percent: values.taxCategory.percent,
                  taxCategory: taxCategory,
                };
              },
              {
                taxableAmount: 0,
                taxAmount: 0,
                percent: 0,
                taxCategory: undefined,
              }
            );

          const taxSubtotal = {
            taxableAmount: {
              value: discount ? taxableAmount * -1 : taxableAmount,
              currencyID: currencyID,
            },
            taxAmount: {
              value:
                taxAmount !== 0
                  ? discount
                    ? taxAmount * -1
                    : taxAmount
                  : taxAmount,
              currencyID: currencyID,
            },
            percent: percent,
            taxCategory: taxCategory,
          };
          discountOrChargeSubtotals.push(taxSubtotal);
        });
      }
    });
    return discountOrChargeSubtotals;
  }

  /**
   * Builds taxTotal grouped by line VAT
   */
  private static buildTaxSummary(
    data: P2pData,
    lineValues: CalculatedLineValues[],
    currencyID: string
  ) {
    const groupedValuesByScheme = groupBy(
      lineValues,
      (values: CalculatedLineValues) => {
        const taxExemptionReasonCode: string =
          values.taxCategory?.taxExemptionReasonCode?.value || 'N/A';
        return `${values.taxCategory?.taxScheme?.id?.value}_${taxExemptionReasonCode}`;
      }
    );

    const taxSubtotals: TaxItem[] = [];

    const sortedSchemeKeys = Object.keys(groupedValuesByScheme);

    sortedSchemeKeys.forEach((tax) => {
      if (
        (groupedValuesByScheme[tax][0] as CalculatedLineValues)
          ?.vatPercentage !== undefined &&
        (groupedValuesByScheme[tax][0] as CalculatedLineValues)
          ?.vatPercentage !== null &&
        (groupedValuesByScheme[tax][0] as any)?.vatPercentage !== ''
      ) {
        const groupedSchemeByPercent = groupBy(
          groupedValuesByScheme[tax],
          (values: CalculatedLineValues) => values.vatPercentage
        );

        const sortedPercentKeys = Object.keys(groupedSchemeByPercent)
          .map((key) => +key)
          .filter((key) => !isNaN(key))
          .sort((a, b) => a - b);
        sortedPercentKeys.forEach((p) => {
          const { totalVat, totalWithoutVat, taxCategory, percent } =
            groupedSchemeByPercent[p].reduce(
              (acc: any, values) => {
                return {
                  totalVat: TaxAndPriceUtils.limitDecimals(
                    acc.totalVat + values.totalVat
                  ),
                  totalWithoutVat: TaxAndPriceUtils.limitDecimals(
                    acc.totalWithoutVat + round(values.totalWithoutVat)
                  ),
                  taxCategory: values.taxCategory,
                  percent: values.vatPercentage,
                };
              },
              {
                totalVat: 0,
                totalWithoutVat: 0,
                taxCategory: undefined,
                percent: 0,
              }
            );

          const taxSubtotal = TaxAndPriceUtils.buildTaxSubtotal(
            percent,
            totalVat,
            totalWithoutVat,
            currencyID,
            taxCategory
          );

          taxSubtotals.push(taxSubtotal);
        });
      }
    });

    // Do the same kind of job collecting the document level Allowance and Charge tax categories
    // and merge with the lines one.

    if (DataHelpers.getDocumentTypeCode(data) === DocumentTypeCode.INVOIC) {
      // Collects the Document Level Allowance Charge if any,
      // Computes then per VAT rates ...
      // Working with DRAFT data which will be always present
      const allowanceChargeSubtotals: TaxItem[] = [];
      const documentLevelDiscounts: any[] = get(
        data,
        FieldTypes.DOC_LEVEL_DISCOUNTS
      );
      const documentLevelCharges: any[] = get(
        data,
        FieldTypes.DOC_LEVEL_CHARGES
      );
      if (documentLevelDiscounts?.length > 0) {
        const documentDiscountSubtotals =
          FormDataHelpers.buildTaxSubtotalDocumentDiscountOrCharge(
            documentLevelDiscounts,
            '95',
            currencyID,
            true
          );
        documentDiscountSubtotals.forEach((d) => {
          allowanceChargeSubtotals.push(d);
        });
      }

      if (documentLevelCharges?.length > 0) {
        const documentChargeSubtotals =
          FormDataHelpers.buildTaxSubtotalDocumentDiscountOrCharge(
            documentLevelCharges,
            'ZZZ',
            currencyID,
            false
          );
        documentChargeSubtotals.forEach((c) => {
          allowanceChargeSubtotals.push(c);
        });
      }

      const taxTotal: TaxTotalItem | undefined = TaxAndPriceUtils.buildTaxTotal(
        taxSubtotals,
        currencyID
      );

      // Merging per category / percent
      if (allowanceChargeSubtotals.length > 0) {
        allowanceChargeSubtotals.forEach((e) => {
          // same pair category / percent if any
          const lineSubTotalCategory = taxTotal?.taxSubtotal?.find(
            (l) =>
              l.taxCategory.taxScheme.name.value ===
                e.taxCategory.taxScheme.name.value &&
              l.percent?.value === e.taxCategory.percent?.value
          );
          if (lineSubTotalCategory !== undefined) {
            // merge
            if (
              lineSubTotalCategory?.taxAmount?.value !== undefined &&
              e.taxAmount.value !== undefined &&
              lineSubTotalCategory?.taxableAmount?.value !== undefined &&
              e.taxableAmount?.value !== undefined
            ) {
              // Tax amount
              const newTaxAmount = round(
                lineSubTotalCategory?.taxAmount?.value + e.taxAmount.value
              );
              set(lineSubTotalCategory, 'taxAmount.value', newTaxAmount);
              // Taxable Amount
              const newTaxableAmount = round(
                lineSubTotalCategory?.taxableAmount?.value +
                  e.taxableAmount.value
              );
              set(
                lineSubTotalCategory,
                'taxableAmount.value',
                newTaxableAmount
              );
            }
          } else {
            // add
            taxTotal?.taxSubtotal?.push(e);
          }
        });
      }
      return taxTotal;
    } else {
      return TaxAndPriceUtils.buildTaxTotalUBL21(taxSubtotals, currencyID);
    }
  }

  private static calculateTotals(
    taxSummary: TaxTotalItem | TaxTotalDetails | undefined,
    data: P2pData,
    currencyID: string
  ) {
    let totalVat: number | undefined;
    let linesTotalWithoutVat: number | undefined;
    let linesTotalWithVat: number | undefined;

    // tax totals calculated only if VAT have been defined for ALL lines
    const canCalculateTaxTotals = data.lines.every(
      (line) => LineProcessor.getVATPercentage(line) !== undefined
    );

    if (canCalculateTaxTotals) {
      (taxSummary as TaxTotalItem)?.taxSubtotal?.forEach((item) => {
        // Document level allowance and charge amounts can be already computed
        if (item?.taxAmount?.value !== undefined) {
          if (totalVat !== undefined) {
            totalVat = TaxAndPriceUtils.limitDecimals(
              item.taxAmount.value + totalVat
            );
          } else {
            totalVat = item.taxAmount.value;
          }
        }
      });
      (taxSummary as TaxTotalDetails)?.TaxSubtotal?.forEach((item) => {
        if (item?.TaxAmount?.[0]?._ !== undefined) {
          if (totalVat !== undefined) {
            totalVat = TaxAndPriceUtils.limitDecimals(
              item.TaxAmount[0]._ + totalVat
            );
          } else {
            totalVat = item.TaxAmount[0]._;
          }
        }
      });
    }

    // total without VAT calculated from the lines directly not from tax summary
    data.lines.forEach((line) => {
      const lineTotalWithoutVat = LineProcessor.getTotalWithoutVat(line);
      if (lineTotalWithoutVat !== undefined) {
        if (linesTotalWithoutVat === undefined) {
          linesTotalWithoutVat = lineTotalWithoutVat;
        } else {
          linesTotalWithoutVat = TaxAndPriceUtils.limitDecimals(
            linesTotalWithoutVat + lineTotalWithoutVat
          );
        }
      }
    });

    // set totals in UBL
    if (DataHelpers.getDocumentTypeCode(data) === DocumentTypeCode.INVOIC) {
      TaxAndPriceUtils.setLegalMonetaryTotal(
        data,
        linesTotalWithoutVat,
        totalVat,
        currencyID
      );
    }
    if (DataHelpers.getDocumentTypeCode(data) === DocumentTypeCode.ORDERS) {
      if (linesTotalWithoutVat !== undefined) {
        linesTotalWithoutVat = round(linesTotalWithoutVat);
      }

      if (totalVat !== undefined && linesTotalWithoutVat !== undefined) {
        linesTotalWithVat = round(totalVat + linesTotalWithoutVat);
      }

      TaxAndPriceUtils.setAnticipatedMonetaryTotal(
        data.ublProperties,
        linesTotalWithVat,
        linesTotalWithoutVat,
        currencyID
      );
    }

    const aggregateTotalWithoutVat = get(
      data,
      'ublProperties.legalMonetaryTotal.taxExclusiveAmount.value'
    );

    const aggregateTotalWithVat = get(
      data,
      'ublProperties.legalMonetaryTotal.taxInclusiveAmount.value'
    );

    // set totals in metadata. /!\ once we go full UBL this won't be needed anymore
    MetadataTotalsUtils.setTotals(
      data,
      totalVat,
      aggregateTotalWithoutVat !== undefined
        ? aggregateTotalWithoutVat
        : linesTotalWithoutVat, // totalWithoutVat
      aggregateTotalWithVat !== undefined
        ? aggregateTotalWithVat
        : linesTotalWithVat // totalWithVat
    );
  }

  private static calculateTaxesAndPricesGlobally(
    data: P2pData,
    calculatedLineValues: CalculatedLineValues[],
    currencyID: string
  ) {
    const taxTotalSummary = FormDataHelpers.buildTaxSummary(
      data,
      calculatedLineValues,
      currencyID
    );
    if (DataHelpers.getDocumentTypeCode(data) === DocumentTypeCode.INVOIC) {
      delete data.ublProperties.taxTotal;
      if (taxTotalSummary) {
        data.ublProperties.taxTotal = [taxTotalSummary];
      }
    }

    FormDataHelpers.calculateTotals(taxTotalSummary, data, currencyID);
  }

  /**
   * Manages tax Categories, exemption reasons values according
   * to the customer country
   * @param data document data
   */
  private static manageTaxCategories(
    documentType: DocumentTypeCode | undefined,
    data: P2pData
  ) {
    if (
      (DataHelpers.regulatorExtraDetails(data) ===
        RegulatorExtraDetailsType.PEPPOL ||
        DataHelpers.regulatorExtraDetails(data) ===
          RegulatorExtraDetailsType.PEPPOL_INBOUND ||
        DataHelpers.regulatorExtraDetails(data) ===
          RegulatorExtraDetailsType.EFACTURA_OUTBOUND ||
        DataHelpers.regulatorExtraDetails(data) ===
          RegulatorExtraDetailsType.EFACTURA_INBOUND) &&
      documentType === DocumentTypeCode.INVOIC
    ) {
      const customizationID = get(data, Ubl.customizationID);

      data.lines.forEach((line) => {
        const taxSchemeName = get(line as InvoiceLine, Ubl.taxSchemeName);
        const taxSchemeID = get(line as InvoiceLine, Ubl.taxSchemeID);
        // taxSchemeName is the value associated to the field Tax Category on Invoice WebForms
        switch (taxSchemeName) {
          case Constants.TAX_SCHEME_ID_AE:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_AE
            );
            break;
          case Constants.TAX_SCHEME_ID_S:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_7
            );
            unset(line as InvoiceLine, Ubl.taxExemptionReasonCode);
            unset(line as InvoiceLine, Ubl.taxExemptionReason);
            break;
          case Constants.TAX_SCHEME_NAME_S_01:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_S
            );
            set(
              line as InvoiceLine,
              Ubl.taxSchemeName,
              Constants.TAX_SCHEME_NAME_S_01
            );
            unset(line as InvoiceLine, Ubl.taxExemptionReasonCode);
            unset(line as InvoiceLine, Ubl.taxExemptionReason);
            break;
          case Constants.TAX_SCHEME_NAME_S_02:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_S
            );
            set(
              line as InvoiceLine,
              Ubl.taxSchemeName,
              Constants.TAX_SCHEME_NAME_S_02
            );
            unset(line as InvoiceLine, Ubl.taxExemptionReasonCode);
            unset(line as InvoiceLine, Ubl.taxExemptionReason);
            break;
          case Constants.TAX_SCHEME_NAME_S_03:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_S
            );
            set(
              line as InvoiceLine,
              Ubl.taxSchemeName,
              Constants.TAX_SCHEME_NAME_S_03
            );
            unset(line as InvoiceLine, Ubl.taxExemptionReasonCode);
            unset(line as InvoiceLine, Ubl.taxExemptionReason);
            break;
          case Constants.TAX_SCHEME_NAME_45:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_AE
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReasonCode,
              Constants.TAX_EXEMPTION_CODE_BETE_45
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReason,
              'Reverse charge - Contractor'
            );
            break;
          case Constants.TAX_SCHEME_NAME_NA:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_E
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReasonCode,
              Constants.TAX_EXEMPTION_CODE_BETE_EX
            );
            set(line as InvoiceLine, Ubl.taxExemptionReason, 'Exempt');
            set(line as InvoiceLine, Ubl.vatPercentage, 0);
            break;
          case Constants.TAX_SCHEME_NAME_46_GO:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_K
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReasonCode,
              Constants.TAX_EXEMPTION_CODE_BETE_46_GO
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReason,
              'Intra-community supply - Goods'
            );
            set(line as InvoiceLine, Ubl.vatPercentage, 0);
            break;
          case Constants.TAX_SCHEME_NAME_46_TR:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_K
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReasonCode,
              Constants.TAX_EXEMPTION_CODE_BETE_46_TR
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReason,
              'Intra-community supply - Triangle a-B-c'
            );
            set(line as InvoiceLine, Ubl.vatPercentage, 0);
            break;
          case Constants.TAX_SCHEME_NAME_44:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_K
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReasonCode,
              Constants.TAX_EXEMPTION_CODE_BETE_44
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReason,
              'Intra-community supply - Services B2B'
            );
            set(line as InvoiceLine, Ubl.vatPercentage, 0);
            break;
          case Constants.TAX_SCHEME_NAME_47_EX:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_G
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReasonCode,
              Constants.TAX_EXEMPTION_CODE_BETE_47_EX
            );
            set(line as InvoiceLine, Ubl.taxExemptionReason, 'Export non E.U.');
            set(line as InvoiceLine, Ubl.vatPercentage, 0);
            break;
          case Constants.TAX_SCHEME_NAME_47_EI:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_G
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReasonCode,
              Constants.TAX_EXEMPTION_CODE_BETE_47_EI
            );
            set(line as InvoiceLine, Ubl.taxExemptionReason, 'Indirect export');
            set(line as InvoiceLine, Ubl.vatPercentage, 0);
            break;
          case Constants.TAX_SCHEME_NAME_47_EE:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_G
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReasonCode,
              Constants.TAX_EXEMPTION_CODE_BETE_47_EE
            );
            set(line as InvoiceLine, Ubl.taxExemptionReason, 'Rxport via E.U.');
            set(line as InvoiceLine, Ubl.vatPercentage, 0);
            break;
          case Constants.TAX_SCHEME_NAME_47_TO:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_K
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReasonCode,
              Constants.TAX_EXEMPTION_CODE_BETE_47_TO
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReason,
              'Intra-community supply - Manufacturing cost'
            );
            set(line as InvoiceLine, Ubl.vatPercentage, 0);
            break;

          case Constants.TAX_SCHEME_NAME_47_AS:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_K
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReasonCode,
              Constants.TAX_EXEMPTION_CODE_BETE_47_AS
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReason,
              'Intra-community supply - Assembly'
            );
            set(line as InvoiceLine, Ubl.vatPercentage, 0);
            break;
          case Constants.TAX_SCHEME_NAME_47_DI:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_K
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReasonCode,
              Constants.TAX_EXEMPTION_CODE_BETE_47_DI
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReason,
              'Intra-community supply - Distance'
            );
            set(line as InvoiceLine, Ubl.vatPercentage, 0);
            break;

          case Constants.TAX_SCHEME_NAME_47_SE:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_K
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReasonCode,
              Constants.TAX_EXEMPTION_CODE_BETE_47_SE
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReason,
              'Intra-community supply - Services'
            );
            set(line as InvoiceLine, Ubl.vatPercentage, 0);
            break;
          case Constants.TAX_SCHEME_NAME_SC:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_E
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReasonCode,
              Constants.TAX_EXEMPTION_CODE_BETE_SC
            );
            set(line as InvoiceLine, Ubl.taxExemptionReason, 'Small company');
            set(line as InvoiceLine, Ubl.vatPercentage, 0);
            break;
          case Constants.TAX_SCHEME_NAME_00_44:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_E
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReasonCode,
              Constants.TAX_EXEMPTION_CODE_BETE_00_44
            );
            set(line as InvoiceLine, Ubl.taxExemptionReason, '0% Clause 44');
            set(line as InvoiceLine, Ubl.vatPercentage, 0);
            break;
          case Constants.TAX_SCHEME_NAME_NS:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_O
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReasonCode,
              Constants.TAX_EXEMPTION_CODE_BETE_NS
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReason,
              'Not subject to VAT'
            );
            break;

          case Constants.TAX_SCHEME_NAME_OSS_S:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_S
            );
            unset(line as InvoiceLine, Ubl.taxExemptionReasonCode);
            unset(line as InvoiceLine, Ubl.taxExemptionReason);
            break;
          case Constants.TAX_SCHEME_NAME_OSS_I:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_S
            );
            unset(line as InvoiceLine, Ubl.taxExemptionReasonCode);
            unset(line as InvoiceLine, Ubl.taxExemptionReason);
            break;
          case Constants.TAX_SCHEME_NAME_OSS_G:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_S
            );
            unset(line as InvoiceLine, Ubl.taxExemptionReasonCode);
            unset(line as InvoiceLine, Ubl.taxExemptionReason);
            break;
          case Constants.TAX_SCHEME_NAME_FD:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_E
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReasonCode,
              Constants.TAX_EXEMPTION_CODE_BETE_FD
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReason,
              'Financial discount'
            );
            set(line as InvoiceLine, Ubl.vatPercentage, 0);
            break;
          case Constants.TAX_SCHEME_NAME_03_SE:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_S
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReasonCode,
              Constants.TAX_EXEMPTION_CODE_BETE_03_SE
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReason,
              'Standard exchange'
            );
            set(line as InvoiceLine, Ubl.vatPercentage, 0);
            break;
          case Constants.TAX_SCHEME_NAME_MA:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_S
            );
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReasonCode,
              Constants.TAX_EXEMPTION_CODE_BETE_MA
            );
            set(line as InvoiceLine, Ubl.taxExemptionReason, 'Margin');
            set(line as InvoiceLine, Ubl.vatPercentage, 0);
            break;

          case Constants.TAX_SCHEME_ID_O:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_O
            );
            break;
          case Constants.TAX_SCHEME_ID_K:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_K
            );
            break;
          case Constants.TAX_SCHEME_ID_G:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_G
            );
            break;
          case Constants.TAX_SCHEME_ID_E:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_E
            );
            break;
          case Constants.TAX_SCHEME_ID_Z:
            set(
              line as InvoiceLine,
              Ubl.taxSchemeID,
              Constants.TAX_SCHEME_ID_Z
            );

            if (customizationID === PeppolService.PEPPOL_CUSTOMIZATION_ID_BE) {
              set(
                line as InvoiceLine,
                Ubl.taxSchemeName,
                Constants.TAX_SCHEME_NAME_Z_00
              );
            }

            unset(line as InvoiceLine, Ubl.taxExemptionReasonCode);
            unset(line as InvoiceLine, Ubl.taxExemptionReason);
            break;
          default:
            set(line as InvoiceLine, Ubl.taxSchemeName, taxSchemeName);
            set(line as InvoiceLine, Ubl.taxSchemeID, taxSchemeID);
            unset(line as InvoiceLine, Ubl.taxExemptionReasonCode);
            unset(line as InvoiceLine, Ubl.taxExemptionReason);
        }

        // E-factura doesnt accept tax exemption reason. Only exemption reason code. While PEPPOL does.
        if (
          DataHelpers.regulatorExtraDetails(data) ===
            RegulatorExtraDetailsType.PEPPOL ||
          DataHelpers.regulatorExtraDetails(data) ===
            RegulatorExtraDetailsType.PEPPOL_INBOUND
        ) {
          if (
            get(line as InvoiceLine, Ubl.taxExemptionReasonCode) ===
            Constants.TAX_EXEMPTION_CODE_VATEX_EU_AE
          ) {
            set(line as InvoiceLine, Ubl.taxExemptionReason, 'Reverse charge');
          }
          if (
            get(line as InvoiceLine, Ubl.taxExemptionReasonCode) ===
            Constants.TAX_EXEMPTION_CODE_VATEX_EU_O
          ) {
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReason,
              'Not subject to VAT'
            );
          }
          if (
            get(line as InvoiceLine, Ubl.taxExemptionReasonCode) ===
            Constants.TAX_EXEMPTION_CODE_VATEX_EU_IC
          ) {
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReason,
              'Intra-Community supply'
            );
          }
          if (
            get(line as InvoiceLine, Ubl.taxExemptionReasonCode) &&
            !get(line as InvoiceLine, Ubl.taxExemptionReason)
          ) {
            set(
              line as InvoiceLine,
              Ubl.taxExemptionReason,
              get(line as InvoiceLine, Ubl.taxExemptionReasonCode)
            );
          }
        }
      });
    }
  }

  /**
   * Recalculates values for taxes and prices (to ensure validity).
   * Currently called after each value change.
   */
  public static recalculateTaxesAndPrices(data: P2pData) {
    const documentType = DataHelpers.getDocumentTypeCode(data);
    let currencyID: string;
    if (documentType === DocumentTypeCode.ORDERS) {
      currencyID =
        get(data, OrderService.MONO_CURRENCY_FIELD) ||
        DataHelpers.getCurrencyID(data);
      DataHelpers.setCurrencyID(data, currencyID);
    } else {
      currencyID = DataHelpers.getCurrencyID(data);
    }

    if (!currencyID) {
      throw new Error('currencyID defined at line level should not be empty');
    }

    // Manages TaxCategory name (BELGIUM specific on PEPPOL context)
    FormDataHelpers.manageTaxCategories(documentType, data);

    const calculatedLineValues = data.lines.map((line) =>
      LineProcessor.calculateLineValues(line)
    );

    data.lines.forEach((line, idx) => {
      LineProcessor.calculateTaxesAndPrices(
        line,
        calculatedLineValues[idx],
        currencyID
      );
    });

    FormDataHelpers.calculateTaxesAndPricesGlobally(
      data,
      calculatedLineValues,
      currencyID
    );
  }

  /**
   * Initializes a new /invoice line with mandatory data as currencyID and others
   */
  public static initNewInvoiceLine(currencyID: string) {
    const line: InvoiceLine = {
      id: {
        value: undefined,
      },
      lineExtensionAmount: {
        value: undefined,
        currencyID,
      },
      price: {
        priceAmount: {
          value: undefined,
          currencyID,
        },
      },
    };

    return line;
  }

  private static recalculateLineIds(lines: Line[]) {
    lines.forEach((line, index) => {
      line.id = {
        value: index + 1,
      };
    });
  }

  private static removeInvalidAndCustomElements(data: P2pData) {
    FormDataHelpers.removeInvalidAllowanceChargeNodes(data);
    FormDataHelpers.removeInvalidExchangeRateNodes(data);
    FormDataHelpers.removeInvalidAdditionalDocumentReference(data);
    FormDataHelpers.removeInvalidTaxRepresentativeNodes(data);
    FormDataHelpers.removeInvalidAddressNodes(
      data,
      'ublProperties.delivery[0].deliveryLocation.address'
    );
    FormDataHelpers.removeEmptyNode(data, 'ublProperties.delivery');
    FormDataHelpers.removeEmptyNode(
      data,
      'ublProperties.delivery[0].deliveryLocation.description'
    );
    FormDataHelpers.removeCustomFields(data);
  }

  /**
   *
   * Remove empty additionDocumentReference node
   */
  private static removeInvalidAdditionalDocumentReference(data: P2pData) {
    const additionalDocumentReferenceNode =
      data.ublProperties.additionalDocumentReference;
    if (additionalDocumentReferenceNode?.length === 0) {
      delete data.ublProperties.additionalDocumentReference;
    }
  }

  /**
   * Remove inconsistent TaxRepresentative nodes
   */
  private static removeInvalidTaxRepresentativeNodes(data: P2pData) {
    if (!data.ublProperties.taxRepresentativeParty) return;
    if (
      !data.ublProperties.taxRepresentativeParty?.partyTaxScheme?.companyID
        ?.value &&
      data.ublProperties.taxRepresentativeParty?.partyTaxScheme?.taxScheme?.id
        ?.value
    ) {
      delete data.ublProperties.taxRepresentativeParty.partyTaxScheme;
    }
    if (Object.keys(data.ublProperties.taxRepresentativeParty).length === 0) {
      delete data.ublProperties.taxRepresentativeParty;
    }
  }

  /**
   *
   * Remove unfilled ExchangeRate
   */
  private static removeInvalidExchangeRateNodes(data: P2pData) {
    const paymentExchangeRateNode = DataHelpers.getPaymentExchangeRate(data);
    if (paymentExchangeRateNode) {
      const sourceCurrencyCode =
        DataHelpers.getPaymentExchangeRateSourceCurrencyCode(data);
      const targetCurrencyCode =
        DataHelpers.getPaymentExchangeRateTargetCurrencyCode(data);
      const calculationRate =
        DataHelpers.getPaymentExchangeRateCalculationRate(data);
      if (!sourceCurrencyCode || !targetCurrencyCode || !calculationRate) {
        delete data.ublProperties.paymentExchangeRate;
      }
    }
  }

  /**
   *
   * Remove unfilled Address field
   */
  private static removeInvalidAddressNodes(data: P2pData, key: string) {
    const address: Address = get(data, key);

    if (address?.postalZone?.value === '') {
      unset(data, `${key}.postalZone`);
    }
  }

  /**
   * Remove unfilled AllowanceCharges
   */
  private static removeInvalidAllowanceChargeNodes(data: P2pData) {
    data.lines.forEach((line) => {
      if (line.allowanceCharge?.length) {
        if (line.allowanceCharge?.length !== 4) {
          throw new Error(
            'AllowanceCharge should contain 4 elements (discount/greenTax/SGR/SugarTax)'
          );
        }

        const newAllowanceCharge: AllowanceChargeItem[] = [];

        const discountEntry = DataHelpers.getDiscountNode(line);
        if (
          discountEntry &&
          discountEntry?.multiplierFactorNumeric?.value !== undefined &&
          discountEntry?.multiplierFactorNumeric?.value !== null &&
          discountEntry?.multiplierFactorNumeric?.value !== 0
        ) {
          newAllowanceCharge.push(discountEntry);
        }

        const greenTaxEntry = DataHelpers.getGreenTaxNode(line);
        if (
          greenTaxEntry &&
          greenTaxEntry?.perUnitAmount?.value !== undefined &&
          greenTaxEntry?.perUnitAmount?.value !== null &&
          greenTaxEntry?.perUnitAmount?.value !== 0 &&
          (greenTaxEntry?.perUnitAmount?.value as any) !== ''
        ) {
          newAllowanceCharge.push(greenTaxEntry);
        }

        const sgrTaxEntry = DataHelpers.getSGRNode(line);
        if (
          sgrTaxEntry &&
          sgrTaxEntry?.perUnitAmount?.value !== undefined &&
          sgrTaxEntry?.perUnitAmount?.value !== null &&
          sgrTaxEntry?.perUnitAmount?.value !== 0 &&
          (sgrTaxEntry?.perUnitAmount?.value as any) !== ''
        ) {
          newAllowanceCharge.push(sgrTaxEntry);
        }

        const sugarTaxEntry = DataHelpers.getSugarTaxNode(line);
        if (
          sugarTaxEntry &&
          sugarTaxEntry?.perUnitAmount?.value !== undefined &&
          sugarTaxEntry?.perUnitAmount?.value !== null &&
          sugarTaxEntry?.perUnitAmount?.value !== 0 &&
          (sugarTaxEntry?.perUnitAmount?.value as any) !== ''
        ) {
          newAllowanceCharge.push(sugarTaxEntry);
        }

        delete line.allowanceCharge;
        delete line[FieldTypes.Taxes];

        if (newAllowanceCharge.length) {
          line.allowanceCharge = newAllowanceCharge;
        }
      }
    });
  }

  private static setDocumentLevelAllowanceChargeNodes(data: P2pData) {
    // Retrieves the local messages directly as we can't use useTranslate() here.
    const messages: DomainMessages = getMessages(getLocaleInfo());
    // Document level allowanceCharge integrity
    const docDiscountNodes = get(data, FieldTypes.DOC_LEVEL_DISCOUNTS);
    const docChargeNodes = get(data, FieldTypes.DOC_LEVEL_CHARGES);
    if (docDiscountNodes !== undefined || docChargeNodes !== undefined) {
      const currencyID = DataHelpers.getCurrencyID(data);
      delete data.ublProperties.allowanceCharge;
      const newAllowanceCharge: object[] = [];

      // Discounts
      if (
        docDiscountNodes?.some(
          (d) => d.amount?.value !== undefined && d.amount?.value > 0
        )
      ) {
        docDiscountNodes.forEach((d) => {
          if (d.amount?.value !== undefined && d.amount?.value > 0) {
            set(d, 'taxCategory.taxScheme.id.value', 'VAT');
            set(d, 'amount.currencyID', currencyID);
            set(
              d,
              'allowanceChargeReason.value',
              messages.dxMessages.invoices.discountReasonCode[
                d.discount_reason_code?.value
              ] || messages.dxMessages.invoices.discountReasonCode['95']
            );
            set(
              d,
              'allowanceChargeReasonCode.value',
              d.discount_reason_code?.value || '95'
            );
            set(d, 'chargeIndicator.value', false);
            delete d.discount_reason_code; // value used by the templates in order to discriminate the select input options
            newAllowanceCharge.push(d);
          }
        });
      }

      // Charges
      if (
        docChargeNodes?.some(
          (c) => c.amount?.value !== undefined && c.amount?.value > 0
        )
      ) {
        docChargeNodes.forEach((c) => {
          if (c.amount?.value !== undefined && c.amount?.value > 0) {
            set(c, 'taxCategory.taxScheme.id.value', 'VAT');
            set(c, 'amount.currencyID', currencyID);
            set(
              c,
              'allowanceChargeReason.value',
              messages.dxMessages.invoices.chargeReasonCode[
                c.charge_reason_code?.value
              ] || messages.dxMessages.invoices.chargeReasonCode['ZZZ']
            );
            set(
              c,
              'allowanceChargeReasonCode.value',
              c.charge_reason_code?.value || 'ZZZ'
            );
            set(c, 'chargeIndicator.value', true);
            delete c.charge_reason_code; // value used by the templates in order to discriminate the select input options
            newAllowanceCharge.push(c);
          }
        });
      }

      if (newAllowanceCharge.length > 0) {
        set(data, 'ublProperties.allowanceCharge', newAllowanceCharge);
      }
    }
  }

  /**
   * Removes customs info.
   */
  private static removeCustomFields(data: P2pData) {
    delete data.customFields;
    delete data.waste;
    data.lines?.forEach((line) => {
      delete line.customFields;
      delete line.waste;
    });
  }

  /**
   * Changes data to add/transform it just before the save
   */
  public static prepareP2pDataForSave(formData: P2pData) {
    const data = cloneDeep(formData);

    // lines
    FormDataHelpers.recalculateLineIds(data.lines);
    set(data, Ubl.lineCountNumeric, data.lines.length);

    // other
    FormDataHelpers.setDocumentLevelAllowanceChargeNodes(data);
    FormDataHelpers.removeInvalidAndCustomElements(data);
    FormDataHelpers.formatDatesForAlfresco(data);
    if (data.ublProperties.paymentTerms) {
      set(data, Ubl.paymentTermsQualifier, 1); // 1 - Basic payments terms - stated in contract or relative date / 1 (default value)
      set(data, Ubl.paymentTermsStartEvent, 5); // 5 - invoice data / 5 (default value)
      set(data, Ubl.paymentTermsReferenceEventCode, 3); // 3 - After reference / 3 (default value)
      if (!get(data, Ubl.paymentTermsSettlementPeriodCode)) {
        set(data, Ubl.paymentTermsSettlementPeriodCode, 'D');
      }
    }

    if (
      data?.ublProperties?.accountingSupplierParty?.party?.endpointID?.value
    ) {
      set(
        data,
        Ubl.accountingSupplierGLN,
        data.ublProperties.accountingSupplierParty.party.endpointID.value
      );
    }

    if (
      data.ublProperties.taxRepresentativeParty?.partyTaxScheme?.companyID
        ?.value
    ) {
      set(
        data,
        'ublProperties.taxRepresentativeParty.partyTaxScheme.taxScheme.id.value',
        'VAT'
      );
    }

    if (
      data.ublProperties.paymentMeans?.[0]?.cardAccount?.primaryAccountNumberID
        ?.value
    ) {
      set(
        data,
        'ublProperties.paymentMeans[0].cardAccount.networkID.value',
        'NA'
      );
    }

    // Checks customizationID and sets it only for P2P needs.
    // Could be set for PEPPOL or EFACTURA needs (such as expected by standard)
    // If not, gives priority to webform data.
    const customizationID = DataHelpers.getCustomizationID(data);
    if (customizationID !== undefined) {
      if (
        customizationID !== undefined &&
        customizationID?.indexOf(
          PeppolService.PEPPOL_CUSTOMIZATION_ID_PREFIX
        ) === -1
      ) {
        //
        DataHelpers.setCustomizationID(data, DataHelpers.getInvoiceType(data));
      }
    } else {
      DataHelpers.setCustomizationID(data, DataHelpers.getInvoiceType(data));
    }

    // metadata
    // Invoice type Code
    if (DataHelpers.getInvoiceTypeCode(data)) {
      set(
        data.properties,
        Metadata.documentSubTypeCode,
        DataHelpers.getInvoiceTypeCode(data)
      );
    }
    // delivery location name
    set(
      data.properties,
      Metadata.locationName,
      get(data, Ubl.deliveryLocationName)
    );

    // delivery location address
    if (!get(data.properties, Metadata.locationAddress)) {
      set(
        data.properties,
        Metadata.locationAddress,
        buildAddressAsAString(getDeliveryLocationAddress(data))
      );
    }

    // Remove custom fields
    unset(data.ublProperties, InvoiceService.CUSTOM_CATEGORY);

    const result: AlfrescoContent = {
      properties: data.properties,
      ublContent: {
        ublProperties: data.ublProperties,
        lines: data.lines,
      },
    };

    return result;
  }

  /**
   * Changes data to add/transform it just before the save
   */
  public static convertToAlfrescoContent(formData: P2pData) {
    const data = cloneDeep(formData);

    const result: AlfrescoContent = {
      properties: data.properties,
      ublContent: {
        ublProperties: data.ublProperties,
        lines: data.lines,
      },
    };

    return result;
  }

  /**
   *  In case of MonoVat, reset monoVat if TaxSummary has more than one lines
   */
  public static manageMonoVat(data: P2pData) {
    if (DataHelpers.getDocumentTypeCode(data) === DocumentTypeCode.ORDERS) {
      const percentArray = data.lines.map(
        (l) => l.LineItem[0].TaxTotal?.[0]?.TaxSubtotal?.[0]?.Percent[0]?._
      );
      const samePercent = new Set(percentArray).size === 1;
      if (!samePercent) {
        set(data, OrderService.MONO_VAT_FIELD, '');
      } else {
        // Can update MonoVAT field
        set(
          data,
          OrderService.MONO_VAT_FIELD,
          data.lines[0].LineItem[0].TaxTotal?.[0]?.TaxSubtotal?.[0]?.Percent[0]
            ?._
        );
      }
    } else {
      const percentArray = data.lines
        .filter((l) => !LineProcessor.isLineSGR(l))
        .map((l) => l.taxTotal?.[0]?.taxSubtotal?.[0]?.percent?.value);
      const vatUndefined = percentArray.includes(undefined);
      const setPercentArray = new Set(percentArray);
      const samePercent = setPercentArray.size === 1;
      if (!samePercent || vatUndefined) {
        set(data, FieldTypes.MONO_VAT, '');
      } else {
        // Can update MonoVAT field
        set(data, FieldTypes.MONO_VAT, Array.from(setPercentArray)[0]);
      }
    }
  }

  /**
   *  In case of Mono Tax Category, reset Tax Category if TaxSummary has more than one lines
   */
  public static manageMonoTaxCategory(data: P2pData) {
    if (DataHelpers.getDocumentTypeCode(data) === DocumentTypeCode.ORDERS) {
      const taxSchemeArray = data.lines.map(
        (l) =>
          l.LineItem[0].TaxTotal?.[0]?.TaxSubtotal?.[0]?.TaxCategory?.[0]
            ?.TaxScheme?.[0]?.Name?.[0]?._
      );
      const sameScheme = new Set(taxSchemeArray).size === 1;
      if (!sameScheme) {
        set(data, OrderService.MONO_TAX_CATEGORY_FIELD, '');
      } else {
        // Can update MonoTaxCategory field
        set(
          data,
          OrderService.MONO_TAX_CATEGORY_FIELD,
          data.lines[0].LineItem[0].TaxTotal?.[0]?.TaxSubtotal?.[0]
            ?.TaxCategory[0]?.TaxScheme[0]?.Name?.[0]?._
        );
      }
    } else {
      const taxSchemeArray = data.lines
        .filter((l) => !LineProcessor.isLineSGR(l))
        .map(
          (l) =>
            l.taxTotal?.[0]?.taxSubtotal?.[0]?.taxCategory?.taxScheme?.name
              ?.value
        );

      const taxSchemeUndefined = taxSchemeArray.includes(undefined);
      const setTaxSchemeArray = new Set(taxSchemeArray);
      const sameScheme = setTaxSchemeArray.size === 1;
      if (!sameScheme || taxSchemeUndefined) {
        set(data, FieldTypes.TAX_CATEGORY, '');
      } else {
        // Can update Tax Category field
        set(data, FieldTypes.TAX_CATEGORY, Array.from(setTaxSchemeArray)[0]);
      }
    }
  }

  /**
   *
   * Remove unfilled node field
   */
  private static removeEmptyNode(data: P2pData, key: string) {
    const node: any = get(data, key);

    if (node !== undefined) {
      if (
        JSON.stringify(node).indexOf('value') < 0 &&
        JSON.stringify(node).indexOf('_') < 0
      ) {
        unset(data, key);
      }
    }
  }
}
