import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import get from 'lodash/get';
import { useRef, useState } from 'react';
import {
  ExpandRowButton,
  Loading,
  ShowContext,
  ShowView,
  SimpleShowLayout,
  useTranslate,
  useVersion,
} from 'react-admin';
import { useRouteMatch } from 'react-router-dom';
import { Metadata, RegulatorExtraDetailsType } from '../../constants';
import { DxTheme } from '../../types';
import {
  documentTypeCodeToResource,
  getMessagePrefix,
  useGAPageViews,
} from '../../utils';
import ToolbarBackButton from '../buttons/ToolbarBackButton';
import { AttachmentsSection } from './AttachmentsSection';
import RelatedDocumentsShowController from './RelatedDocumentsShowController';
import { ListType, Rows } from './Rows';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    root: {
      '& > div': {
        boxShadow: 'unset',
      },
      marginBottom: theme.spacing(2),
    },
    view: {
      padding: theme.spacing(2),
    },
    header: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(1),
    },
    paper: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    backButton: {
      marginTop: '4px',
      minHeight: 35,
    },
    expandIcon: {
      padding: theme.spacing(1),
      transform: 'rotate(-90deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expanded: {
      transform: 'rotate(0deg)',
    },
  }),
  { name: 'RelatedDocuments' }
);

/**
 * Removes unexpected prop(s) from the DOM in order to not inject it(them) to the children
 */
const SanitizePropsComponent = (props) => {
  const { basePath, children, allDocs, ...rest } = props;
  return <div {...rest}>{children}</div>;
};

export interface RelatedDocumentsPropsModel {
  columnsProcessDetails: any;
  commonColumns: any;
  showMetadataButton: any;
  downloadButton: any;
  previewButton: any;
  resource?: string;
}

const RelatedDocuments = ({
  columnsProcessDetails,
  commonColumns,
  showMetadataButton,
  downloadButton,
  previewButton,
  resource: propResource,
}: RelatedDocumentsPropsModel) => {
  const translate = useTranslate();
  const classes = useStyles();
  const ref = useRef();
  const match = useRouteMatch();
  const version = useVersion();
  const [linkedExpanded, setLinkedExpanded] = useState(false);
  const [relatedExpanded, setRelatedExpanded] = useState(true);
  const [messagesExpanded, setMessagesExpanded] = useState(false);
  const [attachmentsExpanded, setAttachmentsExpanded] = useState(false);

  useGAPageViews();

  //const params = match.params[0].split('/');
  const resource = propResource || match.params['resource'];
  const id = match.params['id'];
  const basePath = `/${resource}`;

  // Defining the columns to be displayed
  const linkedColumns = [...commonColumns, ...columnsProcessDetails].filter(
    (column) => column.displayInLinkedDocuments === true
  );

  Object.values(linkedColumns).forEach((value) => {
    if (value.id === 'edm:documentId') {
      value.label = 'dxMessages.headers.documentId';
    }
  });

  const standardColumns = commonColumns.filter(
    (column) => column.displayInRelatedDocuments === true
  );

  const handleToggleRelatedExpand = async () => {
    setRelatedExpanded(!relatedExpanded);
  };

  const handleToggleLinkedExpand = async (currentSortLinked, setSort) => {
    setLinkedExpanded(!linkedExpanded);
    if (!linkedExpanded) {
      setSort(currentSortLinked ? currentSortLinked.field : 'id', 'linked');
    }
  };

  const handleToggleMessagesExpand = async () => {
    setMessagesExpanded(!messagesExpanded);
  };

  const handleToggleAttachmentsExpand = async () => {
    setAttachmentsExpanded(!attachmentsExpanded);
  };

  return (
    <div>
      <RelatedDocumentsShowController
        resource={resource}
        id={id}
        version={version}
        linkedDocuments={false} // Don't compute linked document for the initial call
      >
        {(controllerProps) => {
          const {
            setSort,
            currentSortMessage,
            currentSortRelated,
            currentSortAttach,
            currentSortLinked,
            isLoading,
            record,
          } = controllerProps;

          if (isLoading) {
            return <Loading />;
          } else {
            const documentTypeCode = get(
              record.parentDocument.properties,
              Metadata.documentTypeCode
            );
            const specificResource = documentTypeCodeToResource(
              documentTypeCode,
              get(
                record.parentDocument?.properties,
                Metadata.processDocumentFormatType
              )
            );
            const documentId = get(
              record.parentDocument.properties,
              Metadata.documentId
            );

            const dxuid = get(record.parentDocument.properties, Metadata.dxuid);

            const processStatus = get(
              record.parentDocument.properties,
              Metadata.processStatus
            );

            const invoiceType = get(
              record.parentDocument.properties,
              Metadata.invoiceType
            );

            const recipientId = get(
              record.parentDocument.properties,
              Metadata.recipientId
            );

            const prefix = translate(
              `dxMessages.${getMessagePrefix(specificResource)}.show_title`
            );

            const isEfactura: boolean =
              get(
                record.parentDocument.properties,
                Metadata.regulatorExtraDetails
              ) === RegulatorExtraDetailsType.EFACTURA_OUTBOUND ||
              get(
                record.parentDocument.properties,
                Metadata.regulatorExtraDetails
              ) === RegulatorExtraDetailsType.EFACTURA_INBOUND
                ? true
                : false;

            const title = documentId ? prefix + documentId : <></>;

            const sanitizeControllerProps = ({
              setSort,
              currentSortMessage,
              currentSortRelated,
              currentSortAttach,
              currentSortLinked,
              isLoading,
              ...rest
            }: any) => rest;

            return (
              <ShowContext.Provider
                value={sanitizeControllerProps(controllerProps)}
              >
                <ShowView
                  title={title}
                  classes={{ root: classes.root }}
                  loading={false}
                  loaded={true}
                  version={version}
                >
                  <SimpleShowLayout className={classes.view}>
                    <SanitizePropsComponent>
                      {record.relatedDocuments &&
                        record.relatedDocuments?.length > 0 && (
                          <Grid container direction='column'>
                            <Grid item>
                              <Typography
                                className={classes.header}
                                variant='h6'
                              >
                                {translate('dxMessages.headers.documents', {
                                  _: 'Related Documents',
                                })}
                                <ExpandRowButton
                                  className={classNames(
                                    classes.expandIcon,
                                    {
                                      [classes.expanded]: relatedExpanded,
                                    },
                                    'related-document'
                                  )}
                                  classes={{
                                    expandIcon: classes.expandIcon,
                                    expanded: classes.expanded,
                                  }}
                                  expanded={relatedExpanded}
                                  onClick={handleToggleRelatedExpand}
                                  innerRef={ref}
                                  title={translate(
                                    relatedExpanded
                                      ? 'ra.action.close'
                                      : 'ra.action.expand'
                                  )}
                                />
                              </Typography>
                              {relatedExpanded && (
                                <Paper
                                  square
                                  className={classNames(
                                    classes.paper,
                                    'related-document'
                                  )}
                                >
                                  <Rows
                                    commonColumns={standardColumns}
                                    basePath={basePath}
                                    documents={record.relatedDocuments}
                                    resource={resource}
                                    showMetadataButton={showMetadataButton}
                                    downloadButton={downloadButton}
                                    previewButton={previewButton}
                                    setSort={(event) =>
                                      setSort(event, 'related')
                                    }
                                    currentSort={currentSortRelated}
                                    specificResource={specificResource}
                                    listType={ListType.RELATED}
                                  />
                                </Paper>
                              )}
                            </Grid>
                          </Grid>
                        )}
                      {
                        <Grid container direction='column'>
                          <Grid item>
                            <Typography className={classes.header} variant='h6'>
                              {translate('dxMessages.headers.linkedDocuments', {
                                _: 'Linked Documents',
                              })}
                              <ExpandRowButton
                                className={classNames(
                                  classes.expandIcon,
                                  {
                                    [classes.expanded]: linkedExpanded,
                                  },
                                  'linked-document'
                                )}
                                classes={{
                                  expandIcon: classes.expandIcon,
                                  expanded: classes.expanded,
                                }}
                                expanded={linkedExpanded}
                                onClick={() =>
                                  handleToggleLinkedExpand(
                                    currentSortLinked,
                                    setSort
                                  )
                                }
                                innerRef={ref}
                                title={translate(
                                  linkedExpanded
                                    ? 'ra.action.close'
                                    : 'ra.action.expand'
                                )}
                              />
                            </Typography>

                            {linkedExpanded &&
                              record.linkedDocuments?.length > 0 && (
                                <Paper
                                  square
                                  className={classNames(
                                    classes.paper,
                                    'linked-document'
                                  )}
                                >
                                  <Rows
                                    commonColumns={linkedColumns}
                                    basePath={basePath}
                                    documents={record.linkedDocuments}
                                    resource={resource}
                                    showMetadataButton={showMetadataButton}
                                    downloadButton={downloadButton}
                                    previewButton={previewButton}
                                    setSort={(event) =>
                                      setSort(event, 'linked')
                                    }
                                    currentSort={currentSortLinked}
                                    specificResource={specificResource}
                                    listType={ListType.LINKED}
                                  />
                                </Paper>
                              )}
                            {((linkedExpanded &&
                              record.linkedDocuments?.length === 0) ||
                              (linkedExpanded &&
                                record.linkedDocuments === null)) && (
                              <Paper
                                square
                                className={classNames(
                                  classes.paper,
                                  'linked-document'
                                )}
                              >
                                <Typography>
                                  {translate(
                                    'dxMessages.linkedDocuments.no_docs',
                                    {
                                      _: 'No documents',
                                    }
                                  )}
                                </Typography>
                              </Paper>
                            )}
                          </Grid>
                        </Grid>
                      }

                      <AttachmentsSection
                        standardColumns={standardColumns}
                        basePath={basePath}
                        resource={resource}
                        specificResource={specificResource}
                        showMetadataButton={showMetadataButton}
                        downloadButton={downloadButton}
                        previewButton={previewButton}
                        currentSortAttach={currentSortAttach}
                        setSort={setSort}
                        id={id}
                        attachments={record.attachments}
                        dxuid={dxuid}
                        recipientId={recipientId}
                        processStatus={processStatus}
                        invoiceType={invoiceType}
                        expanded={attachmentsExpanded}
                        toggleExpanded={handleToggleAttachmentsExpand}
                        readOnly={isEfactura}
                      />
                      {
                        <Grid container direction='column'>
                          <Grid item>
                            <Typography className={classes.header} variant='h6'>
                              {translate('dxMessages.feedbackMessages.title', {
                                _: 'Messages',
                              })}

                              <ExpandRowButton
                                className={classNames(
                                  classes.expandIcon,
                                  {
                                    [classes.expanded]: messagesExpanded,
                                  },
                                  'feedback-messages'
                                )}
                                classes={{
                                  expandIcon: classes.expandIcon,
                                  expanded: classes.expanded,
                                }}
                                expanded={messagesExpanded}
                                onClick={handleToggleMessagesExpand}
                                innerRef={ref}
                                title={translate(
                                  messagesExpanded
                                    ? 'ra.action.close'
                                    : 'ra.action.expand'
                                )}
                              />
                            </Typography>

                            {messagesExpanded && record.messages?.length > 0 && (
                              <Paper
                                square
                                className={classNames(
                                  classes.paper,
                                  'feedback-messages'
                                )}
                              >
                                <Rows
                                  commonColumns={standardColumns}
                                  basePath={basePath}
                                  documents={record.messages}
                                  resource={resource}
                                  showMetadataButton={showMetadataButton}
                                  downloadButton={downloadButton}
                                  previewButton={previewButton}
                                  setSort={(event) => setSort(event, 'message')}
                                  currentSort={currentSortMessage}
                                  specificResource={specificResource}
                                  listType={ListType.MESSAGE}
                                />
                              </Paper>
                            )}
                            {messagesExpanded && record.messages?.length === 0 && (
                              <Paper
                                square
                                className={classNames(
                                  classes.paper,
                                  'feedback-messages'
                                )}
                              >
                                <Typography>
                                  {translate(
                                    'dxMessages.feedbackMessages.no_docs',
                                    {
                                      _: 'No messages',
                                    }
                                  )}
                                </Typography>
                              </Paper>
                            )}
                          </Grid>
                        </Grid>
                      }
                    </SanitizePropsComponent>
                  </SimpleShowLayout>
                </ShowView>
              </ShowContext.Provider>
            );
          }
        }}
      </RelatedDocumentsShowController>
      <ToolbarBackButton />
    </div>
  );
};

export default RelatedDocuments;
