import { useEffect, useState } from 'react';

export enum SetupUsage {
  DEMO = 'DEMO',
  PRODUCTION = 'PRODUCTION',
  DEV = 'DEV',
  UAT = 'UAT',
}

const useSetupUsage = (): SetupUsage | undefined => {
  const [setupUsage, setSetupUsage] = useState<SetupUsage | undefined>(
    undefined
  );
  const rootUrl = `${window.location.origin}`;

  useEffect(() => {
    const reservedIPs = {
      [SetupUsage.PRODUCTION]: [
        'https://cs.doc-process.com',
        'https://172.16.9.220', // public staging
        'https://172.16.9.219', // public prod
      ],
      [SetupUsage.DEMO]: [
        'https://13.36.5.178',
        'https://demo.cs.doc-process.com',
      ],
      [SetupUsage.UAT]: [
        'https://172.31.10.219', // BA
        'https://172.31.3.94', // UAT1
        'https://172.31.13.142', // UAT2
        'https://172.31.14.18', // UAT3
        'https://test-uat.doc-process.com', // HA-Proxy UATs
      ],
      [SetupUsage.DEV]: ['https://localhost', 'https://127.0.0.1'],
    };
    const getUsage = () => {
      if (
        reservedIPs[SetupUsage.DEMO].some((ip) => rootUrl.indexOf(ip) !== -1)
      ) {
        setSetupUsage(SetupUsage.DEMO);
      } else if (
        reservedIPs[SetupUsage.DEV].some((ip) => rootUrl.indexOf(ip) !== -1)
      ) {
        setSetupUsage(SetupUsage.DEV);
      } else if (
        reservedIPs[SetupUsage.UAT].some((ip) => rootUrl.indexOf(ip) !== -1)
      ) {
        setSetupUsage(SetupUsage.UAT);
      } else if (
        reservedIPs[SetupUsage.PRODUCTION].some(
          (ip) => rootUrl.indexOf(ip) !== -1
        )
      ) {
        setSetupUsage(SetupUsage.PRODUCTION);
      }
    };
    if (rootUrl) {
      getUsage();
    }
  }, [rootUrl]);

  return setupUsage;
};

export default useSetupUsage;
