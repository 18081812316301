export default {
  resources: {
    login: {
      email: 'Adresse électronique',
      password: 'Mot de passe',
      company: 'Société',
      or: 'OU',
      onlyWithRegisteredAlternateLogins:
        'Seulement si vous êtes autorisé à utiliser des social logins.',
      loginWith: 'Connexion avec %{providerId}',
      emailCheck: 'Votre adresse mail est invalide!',
      errors: {
        badCredentials: "L'authentification a échoué!",
        locked:
          'Compte verrouillé après %{count} tentatives infructueuses. Le verrou sera levé %{date}.',
        unexpected:
          'Nous sommes désolé de cette erreur imprévue. Veuillez réessayer plus tard ou contacter le support de Doc Process.',
      },
      notifications: {
        selectCompany:
          'Veuillez sélectionner la société avec laquelle vous voulez vous connecter.',
      },
    },
    task: {
      menu: 'Mes tâches',
      list: {
        title: 'Mes tâches',
      },
      'cora-fgx-pdf': {
        Cora_FGX_Validation_User_Task: {
          edit: {
            title: 'Approuver ou rejeter la facture',
            open: {
              button: 'Approuver ou Rejeter',
            },
            save: {
              button: 'Approuver',
            },
            cancel: {
              button: 'Rejeter',
            },
            update: {
              button: 'Enregistrer',
            },
            cancelUpdate: {
              button: 'Annuler',
            },
            successMessage: 'Terminé',
          },
        },
      },
    },
    administration: {
      menu: 'Administration',
    },
    departments: {
      menu: 'Rayons',
    },
    roles: {
      menu: 'Rôles',
    },
    establishments: {
      menu: 'Etablissements',
    },
    validationMatrix: {
      menu: 'Matrice de Validation',
    },
    user_and_company: {
      menu: 'Utilisateurs & Société',
    },
    user_and_company_v3: {
      menu: 'Utilisateurs & Société (V3)',
    },
    companies: {
      menu: 'Sociétés',
      list: {
        title: 'Sociétés',
        filters: {
          name: 'Nom',
        },
        headers: {
          name: 'Nom',
          fiscalCode: 'Code fiscal',
          address: 'Adresse',
        },
        toptoolbar: {
          create: 'créer une société',
        },
      },
      edit: {
        title: 'Société %{companyName}',
        deleteConfirmTitle: 'Supprimer %{username}',
        tabs: {
          company: {
            title: 'Sociétés',
            sections: {
              description: {
                subtitle:
                  "Les informations ci-dessous sont en lecture seule. En cas d'erreur, merci de contacter le support pour y remédier.",
                name: 'nom',
                fiscalCode: 'code fiscal',
                address: 'adresse',
                cmsRootDir: 'répertoire de la société',
                cmsRootDirHelper:
                  'Le répertoire dans le Content Management System où sont stockés tous les documents de la société (factures, ...)',
              },
              userAuthenticationTypes: {
                subtitle: 'Types de login supportés',
                subtitleHelper:
                  'Les types de login (login Doc Process basé sur un mot de passe, social login, login interne, ...) ' +
                  "acceptés par cette société pour que ses utilisateurs se connectent. Contactez le support si vous souhaitez en ajouter d'autres.",
              },
            },
          },
          subscriptions: {
            title: 'Fonctionnalités souscrites',
            subtitle_1:
              'Toutes les fonctionnalités de Doc Process (en lecture seule) auxquelles l\u0027entreprise s\u0027est abonnée.\u0020' +
              'Elles dépendent du contrat signé avec Doc Process. Contactez le\u0020',
            subtitle_2: 'support',
            subtitle_3: '\u0020si vous souhaitez modifier cette liste.',
            subtitle_4:
              'Une fois abonné, chaque utilisation de fonctionnalité peut être attribuée à un compte utilisateur via le menu Comptes',
            psp_message:
              'Lors de la sélection d\u0027une fonctionnalité, l\u0027entreprise aura accès à tous les rôles sous-jacents, ' +
              'moins ceux spécifiquement exclus.',
            feature: 'fonctionnalité',
            active: 'active',
            excludedRoleIds: 'rôles exclus',
          },
          delegations: {
            title: 'Délegations entre employés',
            subtitle:
              "Les délegations de droits d'un employé à un autre. Utilisez les " +
              'quand un employé est vacant pour une période donnée et que vous voulez ' +
              "qu'un de ses collégues le remplace durant son absence.",
            representee: 'representé',
            representeeHelper: "L'utilisateur qui délègue ses droits.",
            delegee: 'délégué',
            delegeeHelper: "L'utilisateur qui hérite des droits",
            periodTitle:
              'Cette délegations sera active (attention, dans votre fuseau horaire):',
            from: 'de',
            to: 'à',
            errors: {
              samePerson:
                'ligne %{line}: La personne representée et le délégué doivent être différents.',
              fromAfterTo:
                'ligne %{line}: période invalide (la date de départ doit étre antérieure à la date de fin).',
            },
          },
          history: {
            title: 'Historique des comptes',
            subtitle:
              'Tous les changements des comptes utilisateurs de la société.',
            accountOf: 'Compte de',
            changeType: 'Changement',
            when: 'En date de',
            by: 'Fait par',
          },
        },
        notifications: {
          created: 'La société a été créée',
          updated: 'La société a été mise à jour',
        },
        notificationsPeppol: {
          created: "L'élément a été créé",
          updated: "L'élément a été mise à jour",
        },
      },
    },
    company: {
      menu: 'Société',
    },
    people: {
      menu: 'Personnes',
      list: {
        title: 'Personnes',
        filters: {
          email: 'Adresse électronique',
        },
        headers: {
          email: 'Adresse électronique',
          firstname: 'Prénom',
          lastname: 'Nom',
        },
      },
      edit: {
        sections: {
          info: {
            email: 'Adresse électronique',
            emailHelper:
              "Cette adresse n'est jamais modifiable: vous devez supprimer ce compte " +
              'et le recréer avec la nouvelle addresse pour le faire.',
            phone: 'Téléphone',
            phoneHelper: 'Le numéro de téléphone est optionnel.',
            firstname: 'Prénom',
            lastname: 'Nom',
            language: 'langage',
            english: 'Anglais',
            romanian: 'Roumain',
            french: 'Français',
          },
          authentication: {
            title: 'Authentification',
            subtitle: 'Les logins pour se connecter aux comptes ci-dessous.',
            type: 'type',
            login: 'login',
            none: 'Aucun login configuré.',
          },
          accounts: {
            title: 'Comptes',
            subtitle: 'Les comptes appartenant à cette personne par société.',
            company: 'Société',
            enabled: 'Activé',
            nonLocked: 'Déverrouillé',
          },
        },
        notifications: {
          updated: 'Personne mise à jour',
        },
      },
    },
    address: {
      menu: 'Adresses',
      list: {
        title: 'Adresses',
        headers: {
          street: 'Rue',
          additionalStreet: 'Rue supplémentaire',
          buildingNumber: 'Numéro du bâtiment',
          postBox: 'Boîte aux lettres',
          city: 'Ville',
          postalCode: 'Code postal',
          financialAccount: 'IBAN',
          bank: 'Banque',
          gln: 'GLN',
          country: 'Code pays',
          flgBilling: 'Facturation adresse',
          flgShipping: 'Expédition adresse',
          flgLogistic: 'Logistique adresse',
        },
      },
      edit: {
        title: 'Adresses',
        sections: {
          street: 'Rue',
          additionalStreet: 'Rue supplémentaire',
          buildingNumber: 'Numéro du bâtiment',
          postBox: 'Boîte aux lettres',
          city: 'Ville',
          district: 'Comté',
          postalCode: 'Code postal',
          financialAccount: 'IBAN',
          bank: 'Banque',
          gln: 'GLN',
          country: 'Code pays',
          flgBilling: 'Facturation adresse',
          flgShipping: 'Expédition adresse',
          flgLogistic: 'Logistique adresse',
        },
        notifications: {
          created: "L'élément a été créée",
          updated: "L'élément a été mise à jour",
          flgError: "Veuillez sélectionner le type d'adresse",
        },
      },
    },
    supportedProducts: {
      create: {
        error: 'Ce produit a déjà été créé.',
      },
    },
    accounts: {
      menu: 'Comptes utilisateurs',
      list: {
        title: 'Comptes utilisateurs',
        filters: {
          company: 'Société',
          person: 'Personne',
          status: {
            title: 'Etat',
            disabled: 'Désactivé',
            locked: 'Verrouillé',
          },
          created: {
            title: 'Création',
            today: "Aujourd'hui",
            thisWeek: 'Cette semaine',
            lastWeek: 'La semaine dernière',
            thisMonth: 'Ce mois-ci',
            lastMonth: 'Le mois dernier',
            earlier: 'Avant le dernier mois',
          },
        },
        headers: {
          person: 'Personne',
          company: 'Société',
          enabled: 'Activé',
          nonLocked: 'Déverrouillé',
          created: 'Création',
        },
        toptoolbar: {
          create: 'crée un compte utilisateur',
        },
      },
      create: {
        title: 'Assistant de création de compte',
        notifications: {
          created: 'Le compte a été crée',
        },
        wizard: {
          back: 'Précédent',
          next: 'Suivant',
          steps: {
            companySelection: {
              title: 'Sélectionnez la société à laquelle appartient le compte',
              subtitle:
                'Le détail de chaque société se trouve dans le menu Entreprise',
              company: 'Société',
            },
            personSelection: {
              title: 'Définissez la personne à laquelle le compte est destiné',
              subtitle:
                'Vous pouvez sélectionner une personne existante OU en créer une nouvelle.',
              selectPerson: 'Sélectionnez une personne existante.',
              createPerson: 'Créez une nouvelle personne.',
              or: 'OU',
              person: 'personne',
              job: 'Poste',
              authentication: 'Authentification',
              allFieldsMandatory:
                'Veuillez remplir tous les champs obligatoires ci-dessous.',
              uniqueAmongCompanyEmails:
                'Doit être unique parmi toutes les adresses électroniques de la société',
              selectedLanguageOnLogin:
                'La langue sélectionnée lors de la connexion',
              phoneHelper:
                'Le numéro de téléphone est optionnel. Vous pouvez le laisser vide.',
            },
            usageRightsSelection: {
              title: "Attribuez les droits d'utilisation à ce compte",
              subtitle:
                "Les droits d'utilisation attribués à ce compte. " +
                "Vous ne pouvez attribuer que des droits pour les fonctionnalités auxquelles l'entreprise s'est abonnée. " +
                'Vous pouvez sauter cette étape et attribuez ces droits plus tard après la création du compte.',
              feature: 'fonctionnalité',
              roles: 'rôles',
              errors: {
                duplicateFeatures:
                  'Vous avez mis deux fois la même fonctionnalité. Veuillez regrouper tous les rôles dans une seule.',
              },
            },
            checkAndSave: {
              title:
                'Vérifiez toutes vos informations puis sauvegardez le compte',
            },
          },
        },
      },
      edit: {
        titleWithCompany: 'Compte de %{username} dans %{companyName}',
        titleWithoutCompany: 'Compte de %{username}',
        deleteConfirmTitle: 'Supprimer %{username}',
        tabs: {
          user: {
            title: 'Compte',
            created: 'Créé le %{date}',
            accountEnabled: 'Activé',
            accountDisabled: 'Désactivé',
            accountEnabledHelper:
              'La désactivation interdit tout login sur ce compte',
            nonLocked: 'Déverrouillé',
            locked: 'Verrouillé',
            nonLockedHelper:
              "Accessible lorsque le compte est vérouillé (trop de logins infructueux, voire 'Derniéres connexions' à droite)",
            lockedHelper: 'Vérouillé à la suite de trop de logins infructueux',
            lastLogins: 'Derniéres connexions',
            sections: {
              lastLogins: {
                title: 'Dix derniéres connexions',
                subtitle:
                  'Nota bene. – Un compte est vérouillé aprés trois logins infructueux. La réinitialisation du mot de passe le déverrouille.',
                noLogin: "Aucune connexion jusqu'à présent",
                loggedWith: 'avec',
                badCredentials: 'mauvais mot de passe',
                locked: 'compte bloqué!',
                unlocked: 'compte débloqué!',
                passwordReset: 'mot de passe réinitialisé',
                signout: 'déconnexion',
              },
              job: { title: 'Poste' },
              company: {
                title: 'Société',
                subtitle:
                  'Les informations sur la société dont éditables à partir du menu Sociétés',
                name: 'nom',
                address: 'adresse',
                fiscalCode: 'code fiscal',
              },
              person: {
                title: 'Utilisateur',
                subtitleForPspAdmin:
                  "Comme une personne peut posséder plus d'un compte, ses informations ne sont pas éditables ici " +
                  'mais depuis le menu Personnes',
                subtitleForCompanyAdmin:
                  "Seul l'utilisateur lui-même peut modifier ses propres informations " +
                  'depuis son profil (dans le coin en haut à droite',
                email: 'adresse électronique',
                phone: 'téléphone',
                phoneHelper: 'Le numéro de téléphone est optionnel.',
                noPhone: 'Pas de téléphone.',
                firstname: 'prénom',
                lastname: 'nom',
                language: 'langage',
                languageHelper: 'Le langage sélectionné au login',
                english: 'Anglais',
                romanian: 'Roumain',
                french: 'Français',
              },
            },
          },
          usageRights: {
            title: 'Droits',
            pspAdminSubtitle:
              'Un compte peut détenir trois sortes de droit: (1) les droits configurables, ' +
              '(2) les droits automatiquement attribués par la plateforme Doc Process ' +
              'et (3) les droits hérités par délégation entre utilisateurs. ' +
              'Ces deux derniers ne sont pas configurables.',
            companyAdminSubtitle:
              'Un compte peut détenir deux sortes de droit: (1) les droits configurables ' +
              'et (2) les droits hérités par délégation entre utilisateurs.',
            sections: {
              configurables: {
                title: 'Droits configurables',
                subtitle: 'Les droits configurés pour ce compte.',
              },
              onthefly: {
                title: 'Droits assignés automatiquement',
                subtitle:
                  'Les droits (en lecture seule) assignés automatiquement par la platforme Doc Process.',
                none: 'Aucun',
              },
              delegateds: {
                title: 'Droits hérités par délégation',
                subtitle:
                  "Les droits (en lecture seule) hérités d'un autre utilisateur " +
                  "(voire l'onglet délégation entre employés de la société: ",
                none: 'Aucun',
                feature: 'fonctionnalité',
                roles: 'rôles',
                from: 'délégation de',
              },
            },
          },
          history: {
            title: 'Historique des changements',
            subtitle: 'Tous les changements survenus sur ce compte.',
            changeType: 'Changement',
            when: 'En date de',
            by: 'Fait par',
            empty: "Aucun changement pour l'instant.",
            update: 'mise à jour',
            creation: 'création',
            deletion: 'suppression',
            changes: {
              what: 'Ce qui a changé:',
              accountEnabled: 'compte activé',
              accountDisabled: 'compte désactivé',
              newLogins: 'Nouveaux logins:',
              with: 'avec',
              removedLogins: 'Logins supprimés:',
              newUsageRights: "Nouveaux droits d'utilisation:",
              withRoles: 'avec les rôles:',
              removedUsageRights: "Droits d'utilisation supprimés:",
              newUsageRightRoles:
                "Droits d'utilisation avec de nouveaux roles:",
              withNewRoles: 'avec les nouveaux rôles:',
              removedUsageRightRoles:
                "Droits d'utilisation avec des rôles en moins:",
              removedRoles: 'rôles supprimés:',
              jobAddedAttributes: 'Nouveaux attributs du poste:',
              jobRemovedAttributes: 'Attributs du poste supprimés:',
              jobChangedAttributes: 'Attributs du poste modifiés:',
            },
            filters: {
              username: 'Utilisateur',
            },
          },
        },
        notifications: {
          updated: 'Le compte a été mis à jour',
        },
        errors: {
          identityAlreadyExists: "L'identité %{identityId} existe déjà!",
          personOrIdentityAlreadyExists:
            'La personne ou une identité existe déjà!',
        },
      },
      job: {
        key: 'clé',
        keyHelper: 'Choisissez une clé prédéfinie ou créez la vôtre',
        value: 'valeur',
        noJob: 'Aucun poste défini',
        create: {
          subtitle:
            "Vous pouvez compléter la description de l'utilisateur en décrivant ici son poste (ou laisser cette partie vide).",
        },
        edit: {
          subtitle:
            "Vous pouvez compléter la description de l'utilisateur en décrivant ici son poste (ou laisser cette partie vide).",
        },
        show: {
          subtitle:
            'Les attributions de votre poste définis par votre société.',
        },
      },
    },
    identityProviders: {
      menu: "Fournisseurs d'identité",
      list: {
        title: 'Les types de login supportés par la plateforme Doc Process',
        headers: { id: 'Type de login' },
      },
      common: {
        authentication: 'Authentification',
        authenticationHelper:
          'Les logins pour se connecter à ce compte. ' +
          'Les types de login proposés (Doc Process, Google, ...) sont ceux auxquels la société ' +
          'a souscrit, répertoriés dans le menu Société',
        loginType: 'Type de login',
        email: 'adresse électonique',
        emailOpenIdConnectHelper:
          "Sur la page de login, cliquez sur 'SIGINUP WITH %{providerId}' puis entrez cette adresse.",
        emailBuiltInHelper:
          "Cette adresse électronique est le login de l'utilisateur (la création du compte déclenche l'envoi par mail d'un lien à l'utilisateur afin qu'il configure son mot de passe).",
        errors: {
          notCompliant: 'Non conforme aux critères',
          onlyOneDxIdentity:
            "Vous ne pouvez configurer qu'un seul login Doc Process.",
        },
      },
    },
    regulatorAccessTransport: {
      submenu: 'Etransport Accès',
    },
    regulatorAccess: {
      menu: 'Plateforme publique',
      submenu: 'Accès',
      subtitle: "Informations d'accès à la plateforme publique",
      subtitleHelper: 'Liste des accès existants',
      infoMessage:
        "À compter du 1er juillet 2022, la législation roumaine exige que les types de factures suivants soient envoyés et certifiés par le système d'autorité fiscale du gouvernement: " +
        "(1) Factures comportant des marchandises « à haut risque fiscal », telles que défini par l'autorité fiscale. " +
        '(2) Factures pour les transactions Business-to-Government (B2G) (marchés publics). ' +
        "À partir de 2023, ce mandat s'étendra à toutes les factures Business-to-Business (B2B). " +
        "Afin de permettre à la plateforme DocProcess d'interagir avec l'autorité fiscale en votre nom et strictement à cette fin, vous devez fournir l'e-mail de l'Approbateur qui autorisera DocProcess à accéder à l'Espace Virtuel Privé (SPV) de votre entreprise.",
      status: {
        PENDING_VALIDATION: 'En attente de validation',
        UNDER_VALIDATION: 'En cours de validation',
        VALIDATED: 'Validé',
        REJECTED: 'Rejeté',
      },
      clientId: 'ID client',
      clientSecret: 'Secret',
      approverEmail: "Email de l'approbateur",
      create: {
        notifications: {
          updated: 'Informations mise à jour',
          successMessage:
            "Un e-mail a été envoyé à l'adresse e-mail de l'approbateur : %{approverEmail}. " +
            "Cet e-mail contient un lien qui redirigera l'approbateur vers le site Web de l'autorité fiscale, où il devra s'authentifier et autoriser l’accès de DocProcess. " +
            "L'approbateur doit accéder ce lien à partir de l'ordinateur sur lequel le certificat de l’autorité fiscale est installé dans le navigateur Web, sinon l'accès ne pourra être accordé. " +
            "Une fois la connexion établie, l'approbateur sera automatiquement redirigé vers la plateforme DocProcess et commencera à facturer selon le processus de l’autorité fiscale.",
        },
        errors: {
          'regulator.unavailable': 'Autorité fiscale indisponible',
        },
        actions: {
          new: 'Nouveau',
        },
      },
      list: {
        headers: {
          id: 'ID client',
          secret: 'Secret',
          created: 'Date de création',
          email: 'Créé par',
          status: 'Etat',
          companyId: 'Société',
          approverEmail: 'Email approbateur',
        },
        actions: {
          save: "Accorder l'accès",
        },
      },
    },
    dxfeatures: {
      // Enter below the description of your features and their associated roles:
      administration: {
        description: 'Administration des utilisateurs',
        'company administrator': 'Uniquement pour ma société',
        'PSP administrator': 'Pour toutes les sociétés',
      },
      archiving: {
        description: 'Archivage de documents',
        uploader: 'sauvegarde',
      },
      'purchase to pay': { description: 'Purchase to pay' },
      'CORAFGX-SUPERVISOR': {
        description: 'Superviseur/Superviseuse',
        'VIEW-INVOICE': 'Voir les factures',
        'EDIT-INVOICE': 'Modifier les factures',
      },
      'CORAFGX-APPROVER': { description: 'Approbateur de tâche' },
      'CORAFGX-TASK-ACCESS': { description: 'Accès aux tâches' },
      'CORAFGX-BUSINESS-ADMIN': { description: 'Administrateur Cora VisaIX' },
      'CMS-DM': {
        description: 'Visionneur de PDF',
        'DOWNLOAD-INVOICE': 'Voir PDF',
      },
      'reconciliation-cockpit': {
        description: 'Cockpit de réconciliation',
        reconciliator: 'Réconciliateur',
        viewer: 'Visionneur',
      },
      'allocation-cockpit': {
        description: 'Cockpit de class. des dépenses',
        allocator: 'Allocateur',
        viewer: 'Visionneur',
      },
      'simple-validation-cockpit': {
        description: 'Cockpit de validation simple',
        INVOICE_VALIDATOR_LEVEL_1: 'Validateur de factures de niveau 1',
        INVOICE_VALIDATOR_LEVEL_2: 'Validateur de factures de niveau 2',
      },
    },
    profile: {
      edit: {
        title: 'Votre profil',
        emailHelper:
          "L'adresse électronique n'est jamais modifiable. Demandez à votre administrateur si vous souhaitez la modifier.",
        changePassword: 'Changement du mot de passe',
        changePasswordHelper:
          "Pour changer votre mot de passe, entrez l'actuel et le nouveau " +
          '(qui doit être conforme à la stratégie de mot de passe).',
        currentPassword: 'Ancien mot de passe',
        newPassword: 'Nouveau mot de passe',
        pspAdministratorAlert:
          'Vous étes administrateur des comptes utilisateurs de la plateforme Doc Process.',
        companyAdministratorAlert:
          'Vous étes administrateur des comptes utilisateurs de votre société.',
        notifications: {
          updated: 'Votre profil a été mis à jour',
          error:
            'Une erreur technique empêche de mettre à jour votre profil. Veuillez réessayer ultérieurement',
        },
        tabs: {
          personalInfoAndPassword: 'Informations personnelles & mot de passe',
          personalInfo: 'Informations personnelles',
          job: 'Poste',
        },
      },
    },
  },
  myAccount: {
    email: 'Email',
    firstName: 'Prénom',
    lastName: 'Nom',
    phoneNumber: 'Numéro de téléphone',
    roles: 'Rôles',
    profile_update_success:
      "Les données de l'utilisateur (sauf l'adresse email) ont été mises à jour avec succès. Si l'adresse email a changé, une confirmation supplémentaire sera nécessaire. Des instructions vous seront envoyés vers la nouvelle adresse email.",
    profile_update_error:
      "Impossible de mettre à jour les données de l'utilisateur. Une erreur est survenue.",
    email_update_success: "L'adresse email a été mise à jour avec succès",
    email_update_error:
      "Impossible de mettre à jour l'adresse email. Une erreur est survenue.",
    update_profile_title: "Profil de l'utilisateur",
  },
  auth: {
    crt_password: 'Mot de passe actuel',
    crt_password_required: 'Veuillez saisir votre mot de passe actuel',
    crt_password_invalid: "Le mot de passe n'est pas valide",

    new_password: 'Nouveau mot de passe',
    new_password_required: 'Veuillez saisir votre nouveau mot de passe',

    confirm_password: 'Confirmer le mot de passe',
    confirm_password_required:
      'Veuillez saisir à nouveau votre nouveau mot de passe',

    lost_password_title: 'Mot de passe oublié ',
    lost_password_desc:
      'Veuillez saisir votre nom utilisateur pour demander le changement de mot de passe',
    lost_password_email_notif:
      "Si un compte existe pour ce nom d'utilisateur, un e-mail contenant plus d'instructions vient d'être envoyé.",
    lost_password_error:
      "Une erreur s'est produite lors de votre demande de mot de passe oublié.",

    change_password_title: 'Changement de mot de passe',
    configure_password_title: 'Configurer votre mot de passe',
    change_password_confirmation:
      "Votre mot de passe a été changé avec succès. Vous pouvez désormais l'utiliser pour vous connecter.",
    change_password_expired:
      "Le lien de réinitialisation de mot de passe a expiré. Votre mot de passe n'a pas été changé.",
    change_password_error:
      "Une erreur s'est produite lors du changement du mot de passe.",
    change_password_match_error:
      'Les mots de passe ne sont pas identiques. Veuillez réessayer.',
    token_too_many_attempts:
      'La limite de demandes a été dépassé.  Veuillez ré-essayer dans 30 minutes.',
    token_invalid_or_already_used:
      'Votre demande a expirée ou a déjà été exhaucée.',
    associatedWithLogin: 'associé à votre login',
  },
  menuBar: {
    selectLanguage: 'Langage',
    languageSuccess: 'Le choix de langage a été mis à jour.',
    languageError:
      "Le choix de langage n'a pas été mis à jour. Ré-essayez plus tard",
  },
  error: {
    internal:
      "Oups. Ré-essayez dans quelques minutes puias contactez le support@docprocess.com si l'erreur persiste.",
    taskNotFound:
      'Pas de chance: un autre utilisateur a acheveé cette tâche avant vous!',
    accept: {
      with_rej_reason:
        'Désolé, vous ne pouvez pas accepter une facture avec un motif de refus! Retirez-le avant de continuer.',
    },
    reject: {
      without_rej_reason:
        "Il manque un motif de rejet. Veuillez l'ajouter avant de continuer",
    },
  },
};
