/**
 * The Apps widget displays the icons of the available apps for
 * the current user to let him directly open them.
 * Widget not moveable, not removeable.
 * The apps for which the user has pending tasks display a badge.
 *
 */
import {
  Avatar,
  Badge,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CachedIcon from '@material-ui/icons/Cached';
import { default as Description } from '@material-ui/icons/Description';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { Loading, usePermissions, useTranslate } from 'react-admin';
import { isBetaTester, isPspAdministrator } from '../../configuration';
import { UsageRight } from '../../configuration/types';
import { Constants } from '../../constants';
import { checkPermissions } from '../../security';
import { UserRoles } from '../../security/UserRoles';
import { useTaskCount } from '../../tasks';
import { DxTheme } from '../../types';
import { useSwitchTo } from '../../utils';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    appsIcon: {
      flexDirection: 'column',
      textAlign: 'center',
      width: 100,
      height: 100,
      margin: '1em',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    appsIconDisabled: {
      flexDirection: 'column',
      textAlign: 'center',
      width: 100,
      height: 100,
      margin: '1em',
      '& div div': {
        backgroundColor: theme.colors.grey[600],
      },
      '& svg': {
        color: theme.colors.darkGrey,
      },
    },
    isHidden: {
      display: 'none',
    },
    avatar: {
      minWidth: 40,
      width: 60,
      height: 60,
      backgroundColor: theme.colors.lightPurple,
      '&:hover': {
        backgroundColor: theme.colors.lightBlue,
      },
      borderRadius: '50%',
    },
    icon: {
      width: 40,
      height: 40,
      color: theme.colors.white,
    },
    help: {
      position: 'fixed',
      right: theme.spacing(1),
      top: theme.spacing(0),
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
  { name: 'AppsWidget' }
);

const AppsSelector = ({ accountId }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const switchTo = useSwitchTo();
  const { loaded, permissions } = usePermissions<UsageRight[], any>();
  const taskCount = useTaskCount({
    permissions,
    productName: Constants.DXPURCHASE_APP,
  });
  const reconciliationTaskCount = useTaskCount({
    permissions,
    productName: Constants.RECONCILIATION_COCKPIT_APP,
  });

  if (!loaded) return <Loading />;

  /**
   * Checks if the app(i.e. product) is allowed or not according to user's permissions.
   * This method is used in order to mimic the disabled state incompatible with a
   * always available tooltip message (pointerEvents).
   * @param permissions user set of permissions
   * @param product product
   * @param role first role to check
   * @param role2 (optional) second role to check
   * @returns true if not allow, else false
   */
  const isDisabled = (
    permissions: any,
    product: string,
    role: string,
    role2?: string
  ): boolean => {
    if (!role2) {
      role2 = role;
    }
    return (
      !isPspAdministrator(permissions! as any) &&
      !checkPermissions(permissions, product, role) &&
      !checkPermissions(permissions, product, role2) &&
      !isBetaTester(permissions, product)
    );
  };

  return (
    <Box>
      <List>
        <Tooltip title={translate('dxMessages.appSwitcher.DocProcess.label')}>
          {/* Define a span in order to still have a tooltip even if the item is disabled */}
          <span>
            <ListItem
              button
              onClick={() => {
                switchTo(
                  `/${Constants.DXPURCHASE_APP}`,
                  Constants.DXPURCHASE_APP
                );
              }}
              disabled={isDisabled(
                permissions,
                UserRoles.DXPURCHASE_PRODUCT,
                UserRoles.DXPURCHASE_ACCESS
              )}
              classes={{
                root: isDisabled(
                  permissions,
                  UserRoles.DXPURCHASE_PRODUCT,
                  UserRoles.DXPURCHASE_ACCESS
                )
                  ? classes.appsIconDisabled
                  : classes.appsIcon,
              }}
            >
              <ListItemAvatar
                classes={{
                  root: classes.avatar,
                }}
              >
                <Badge badgeContent={taskCount} color='error' overlap='circle'>
                  <Avatar
                    classes={{
                      root: classes.avatar,
                    }}
                  >
                    <CachedIcon classes={{ root: classes.icon }} />
                  </Avatar>
                </Badge>
              </ListItemAvatar>
              <ListItemText primary={Constants.DXPURCHASE_APP_DISPLAY} />
            </ListItem>
          </span>
        </Tooltip>

        <Tooltip title={translate('dxMessages.appSwitcher.DxArchive.label')}>
          {/* Define a span in order to still have a tooltip even if the item is disabled */}
          <span>
            <ListItem
              button
              onClick={() => {
                switchTo(
                  `/${Constants.DXARCHIVE_APP}`,
                  Constants.DXARCHIVE_APP
                );
              }}
              disabled={isDisabled(
                permissions,
                UserRoles.DXARCHIVE_PRODUCT,
                UserRoles.DXARCHIVE_ACCESS
              )}
              classes={{
                root: isDisabled(
                  permissions,
                  UserRoles.DXARCHIVE_PRODUCT,
                  UserRoles.DXARCHIVE_ACCESS
                )
                  ? classes.appsIconDisabled
                  : classes.appsIcon,
              }}
            >
              <ListItemAvatar
                classes={{
                  root: classes.avatar,
                }}
              >
                <Avatar
                  classes={{
                    root: classes.avatar,
                  }}
                >
                  <UnarchiveIcon classes={{ root: classes.icon }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={Constants.DXARCHIVE_APP_DISPLAY} />
            </ListItem>
          </span>
        </Tooltip>
        <Tooltip title={translate('dxMessages.appSwitcher.DxCatalog.label')}>
          {/* Define a span in order to still have a tooltip even if the item is disabled */}
          <span>
            <ListItem
              button
              onClick={() => {
                switchTo(
                  `/${Constants.DXCATALOG_APP}`,
                  Constants.DXCATALOG_APP
                );
              }}
              disabled={isDisabled(
                permissions,
                UserRoles.DXCATALOG_PRODUCT,
                UserRoles.DXCATALOG_ACCESS
              )}
              classes={{
                root: isDisabled(
                  permissions,
                  UserRoles.DXCATALOG_PRODUCT,
                  UserRoles.DXCATALOG_ACCESS
                )
                  ? classes.appsIconDisabled
                  : classes.appsIcon,
              }}
            >
              <ListItemAvatar
                classes={{
                  root: classes.avatar,
                }}
              >
                <Avatar
                  classes={{
                    root: classes.avatar,
                  }}
                >
                  <LibraryBooksIcon classes={{ root: classes.icon }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={Constants.DXCATALOG_APP_DISPLAY} />
            </ListItem>
          </span>
        </Tooltip>
        <Tooltip
          title={translate(
            'dxMessages.appSwitcher.ReconciliationCockpit.label'
          )}
        >
          {/* Define a span in order to still have a tooltip even if the item is disabled */}
          <span>
            <ListItem
              button
              onClick={() => {
                switchTo(
                  `/${Constants.RECONCILIATION_COCKPIT_APP}`,
                  Constants.RECONCILIATION_COCKPIT_APP
                );
              }}
              disabled={isDisabled(
                permissions,
                'reconciliation-cockpit',
                'reconciliator',
                'viewer'
              )}
              classes={{
                root: isDisabled(
                  permissions,
                  'reconciliation-cockpit',
                  'reconciliator',
                  'viewer'
                )
                  ? classes.appsIconDisabled
                  : classes.appsIcon,
              }}
            >
              <ListItemAvatar
                classes={{
                  root: classes.avatar,
                }}
              >
                <Badge
                  badgeContent={reconciliationTaskCount}
                  color='error'
                  overlap='circle'
                >
                  <Avatar
                    classes={{
                      root: classes.avatar,
                    }}
                  >
                    <MergeTypeIcon classes={{ root: classes.icon }} />
                  </Avatar>
                </Badge>
              </ListItemAvatar>
              <ListItemText
                primary={Constants.RECONCILIATION_COCKPIT_APP_DISPLAY}
              />
            </ListItem>
          </span>
        </Tooltip>
        <Tooltip
          title={translate('dxMessages.appSwitcher.EtransportCockpit.label')}
        >
          {/* Define a span in order to still have a tooltip even if the item is disabled */}
          <span>
            <ListItem
              button
              onClick={() => {
                switchTo(
                  `/${Constants.ETRANSPORT_COCKPIT_APP}`,
                  Constants.ETRANSPORT_COCKPIT_APP
                );
              }}
              disabled={isDisabled(
                permissions,
                'E-TRANSPORT',
                'ETRANSPORT_COCKPIT_ACCESS'
              )}
              classes={{
                root: isDisabled(
                  permissions,
                  'E-TRANSPORT',
                  'ETRANSPORT_COCKPIT_ACCESS'
                )
                  ? classes.appsIconDisabled
                  : classes.appsIcon,
              }}
            >
              <ListItemAvatar
                classes={{
                  root: classes.avatar,
                }}
              >
                <Avatar
                  classes={{
                    root: classes.avatar,
                  }}
                >
                  <LocalShippingIcon classes={{ root: classes.icon }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={Constants.ETRANSPORT_COCKPIT_APP_DISPLAY}
                primaryTypographyProps={{ noWrap: true }}
              />
            </ListItem>
          </span>
        </Tooltip>
        <Tooltip title={translate('dxMessages.appSwitcher.DxContract.label')}>
          <span>
            <ListItem
              button
              onClick={() => {
                switchTo(
                  `/${Constants.DXCONTRACT_APP}`,
                  Constants.DXCONTRACT_APP
                );
              }}
              disabled={isDisabled(
                permissions,
                UserRoles.DXCONTRACT_PRODUCT,
                UserRoles.DXCONTRACT_ACCESS
              )}
              classes={{
                root: isDisabled(
                  permissions,
                  UserRoles.DXCONTRACT_PRODUCT,
                  UserRoles.DXCONTRACT_ACCESS
                )
                  ? classes.appsIconDisabled
                  : classes.appsIcon,
              }}
            >
              <ListItemAvatar
                classes={{
                  root: classes.avatar,
                }}
              >
                <Avatar
                  classes={{
                    root: classes.avatar,
                  }}
                >
                  <Description classes={{ root: classes.icon }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={Constants.DXCONTRACT_APP_DISPLAY} />
            </ListItem>
          </span>
        </Tooltip>
        <Tooltip title={translate('dxMessages.appSwitcher.C4Cockpit.label')}>
          {/* Define a span in order to still have a tooltip even if the item is disabled */}
          <span>
            <ListItem
              button
              onClick={() => {
                switchTo(
                  `/${Constants.C4_COCKPIT_APP}`,
                  Constants.C4_COCKPIT_APP_DISPLAY
                );
              }}
              disabled={isDisabled(
                permissions,
                'carrefour-inbound-cockpit',
                'C4_EFACTURA_COCKPIT_ACCESS'
              )}
              classes={{
                root: isDisabled(
                  permissions,
                  'carrefour-inbound-cockpit',
                  'C4_EFACTURA_COCKPIT_ACCESS'
                )
                  ? classes.isHidden
                  : classes.appsIcon,
              }}
            >
              <ListItemAvatar
                classes={{
                  root: classes.avatar,
                }}
              >
                <Avatar
                  classes={{
                    root: classes.avatar,
                  }}
                >
                  <FileCopyIcon classes={{ root: classes.icon }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={Constants.C4_COCKPIT_APP_DISPLAY}
                primaryTypographyProps={{ noWrap: true }}
              />
            </ListItem>
          </span>
        </Tooltip>
        <Tooltip title={translate('dxMessages.appSwitcher.EmagCockpit.label')}>
          {/* Define a span in order to still have a tooltip even if the item is disabled */}
          <span>
            <ListItem
              button
              onClick={() => {
                switchTo(
                  `/${Constants.EMAG_COCKPIT_APP}`,
                  Constants.EMAG_COCKPIT_APP_DISPLAY
                );
              }}
              disabled={isDisabled(
                permissions,
                'emag-inbound-cockpit',
                'ROLE_EMAG_EFACTURA_COCKPIT'
              )}
              classes={{
                root: isDisabled(
                  permissions,
                  'emag-inbound-cockpit',
                  'ROLE_EMAG_EFACTURA_COCKPIT'
                )
                  ? classes.isHidden
                  : classes.appsIcon,
              }}
            >
              <ListItemAvatar
                classes={{
                  root: classes.avatar,
                }}
              >
                <Avatar
                  classes={{
                    root: classes.avatar,
                  }}
                >
                  <FileCopyIcon classes={{ root: classes.icon }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  isPspAdministrator(permissions as any)
                    ? Constants.EMAG_COCKPIT_APP_DISPLAY
                    : Constants.C4_COCKPIT_APP_DISPLAY
                }
                primaryTypographyProps={{ noWrap: true }}
              />
            </ListItem>
          </span>
        </Tooltip>
        <Tooltip title={translate('dxMessages.appSwitcher.EmagCockpit.label')}>
          {/* Define a span in order to still have a tooltip even if the item is disabled */}
          <span>
            <ListItem
              button
              onClick={() => {
                switchTo(
                  `/${Constants.CORA_COCKPIT_APP}`,
                  Constants.CORA_COCKPIT_APP_DISPLAY
                );
              }}
              disabled={isDisabled(
                permissions,
                'cora-inbound-cockpit',
                'ROLE_CORA_EFACTURA_COCKPIT'
              )}
              classes={{
                root: isDisabled(
                  permissions,
                  'cora-inbound-cockpit',
                  'ROLE_CORA_EFACTURA_COCKPIT'
                )
                  ? classes.isHidden
                  : classes.appsIcon,
              }}
            >
              <ListItemAvatar
                classes={{
                  root: classes.avatar,
                }}
              >
                <Avatar
                  classes={{
                    root: classes.avatar,
                  }}
                >
                  <FileCopyIcon classes={{ root: classes.icon }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  isPspAdministrator(permissions as any)
                    ? Constants.CORA_COCKPIT_APP_DISPLAY
                    : Constants.C4_COCKPIT_APP_DISPLAY
                }
                primaryTypographyProps={{ noWrap: true }}
              />
            </ListItem>
          </span>
        </Tooltip>
      </List>
    </Box>
  );
};

export default AppsSelector;
