export default {
  resources: {
    login: {
      emailCheck: 'Ați introdus o adresă invalidă de e-mail!',
      email: 'Email',
      password: 'Parolă',
      company: 'Compania',
      or: 'SAU',
      loginWith: 'Inscrie-te cu %{providerId}',
      onlyWithRegisteredAlternateLogins:
        'Numai dacă datele de conectare alternative au fost înregistrate.',
      errors: {
        badCredentials: 'Autentificare eșuată!',
        locked:
          'Cont blocat după încercări nereușite de %{count}. Blocarea va fi lansată %{date}.',
        unexpected:
          'Ne pare rău: a apărut o eroare neașteptată. Vă rugăm, reîncercați mai târziu sau contactați asistența pentru Doc Process.',
      },
      notifications: {
        selectCompany:
          'Vă rugăm să selectați compania la care doriți să vă autentificați',
      },
    },
    task: {
      menu: 'Sarcinile mele',
      list: {
        title: 'Sarcinile mele',
      },
      'cora-fgx-pdf': {
        Cora_FGX_Validation_User_Task: {
          edit: {
            title: 'Aprobați sau respingeți factura',
            open: {
              button: 'Aprobă sau respinge',
            },
            save: {
              button: 'Aprobă',
            },
            cancel: {
              button: 'Respinge',
            },
            update: {
              button: 'Salvați',
            },
            cancelUpdate: {
              button: 'Anulare',
            },
            successMessage: 'Terminat',
          },
        },
      },
    },
    administration: {
      menu: 'Administrare',
    },
    departments: {
      menu: 'Departamente',
    },
    roles: {
      menu: 'Roluri',
    },
    establishments: {
      menu: 'Unități',
    },
    validationMatrix: {
      menu: 'Matrice de Validare',
    },
    user_and_company: {
      menu: 'Utilizator și companie',
    },
    user_and_company_v3: {
      menu: 'Utilizator și companie (V3)',
    },
    companies: {
      menu: 'Companii',
      list: {
        title: 'Companii',
        filters: {
          name: 'Nume',
        },
        headers: {
          name: 'Nume',
          fiscalCode: 'Cod fiscal',
          address: 'Adresă',
        },
        toptoolbar: {
          create: 'Creați o companie',
        },
      },
      edit: {
        title: '%{companyName} companie',
        deleteConfirmTitle: 'Ștergeti %{username}',
        tabs: {
          company: {
            title: 'Companie',
            sections: {
              description: {
                subtitle:
                  'Informațiile de mai jos sunt doar pentru citire. În cazul unor erori, vă rugăm să contactați suportul pentru a le corecta.',
                name: 'nume',
                fiscalCode: 'cod fiscal',
                address: 'adresă',
                cmsRootDir: 'directorul companiei',
                cmsRootDirHelper:
                  'Directorul din sistemul de management al conținutului în care sunt stocate toate documentele companiei (facturi, ...).',
              },
              userAuthenticationTypes: {
                subtitle: 'Tipuri de conectare acceptate',
                subtitleHelper:
                  'Tipurile de autentificare (autentificare Doc Process bazată pe parolă, autentificare socială, autentificare corporativă, ...) ' +
                  'acceptate de această companie pentru ca utilizatorii săi să se autentifice. Contactați asistența dacă doriți să adăugați noi tipuri de conectare.',
              },
            },
          },
          subscriptions: {
            title: 'Abonamente de caracteristici',
            subtitle_1:
              'Toate funcțiile Doc Process (numai citire) la care compania s-a abonat.\u0020' +
              'Depind de contractul semnat cu Doc Process. Contacteaza\u0020',
            subtitle_2: 'suport',
            subtitle_3: '\u0020dacă doriți să schimbați această listă.',
            subtitle_4:
              'Odată abonat, fiecare utilizare a funcției poate fi atribuită unui cont de utilizator prin meniul Conturi',
            psp_message:
              'Atunci când selectează o funcție, compania va avea acces la toate rolurile subiacente, minus cele ' +
              'excluse în mod specific.',
            feature: 'caracteristică',
            active: 'activă',
            excludedRoleIds: 'roluri excluse',
          },
          delegations: {
            title: 'Delegările angajaților',
            subtitle:
              'Delegarea drepturilor de utilizare ale unui utilizator către un alt utilizator. Utilizati ' +
              'atunci când un angajat lipsește din funcție pentru o perioadă și doriți ca altul ' +
              'să îi preia atribuțiile în timpul absenței sale.',
            representee: 'reprezentant',
            representeeHelper:
              'Utilizatorul ale cărui drepturi trebuie să fie delegate.',
            delegee: 'delegat',
            delegeeHelper:
              'Utilizatorul care moștenește drepturile de utilizare.',
            periodTitle:
              'Această delegare va fi activă (atenție: în fusul dumneavoastră orar):',
            from: 'de la',
            to: 'la',
            errors: {
              samePerson:
                'linia %{line}: reprezentantul și delegatul TREBUIE să fie două persoane diferite.',
              fromAfterTo:
                'linia %{line}: data de la TREBUIE să fie mai mare decât până la.',
            },
          },
          history: {
            title: 'Istoricul modificării conturilor',
            subtitle:
              'Toate evenimentele care au avut loc pe durata de viață a conturilor companiei.',
            accountOf: 'Cont de',
            changeType: 'Change',
            when: 'Când',
            by: 'De către',
          },
        },
        notifications: {
          created: 'Companie creată',
          updated: 'Companie actualizată',
        },
        notificationsPeppol: {
          created: 'Elementul a fost creat',
          updated: 'Elementul a fost actualizat',
        },
      },
    },
    company: {
      menu: 'Companie',
    },
    people: {
      menu: 'Persoane',
      list: {
        title: 'Persoane',
        filters: {
          email: 'Email',
        },
        headers: {
          email: 'Email',
          firstname: 'Prenume',
          lastname: 'Nume de familie',
        },
      },
      edit: {
        sections: {
          info: {
            email: 'e-mail',
            emailHelper:
              'E-mailul nu este niciodată modificabil. Trebuie să ștergeți și să creați un cont nou dacă doriți să îl modificați.',
            phone: 'număr de telefon',
            phoneHelper: 'Numărul de telefon este opțional.',
            firstname: 'prenume',
            lastname: 'nume de familie',
            language: 'limbă',
            english: 'Engleză',
            romanian: 'Română',
            french: 'Franceză',
          },
          authentication: {
            title: 'Autentificare',
            subtitle:
              'Datele de conectare pe care persoana le poate utiliza pentru a se conecta la conturile sale de mai jos.',
            type: 'tip',
            login: 'autentificare',
            none: 'Nu este configurată nicio autentificare.',
          },
          accounts: {
            title: 'Conturi',
            subtitle: 'Conturile persoanei per companie.',
            company: 'Companie',
            enabled: 'Activat',
            nonLocked: 'Neblocată',
          },
        },
        notifications: {
          updated: 'Persoană actualizată',
        },
      },
    },
    address: {
      menu: 'Adrese',
      list: {
        title: 'Adrese',
        headers: {
          street: 'Strada',
          additionalStreet: 'Strada Aditionala',
          buildingNumber: 'Numar Cladire',
          postBox: 'Cutie Postala',
          city: 'Oras',
          postalCode: 'Cod Postal',
          financialAccount: 'IBAN',
          bank: 'Banca',
          gln: 'GLN',
          country: 'Cod Tara',
          flgBilling: 'Adresa Facturare',
          flgShipping: 'Adresa livrare',
          flgLogistic: 'Adresa logistica',
        },
      },
      edit: {
        title: 'Address',
        sections: {
          street: 'Strada',
          additionalStreet: 'Strada Aditionala',
          buildingNumber: 'Numar Cladire',
          postBox: 'Cutie Postala',
          city: 'Oras',
          district: 'Judet',
          postalCode: 'Cod Postal',
          financialAccount: 'IBAN',
          bank: 'Banca',
          gln: 'GLN',
          country: 'Cod Tara',
          flgBilling: 'Adresa Facturare',
          flgShipping: 'Adresa livrare',
          flgLogistic: 'Adresa logistica',
        },
        notifications: {
          updated: 'Elementul a fost actualizat',
          created: 'Elementul a fost creat',
          flgError: 'Selecteaza tipul adresei',
        },
      },
    },
    supportedProducts: {
      create: {
        error: 'Acest produs este deja creat.',
      },
    },
    accounts: {
      menu: 'Conturi',
      list: {
        title: 'Conturi',
        filters: {
          company: 'Companie',
          person: 'Persoană',
          status: {
            title: 'Stare',
            disabled: 'dezactivată',
            locked: 'blocată',
          },
          created: {
            title: 'Creată',
            today: 'Astăzi',
            thisWeek: 'Săptămâna aceasta',
            lastWeek: 'Săptămâna trecută',
            thisMonth: 'Luna aceasta',
            lastMonth: 'Luna trecută',
            earlier: 'Mai devreme',
          },
        },
        headers: {
          person: 'Persoană',
          company: 'Companie',
          enabled: 'Activată',
          nonLocked: 'Neblocată',
          created: 'Creare',
        },
        toptoolbar: {
          create: 'Creați un cont',
        },
      },
      create: {
        title: 'Asistent pentru crearea contului',
        notifications: {
          created: 'Cont creat cu succes',
        },
        wizard: {
          back: 'Înapoi',
          next: 'Următorul',
          steps: {
            companySelection: {
              title: 'Selectați compania căreia îi aparține contul',
              subtitle:
                'Detaliile fiecărei companii se găsesc în meniul Companie',
              company: 'Companie',
            },
            personSelection: {
              title: 'Definiți persoana pentru care este destinat contul',
              subtitle:
                'Puteți selecta o persoană existentă SAU crea una nouă.',
              selectPerson: 'Selectați o persoană existentă.',
              createPerson: 'Creați o nouă persoană.',
              or: 'SAU',
              person: 'persoană',
              job: 'Funcție',
              authentication: 'Autentificare',
              allFieldsMandatory:
                'Vă rugăm să completați toate câmpurile obligatorii de mai jos.',
              uniqueAmongCompanyEmails:
                'Trebuie să fie unic printre toate conturile de e-mail din companie',
              selectedLanguageOnLogin: 'Limba selectată la conectare',
              phoneHelper:
                'Numărul de telefon este opțional. Îl puteți lăsa gol.',
            },
            usageRightsSelection: {
              title: 'Atribuiți drepturile de utilizare acestui cont',
              subtitle:
                'Drepturile de utilizare atribuite pentru acest cont. ' +
                'Puteți atribui drepturi numai pentru caracteristicile la care s-a abonat compania. ' +
                'Puteți să nu introduceți niciun drept aici și le puteți atribui mai târziu după crearea contului.',
              feature: 'caracteristică',
              roles: 'roluri',
              errors: {
                duplicateFeatures:
                  'Ați pus de două ori aceleași caracteristici. Vă rugăm să grupați toate rolurile într-unul singur.',
              },
            },
            checkAndSave: {
              title: 'Verificați și salvați',
            },
          },
        },
      },
      edit: {
        titleWithCompany: '%{username} cont în %{companyName}',
        titleWithoutCompany: '%{username} cont',
        deleteConfirmTitle: 'Ștergeți %{username}',
        tabs: {
          user: {
            title: 'utilizator',
            created: 'Creat pe %{date}',
            accountEnabled: 'Activat',
            accountDisabled: 'Dezactivat',
            accountEnabledHelper:
              'Dezactivarea previne ca utilizatorul să se autentifice.',
            nonLocked: 'Neblocat',
            locked: 'Blocat',
            nonLockedHelper:
              'Comutabil atunci când este blocat (după prea multe autentificări eșuate, a se vedea. Ultimele autentificări în colțul din dreapta)',
            lockedHelper: 'Blocat după prea multe autentificări eșuate',
            lastLogins: 'Ultimele autentificări',
            sections: {
              lastLogins: {
                title: 'Ultimele 10 autentificări',
                subtitle:
                  'Nota bene. - Un cont este blocat după trei autentificări eșuate. Resetarea parolei deblochează contul.',
                noLogin:
                  'Nimeni nu a folosit acest cont pentru a se autentifica.',
                loggedWith: 'cu',
                badCredentials: 'credențiale greșite',
                locked: 'cont blocat!',
                unlocked: 'cont deblocat',
                passwordReset: 'resetarea parolei',
                signout: 'delogare',
              },
              job: { title: 'Denumirea funcției' },
              company: {
                title: 'Companie',
                subtitle:
                  'Datele companiei pot fi editate din meniul Companie.',
                name: 'nume',
                address: 'adresă',
                fiscalCode: 'codul fiscal',
              },
              person: {
                title: 'Utilizator',
                subtitleForPspAdmin:
                  'O persoană poate deține mai multe conturi, insă datele sale nu sunt modificabile aici.' +
                  'ci din meniul Persoane',
                subtitleForCompanyAdmin:
                  'Numai persoana în cauză poate edita informațiile de mai jos' +
                  'prin profilul său de utilizator (în colțul din dreapta sus)',
                email: 'e-mail',
                phone: 'telefon',
                phoneHelper: 'Numărul de telefon este opțional.',
                noPhone: 'Fără număr de telefon.',
                firstname: 'prenume',
                lastname: 'nume de familie',
                language: 'limbă',
                languageHelper: 'Limba selectată atunci când vă autentificați',
                english: 'Engleză',
                romanian: 'Română',
                french: 'Franceză',
              },
            },
          },
          usageRights: {
            title: 'drepturi de utilizare',
            pspAdminSubtitle:
              'Un cont poate deține trei tipuri de drepturi de utilizare: (1) drepturi de utilizare configurabile' +
              '(2) drepturile atribuite în mod automat de către platforma Doc Process' +
              'și (3) drepturile moștenite prin delegare de la utilizatori. Ultimele ' +
              'două nu sunt configurabile.',
            companyAdminSubtitle:
              'Un cont poate deține două tipuri de drepturi de utilizare: (1) drepturile configurabile ' +
              'și (2) drepturile moștenite prin delegare de la utilizatori.',
            sections: {
              configurables: {
                title: 'Drepturi de utilizare configurabile',
                subtitle:
                  'Drepturile de utilizare configurate pentru acest cont.',
              },
              onthefly: {
                title: 'Atribuirea automată a drepturilor de utilizare',
                subtitle:
                  'Drepturile de utilizare doar pentru citire alocate automat de platforma Doc Process.',
                none: 'Niciunul',
              },
              delegateds: {
                title: 'Drepturi de utilizare moștenite prin delegare',
                subtitle:
                  'Drepturile de utilizare în regim de citire exclusivă moștenite de la alți utilizatori (a se vedea tab-ul de delegare din cadrul companiei ',
                none: 'Niciunul',
                feature: 'caracteristică',
                roles: 'roluri',
                from: 'delegație din partea',
              },
            },
          },
          history: {
            title: 'istoricul modificărilor',
            subtitle:
              'Toate evenimentele care au avut loc pe durata de viață a contului.',
            changeType: 'Schimbare',
            when: 'Când',
            by: 'De către',
            empty: 'Nici un eveniment încă.',
            update: 'actualizare',
            creation: 'creare',
            deletion: 'ștergere',
            changes: {
              what: 'Ce s-a schimbat:',
              accountEnabled: 'cont activat',
              accountDisabled: 'cont dezactivat',
              newLogins: 'Noi autentificări:',
              with: 'cu',
              removedLogins: 'Autentificări eliminate:',
              newUsageRights: 'Noi drepturi de utilizare:',
              withRoles: 'cu rolurile:',
              removedUsageRights: 'Drepturi de utilizare eliminate:',
              newUsageRightRoles: 'Drepturi de utilizare cu roluri noi:',
              withNewRoles: 'cu roluri noi:',
              removedUsageRightRoles:
                'Drepturi de utilizare cu roluri eliminate:',
              removedRoles: 'roluri eliminate:',
              jobAddedAttributes: 'Atribute noi ale postului:',
              jobRemovedAttributes: 'Atributele postului eliminate:',
              jobChangedAttributes: 'Atributele postului modificate:',
            },
            filters: {
              username: 'nume de utilizator',
            },
          },
        },
        notifications: {
          updated: 'Contul a fost actualizat',
        },
        errors: {
          identityAlreadyExists: 'Identitatea %{identityId} există deja!',
          personOrIdentityAlreadyExists:
            'Persoana sau o identitate există deja!',
        },
      },
      job: {
        key: 'key',
        keyHelper: 'Alegeți una predefinită sau creați-o pe a dumneavoastră',
        value: 'valoare',
        noJob: 'Nici o funcție definită',
        create: {
          subtitle:
            'Puteți completa descrierea utilizatorului descriind aici funcția acestuia (sau lăsați această parte necompletată).',
        },
        edit: {
          subtitle:
            'Puteți completa descrierea utilizatorului descriind aici funcția acestuia (sau lăsați această parte necompletată).',
        },
        show: {
          subtitle:
            'Atributele funcției dumneavoastră, așa cum sunt definite de compania dumneavoastră.',
        },
      },
    },
    identityProviders: {
      menu: 'Furnizori de identitate',
      list: {
        title: 'Tipurile de autentificare suportate de platforma Doc Process',
        headers: { id: 'tip de autentificare' },
      },
      common: {
        authentication: 'Autentificare',
        authenticationHelper:
          'Credentialele pe care persoana le poate utiliza pentru a se conecta la acest cont.' +
          'Tipurile de autentificare propuse (Doc Process, Google, ...) sunt cele la care compania ' +
          's-a abonat, enumerate în meniul Companie.',
        loginType: 'Tip de autentificare',
        email: 'e-mail',
        emailOpenIdConnectHelper:
          "Pe pagina de conectare, faceți clic pe'SIGINUP WITH WITH %{providerId}' apoi introduceți acest cont de e-mail.",
        emailBuiltInHelper:
          'Această adresă de e-mail este adresa de autentificare a utilizatorului (crearea contului declanșează trimiterea prin e-mail a unui link către utilizator pentru ca acesta să-și poată configura parola).',
        errors: {
          notCompliant: 'Nu este în conformitate cu politica privind parolele',
          onlyOneDxIdentity:
            'Puteți configura doar o singură autentificare Doc Process.',
        },
      },
    },
    regulatorAccessTransport: {
      submenu: 'Etransport Acces',
    },
    regulatorAccess: {
      menu: 'Autoritatea Fiscala',
      submenu: 'Efactura Acces',
      subtitle: 'Acces la informații la organismul de reglementare',
      subtitleHelper: 'Lista acceselor existente',
      infoMessage:
        'Începând cu 1 iulie 2022, legislația română prevede ca următoarele tipuri de facturi sa fie transmise și certificate de către sistemul autorității fiscale guvernamentale: ' +
        '(1) Facturi care includ bunuri cu „risc fiscal ridicat”, așa cum sunt definite de autoritatea fiscală. ' +
        '(2) Facturi pentru tranzacții Business-to-Government (B2G) (achiziții publice). ' +
        'Din 2023, acest mandat se va extinde la toate facturile Business-to-Business (B2B). ' +
        'Pentru a permite platformei DocProcess să interacționeze cu autoritatea fiscală în numele companiei tale strict în acest scop, trebuie să furnizezi e-mailul Aprobatorului care va autoriza DocProcess să acceseze Spațiul Virtual Privat (SPV) al companiei tale.',
      status: {
        PENDING_VALIDATION: 'Se așteaptă validarea',
        UNDER_VALIDATION: 'În curs de validare',
        VALIDATED: 'Validat',
        REJECTED: 'Respins',
      },
      clientId: 'ID client',
      clientSecret: 'Secret',
      approverEmail: 'E-mail de aprobare',
      create: {
        notifications: {
          updated: 'Informații actualizate',
          successMessage:
            'Un e-mail a fost trimis la adresa de e-mail a Aprobatorului: %{approverEmail}. ' +
            'Acest e-mail conține un link care va redirecționa Aprobatorul către site-ul web al autoritatea fiscală, unde va trebui să se autentifice și să autorizeze DocProcess. ' +
            'Aprobatorul trebuie să acceseze acest link de pe computerul care are certificatul autoritatea fiscală instalat în browserul web, altfel accesul nu poate fi acordat. ' +
            'Odată ce conexiunea este reușită, Aprobatorul va fi redirecționat automat către platforma DocProcess și va începe facturarea conform procesului autoritatea fiscală.',
        },
        errors: {
          'regulator.unavailable': 'Autoritatea fiscală indisponibil',
        },
        actions: {
          new: 'Nou',
        },
      },
      list: {
        headers: {
          id: 'ID client',
          secret: 'Secret',
          created: 'Data creării',
          email: 'Creat de',
          status: 'Stare',
          companyId: 'Companie',
          approverEmail: 'E-mail aprobare',
        },
        actions: {
          save: 'Acordați acces',
        },
      },
    },
    dxfeatures: {
      // Enter below the description of your features and their associated roles:
      administration: {
        description: 'Administrarea utilizatorilor',
        'company administrator': 'Doar pentru compania mea',
        'PSP administrator': 'Pentru toate companiile',
      },
      archiving: {
        description: 'Arhivarea documentelor',
      },
      'purchase to pay': { description: 'Purchase to pay' },
      'CORAFGX-SUPERVISOR': {
        description: 'Supervisor',
        'VIEW-INVOICE': 'Vizualizați facturile',
        'EDIT-INVOICE': 'Editați facturile',
      },
      'CORAFGX-APPROVER': {
        description: 'Aprobarea sarcinilor',
        'APPROVE-INVOICE': 'Aprobarea facturilor',
      },
      'CORAFGX-TASK-ACCESS': { description: 'Vezi sarcinile' },
      'CORAFGX-BUSINESS-ADMIN': {
        description: 'Cora VisaIX admin',
        'EDIT-CONTENT': 'Editați conținutul de afaceri',
      },
      'CMS-DM': {
        description: 'Accesul la documente',
        'DOWNLOAD-ALL': 'Descărcați toate documentele',
        'DOWNLOAD-INVOICE': 'Descărcați factura',
        'READ-ALL': 'Vizualizați toate documentele',
      },
      'reconciliation-cockpit': {
        description: 'Platforma de reconciliere',
        reconciliator: 'Reconciliator',
        viewer: 'Vizualizator',
      },
      'allocation-cockpit': {
        description: 'Platforma de clasif. a cheltuielilor',
        allocator: 'Alocator',
        viewer: 'Vizualizator',
      },
      'simple-validation-cockpit': {
        description: 'Platforma de validare simplă',
        INVOICE_VALIDATOR_LEVEL_1: 'Validator de facturi nivelul 1',
        INVOICE_VALIDATOR_LEVEL_2: 'Validator de facturi nivelul 2',
      },
    },
    profile: {
      edit: {
        title: 'Profilul dumneavoastră',
        emailHelper:
          'E-mailul nu este niciodată editabil. Adresați-vă administratorului dumneavoastră dacă doriți să îl modificați.',
        changePassword: 'Schimbați parola',
        changePasswordHelper:
          'Pentru a vă schimba parola, introduceți-o pe cea actuală și pe cea nouă ' +
          '(care trebuie să fie în conformitate cu politica).',
        currentPassword: 'Parola curentă',
        newPassword: 'Parolă nouă',
        pspAdministratorAlert:
          'Sunteți un administrator al tuturor conturilor platformei Doc Process.',
        companyAdministratorAlert:
          'Sunteți administrator al conturilor companiei dumneavoastră.',
        notifications: {
          updated: 'Profilul dumneavoastră. a fost actualizat',
          error:
            'O eroare tehnică a împiedicat actualizarea profilului dumneavoastră. Vă rugăm să încercați mai târziu',
        },
        tabs: {
          personalInfoAndPassword: 'Informații personale și parolă',
          personalInfo: 'Informatie personala',
          job: 'denumirea funcției',
        },
      },
    },
  },
  myAccount: {
    email: 'Email',
    firstName: 'Prenume',
    lastName: 'Nume',
    phoneNumber: 'Număr de telefon',
    roles: 'Roluri',
    profile_update_success:
      'Datele utilizatorului (cu excepția adresei email) au fost actualizate cu succes. Dacă adresa de email a fost schimbată, e necesară o confirmare suplimentară. Verificați noua adresă de email pentru instrucțiuni.',
    profile_update_error:
      'Datorită unei erori, actualizarea datelor utilizatorului nu este posibilă.',
    email_update_success: 'Adresa email a fost actualizată cu succes.',
    email_update_error:
      'Datorită unei erori, actualizarea adresei email nu este posibilă.',
    update_profile_title: 'Profil utilizator',
  },
  auth: {
    crt_password: 'Parola curentă',
    crt_password_required: 'Parola curentă este obligatorie',
    crt_password_invalid: 'Parolă incorectă',

    new_password: 'Noua parolă',
    new_password_required: 'Noua parolă este obligatorie',

    confirm_password: 'Confirmare parolă',
    confirm_password_required: 'Confirmarea noii parole este obligatorie',

    lost_password_title: 'Am uitat parola',
    lost_password_desc:
      'Introduceți numele de utilizator pentru a cere schimbarea parolei',
    lost_password_email_notif:
      'Dacă un cont există pentru acest nume de utilizator, un email cu instrucțiuni tocmai a fost trimis',
    lost_password_error:
      'O eroare s-a produs în urma cererii de schimbare a parolei',

    change_password_title: 'Schimbare parolă',
    change_password_confirmation:
      'Parola dumneavoastră a fost schimbată cu succes. De acum, vă puteți conecta utilizănd noua parolă.',
    change_password_expired:
      'Adresa utilizată pentru schimbarea parolei a expirat. Parola nu a fost schimbată.',
    change_password_error:
      'O eroare s-a produs în urma încercării de schimbare a parolei. Nici o schimbare nu s-a efectuat',
    change_password_match_error:
      'Cele două parole introduse nu corespund. Vă rugăm reîncercați',
    token_too_many_attempts:
      'Numărul maxim de cereri a fost depășit. Încercați din nou în 30 minute.',
    token_invalid_or_already_used:
      'Cererea dumneavoastră a expirat sau nu este validă.',
  },
  menuBar: {
    selectLanguage: 'Limbă',
    languageSuccess: 'Limba a fost schimbată cu succes',
    languageError:
      'Limba nu a putut fi actualizată, te rugăm să încerci mai târziu',
  },
  error: {
    internal:
      'Ups! Te rugăm să încerci din nou în câteva minute iar apoi să contactezi support@docprocess.com dacă eroarea persistă.',
    taskNotFound:
      'Se pare că un alt utilizator a completat această acțiune înaintea ta!',
    accept: {
      with_rej_reason:
        'Ne pare rău, nu puteți accepta o factură cu un motiv de respingere! Scoateți-l înainte de a continua.',
    },
    reject: {
      without_rej_reason:
        'Un motiv de respingere lipsește. Vă rugăm să îl adăugați înainte de a continua',
    },
  },
};
