import { Constants, isPspAdministrator } from '@dx-ui/dx-common/src';
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Tooltip,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
import countryLanguage from 'country-language';
import HttpStatus from 'http-status-codes';
import { split } from 'lodash';
import { FC, useState } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  EditProps,
  FieldProps,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  Translate,
  maxLength,
  required,
  useLocale,
  useNotify,
  usePermissions,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { CountryService } from '../../../../services';
import { V2CompanyModel } from '../../../../shared/types';
import UploadFilesField from '../UploadFilesField';

const useStyles = makeStyles((theme) => ({
  fillSpace: {
    width: '100%',
    minWidth: 300,
  },
}));

/**
 * The edition of a person.
 *
 * Only visible for PSP administrators.
 */
const AccountDetailsCreate: FC<EditProps> = (props) => {
  const notify = useNotify();
  const { permissions } = usePermissions();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = (body) => {
    notify('resources.address.edit.notifications.created');
    redirect(props.basePath + '/' + body.data.id + '/show');
  };

  const onFailure = (error) => {
    // eslint-disable-next-line no-console
    console.error(error);
    if (error?.status === HttpStatus.CONFLICT) {
      // company already exist
      notify('dxMessages.error_messages.company_already_exists', 'error');
    } else {
      // unknown error
      notify('dxMessages.error_messages.OnError_contact_the_support', 'error', {
        error: error.message,
      });
    }
  };

  /**
   * Formats Trade Register Number
   * @param formData form data
   * @returns form data containing Trade Register number + corporate Stock if any
   */
  const transformBeforeCreating = (formData: any) => {
    formData.traderegnr =
      formData.corporateStockAmount && formData.tradeRegisterNumber
        ? `${formData.tradeRegisterNumber}*${formData.corporateStockAmount}`
        : formData.tradeRegisterNumber || undefined;

    delete formData.tradeRegisterNumber;
    delete formData.corporateStockAmount;
    return formData;
  };

  const sanitizeRestProps = ({ mutationMode, validating, ...rest }) => rest;
  const CustomCreateToolbar = (props: any) => (
    <Toolbar
      {...sanitizeRestProps(props)}
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <SaveButton {...sanitizeRestProps(props)} disabled={props.pristine} />
      <CancelButton {...sanitizeRestProps(props)} />
    </Toolbar>
  );

  const CancelButton = (props) => {
    const translate = useTranslate();
    return (
      <Button
        onClick={() => {
          redirect(props.basePath);
        }}
        variant='contained'
      >
        {translate('ra.action.cancel')}
      </Button>
    );
  };

  return (
    <div>
      {isPspAdministrator(permissions) && (
        <div>
          <a
            href='/management/bulkcompany'
            style={{ marginTop: 20, display: 'flex' }}
          >
            {translate(
              'dxMessages.companyManagement.account.downloadCsvTemplate'
            )}
          </a>
          <UploadFilesField
            {...props}
            postUrl={`/management/bulkcompany/`}
            revertUrl={`dxrevert/`}
          />
        </div>
      )}
      <Create
        undoable={false}
        onSuccess={onSuccess}
        onFailure={onFailure}
        transform={transformBeforeCreating}
        component='div'
        {...props}
        resource={Constants.RESOURCE_ACCOUNT}
      >
        <SimpleForm toolbar={<CustomCreateToolbar />}>
          <FormLayout />
        </SimpleForm>
      </Create>
    </div>
  );
};

const FormLayout: FC<FieldProps<V2CompanyModel>> = (props) => {
  const { record } = props;
  if (!record) return null;
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <GeneralInfo />
      </Grid>
    </Grid>
  );
};

const GeneralInfo = (props: any) => {
  const { permissions } = usePermissions();
  const notify = useNotify();
  const translate = useTranslate();
  const locale = useLocale();
  const countryService = new CountryService(locale);
  const countries = countryService.getCountries();
  const countryItems = countries.map((country) => {
    return {
      id: country.code,
      name: country.name,
    };
  });

  const allLanguages = countryLanguage.getLanguages().map((language) => {
    return {
      id: language.iso639_1,
      name: language.name,
    };
  });

  const icon = <CheckCircle color='primary' />;
  const classes = useStyles();
  const [vatCode, setVatCode] = useState<string>('');
  const [details, setDetails] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [displayLoading, setDisplayLoading] = useState<boolean>(false);
  const refresh = useRefresh();
  const form = useForm();
  const country = form.getState().values?.countryCode;

  const fetchDetails = () => {
    const params = new URLSearchParams({
      country: country,
      vatCode: vatCode,
    });
    setDisplayLoading(true);
    setLoading(true);

    fetch(`/${Constants.RESOURCE_ACCOUNT}/company-info?${params}`, {
      credentials: 'include',
    })
      .then((response: Response) => {
        if (response.status < 200 || response.status >= 300) {
          if (response.status === HttpStatus.NOT_FOUND) {
            notify(
              'dxMessages.error_messages.company_info_not_found',
              'warning',
              { _: 'Company information not found' }
            );
          } else if (response.status === HttpStatus.BAD_REQUEST) {
            notify('dxMessages.error_messages.vat_not_found', 'warning', {
              _: 'VAT not found in public repository',
            });
          } else {
            notify(
              'dxMessages.error_messages.OnError_contact_the_support',
              'error',
              { error: response.statusText }
            );
          }
          // come back to initial value
          refresh();
        } else {
          response.json().then((json) => {
            const details = json;
            if (details.account !== undefined) {
              if (details.account.name !== undefined) {
                form.change('accountname', details.account.name);
              }
              if (details.account.registryNumber !== undefined) {
                let trn: string = details.account.registryNumber;
                let csa: string = '';
                if (details.account.registryNumber.indexOf('*') !== -1) {
                  trn = split(details.account.registryNumber, '*')?.[0];
                  csa = split(details.account.registryNumber, '*')?.[1];
                }
                form.change('tradeRegisterNumber', trn);
                if (csa?.length > 0) {
                  form.change('corporateStockAmount', csa);
                }
              }
            }
            setDetails(details);
          });
        }
        setLoading(false);
        setDisplayLoading(false);
      })
      .catch((err) => {
        setDisplayLoading(false);
        setLoading(false);
        notify(
          'dxMessages.error_messages.OnError_contact_the_support',
          'error',
          { error: err.message }
        );
      });
  };

  const CustomWidthTooltip = withStyles((theme) => ({
    tooltip: {
      maxWidth: 200,
      fontSize: 15,
    },
  }))(Tooltip);

  return (
    <Paper>
      <Grid
        style={{
          marginLeft: 20,
          paddingTop: 20,
        }}
      >
        <Grid item xs={8}>
          <div
            style={{
              display: 'flex',
              gap: 20,
              alignItems: 'flex-end',
            }}
          >
            <SelectInput
              label={'dxMessages.companyManagement.account.country'}
              source='countryCode'
              className={classes.fillSpace}
              choices={countryItems}
              defaultValue={'RO'}
              validate={required()}
            />
            <TextInput
              label={'dxMessages.companyManagement.account.vatCode'}
              source='identification'
              className={classes.fillSpace}
              validate={[required(), maxLength(50)]}
              onChange={(ev) => {
                setDetails(undefined);
                setVatCode(ev.target.value.substring(2));
              }}
            />
            <CustomWidthTooltip
              title={
                country !== 'RO'
                  ? translate(
                      'dxMessages.companyManagement.account.detailsTooltipRo'
                    )
                  : translate(
                      'dxMessages.companyManagement.account.detailsTooltip'
                    )
              }
            >
              <div>
                <Button
                  variant='outlined'
                  onClick={fetchDetails}
                  disabled={
                    vatCode.length === 0 ||
                    vatCode === details?.account?.vatCode ||
                    displayLoading === true ||
                    country !== 'RO'
                  }
                  startIcon={icon}
                  style={{
                    width: 200,
                    minWidth: 150,
                    height: 60,
                    display: 'flex',
                    position: 'relative',
                    bottom: '2em',
                  }}
                >
                  {translate('dxMessages.companyManagement.account.getDetails')}
                </Button>
              </div>
            </CustomWidthTooltip>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ display: 'flex', alignItems: 'baseline', gap: 10 }}>
            <SelectInput
              label={'dxMessages.companyManagement.account.language'}
              source='languageCode'
              className={classes.fillSpace}
              choices={allLanguages}
              defaultValue={'ro'}
              validate={required()}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ display: 'flex', alignItems: 'baseline', gap: 10 }}>
            <TextInput
              label={'dxMessages.companyManagement.account.name'}
              source='accountname'
              className={classes.fillSpace}
              validate={[required(), maxLength(255)]}
            />
            <CircularProgress
              size='1.5em'
              style={{
                display:
                  loading === true && displayLoading === true
                    ? 'block'
                    : 'none',
              }}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ display: 'flex', alignItems: 'baseline', gap: 10 }}>
            <TextInput
              label={'dxMessages.companyManagement.account.registryNumber'}
              source='tradeRegisterNumber'
              className={classes.fillSpace}
              validate={[maxLength(255), checkAuthorizedChars(translate)]}
            />
            <CircularProgress
              size='1.5em'
              style={{
                display:
                  loading === true && displayLoading === true
                    ? 'block'
                    : 'none',
              }}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'baseline', gap: 10 }}>
            <TextInput
              label={'dxMessages.companyManagement.account.corporateStock'}
              source='corporateStockAmount'
              className={classes.fillSpace}
              validate={[maxLength(255)]}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <SelectInput
            label={'dxMessages.companyManagement.account.idstatus'}
            source='idstatus'
            className={classes.fillSpace}
            choices={[
              { id: 1, name: 'Active' },
              { id: 2, name: 'Not Active' },
              { id: 3, name: 'Suspended' },
              { id: 4, name: 'Terminat' },
            ]}
            defaultValue={1}
            validate={[required(), maxLength(50)]}
          />
        </Grid>
        <Grid item xs={3} style={{ marginRight: 110 }}>
          {isPspAdministrator(permissions) && (
            <ReferenceInput
              label='dxMessages.companyManagement.account.idformattype'
              source='idformattype'
              reference='management/formattype'
              validate={required()}
              filterToQuery={(searchText) => ({
                description: [searchText],
              })}
              filter={{ idDocumentType: 1 }}
              sort={{ field: 'id', order: 'ASC' }}
              className={classes.fillSpace}
            >
              <AutocompleteInput
                optionText='description'
                className={classes.fillSpace}
              />
            </ReferenceInput>
          )}
        </Grid>
        <Grid item xs={3}>
          {isPspAdministrator(permissions) && (
            <ReferenceInput
              label='dxMessages.companyManagement.account.idformattypedesadv'
              source='idformattypedesadv'
              reference='management/formattype'
              validate={required()}
              filterToQuery={(searchText) => ({
                description: [searchText],
              })}
              filter={{ idDocumentType: 2 }}
              sort={{ field: 'id', order: 'ASC' }}
              className={classes.fillSpace}
            >
              <AutocompleteInput
                optionText='description'
                className={classes.fillSpace}
              />
            </ReferenceInput>
          )}
        </Grid>
        <Grid item xs={2}>
          <BooleanInput
            label='dxMessages.companyManagement.account.v3Synchronize'
            source='v3Synchronize'
            defaultValue={false}
            fullWidth
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

const checkAuthorizedChars = (translate: Translate) => (value: any) => {
  if (value === undefined) return;
  if (value?.indexOf('*') !== -1) {
    return translate('dxMessages.error_messages.invalid_character', {
      unwanted: '*',
    });
  }
};

export default AccountDetailsCreate;
